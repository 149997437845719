import { UploadFile } from 'antd';
import { httpApi } from './http.api';
import { notificationController } from '@app/controllers/notificationController';
import { TReportsForm } from '@app/components/apps/manage-reports/ReportsForm';
import { useMutation, useQueryClient } from 'react-query';
import pluralize from 'pluralize';

export const createReport = async (name: string, customData: any, format: string): Promise<Blob | null> => {
  try {
    const response = await httpApi.post(
      '/report/generate',
      { name, customData, format },
      { responseType: 'arraybuffer' },
    );
    if (response.status !== 201) {
      throw new Error(response.statusText);
    }
    return new Blob([response.data], { type: response.headers['content-type'] });
  } catch (err) {
    notificationController.error({ message: 'Failed to download the report' });
    console.error(err);
    return null;
  }
};

export const sendEmailReport = async (name: string, customData: any, recipients: string[], format: string) => {
  try {
    const response = await httpApi.post('/report/send', { name, customData, recipients, format });
    if (response.status !== 201) {
      throw new Error(response.statusText);
    }
    return undefined;
  } catch (err) {
    notificationController.error({ message: 'Failed to send the report' });
    console.error(err);
    return null;
  }
};

export const generateReport = async (file: UploadFile<any> | null): Promise<void> => {
  try {
    if (!file) {
      return;
    }

    const form = new FormData();
    form.append('name', 'testyd');
    form.append('description', 'test yd description');
    form.append('status', '1');
    form.append(
      'customData',
      `[
      {
        "label": "Report Date",
        "name": "ReportDate",
        "type": "Date",
        "isRequired": "true"
      }
    ]`,
    );
    form.append(
      'reportData',
      `[
      {
        "name": "recommendations",
        "type": "DynamicQuery",
        "query": "SELECT * FROM account_recommendation_extended are WHERE are.account_id = {{accountId}} AND not are.is_complaint AND are.status = '1' AND day(are.creation_time) = day({{Report Date}})] ORDER BY are.product_name, are.severity DESC"
      }
    ]`,
    );
    // form.append("template", file.originFileObj as Blob);
    // form.append("thumbnail", file.originFileObj as Blob);
    const response = await httpApi.post<any>('/report/generate', form);
  } catch (error) {}
};

export const createReportItem = async (payload: TReportsForm & { status?: number }) => {
  try {
    const response = await httpApi.post(`/report/create`, payload);
    notificationController.success({ message: `Report successfully created` });

    if (response?.status === 200) {
      notificationController.success({
        message: `Report Created`,
      });
    }

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const useCreateReportItem = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createReportItem,
    onSuccess() {
      queryClient.invalidateQueries('report-odata');
    },
  });
};

export const updateReport = async (payload: { id: string | number; data: Partial<TReportsForm> }) => {
  try {
    const response = await httpApi.patch(`/report/update/${payload.id}`, payload.data);

    if (response?.status === 200) {
      notificationController.success({
        message: `Report Updated`,
      });
    }

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const useUpdateReport = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateReport,
    onSuccess() {
      queryClient.invalidateQueries('report-odata');
    },
  });
};

export const deleteReport = async (ids: number[]): Promise<void> => {
  const response = await httpApi.delete(`/report/delete`, { data: { ids } });
  if (response?.status === 200) {
    notificationController.success({
      message: `${pluralize('Report', ids.length)} Deleted`,
    });
  }
  return response.data;
};

export const useDeleteReport = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteReport,
    onSuccess() {
      queryClient.invalidateQueries('report-odata');
    },
  });
};

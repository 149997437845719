import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 1.5rem;
  background-color: #fafafa;
  padding: 16px;
  border: 1px solid var(--grey-100);
  border-radius: 4px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 12px;
  }
`;

export const SettingTitle = styled.span`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: var(--grey-950);
`;

export const SettingDescription = styled.p`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: var(--grey-700);
  margin: 0;
`;

import { useFetchAzureAuditSummary, useGetAuditTypesWithActions } from '@app/api/azure-events-monitoring.api';
import { CenterContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { FONT_SIZE, FONT_WEIGHT } from '@app/styles/themes/constants';
import { Popover, Spin } from 'antd';
import {
  addMonths,
  differenceInDays,
  differenceInMonths,
  format,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';
import AzureBarChartTooltip from './AzureBarChartTooltip';
import { TAuditLog, TGroupedDataItem } from '@app/types/azureAuditLogs';
import { NoData } from '@app/components/common/NoData';

const ChartContainer = styled.div`
  background-color: white;
  margin-bottom: 2rem;
  padding: 16px 16px 0 16px;
  border: 1px solid var(--grey-100);
  border-radius: 8px;
  width: 100%;
  height: 340px;
  max-height: fit-content;
`;

const LegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const LegendTitle = styled.span`
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
  margin-right: 8px;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  font-size: ${FONT_SIZE.xxs};
  color: var(--grey-600);
`;

const LegendCircle = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  font-size: ${FONT_SIZE.xs};
  background-color: ${(props) => props.color};
  margin-right: 4px;
`;

interface IAzureAuditEventsBarChartProps {
  startDate: Date;
  endDate: Date;
}

const CustomBar = (props: any) => {
  const { x, y, width, height, fill, payload, dataKey } = props;

  const eventType = dataKey.split('.')[1];
  const details = (payload as TGroupedDataItem).events[eventType]?.details;

  return (
    <Popover
      content={<AzureBarChartTooltip barPayload={payload} barDetails={details} barColor={fill} />}
      trigger="hover"
      placement="left"
    >
      <rect x={x} y={y} width={width} height={height} fill={fill} style={{ transition: 'all 0.3s ease-out' }} />
    </Popover>
  );
};

export default function AzureAuditEventsBarChart({ startDate, endDate }: IAzureAuditEventsBarChartProps) {
  const [animateOnMount, setAnimateOnMount] = useState(false);
  const prevDataRef = useRef<string>('');

  const { data: auditLogsSummary, isFetching } = useFetchAzureAuditSummary(startDate, endDate);
  const { data: typesWithActions } = useGetAuditTypesWithActions();
  const colors = ['#8884d8', '#82ca9d', '#ffc658'];

  const typeOrder: Record<string, number> = {
    Group: 1,
    Role: 2,
    ConditionalAccess: 3,
  };

  useEffect(() => {
    if (!isFetching && auditLogsSummary) {
      const currentDataStr = JSON.stringify(auditLogsSummary);
      if (currentDataStr !== prevDataRef.current) {
        setAnimateOnMount(true);
        prevDataRef.current = currentDataStr;
        const timer = setTimeout(() => {
          setAnimateOnMount(false);
        }, 1500);
        return () => clearTimeout(timer);
      }
    }
  }, [isFetching, auditLogsSummary]);

  const legends = useMemo(() => {
    if (!typesWithActions) return [];
    const sortedTypes = [...typesWithActions].sort((a, b) => {
      const orderA = typeOrder[a.itemType] ?? 99;
      const orderB = typeOrder[b.itemType] ?? 99;
      return orderA - orderB;
    });
    return sortedTypes.map((type, index) => ({
      label: type.itemDisplayName,
      color: colors[index % colors.length],
    }));
  }, [typesWithActions]);

  const groupedData = useMemo((): TGroupedDataItem[] => {
    if (!auditLogsSummary) return [];

    const totalDays = differenceInDays(endDate, startDate);
    const totalMonths = differenceInMonths(endDate, startDate) + 1;

    let grouping: 'day' | 'week' | 'month' | 'custom';
    let customInterval = 1;
    if (totalDays <= 7) {
      grouping = 'day';
    } else if (totalDays <= 30) {
      grouping = 'week';
    } else if (totalMonths <= 6) {
      grouping = 'month';
    } else {
      grouping = 'custom';
      // calculate the number of months per group so that total groups <= 6
      customInterval = Math.ceil(totalMonths / 6);
    }

    const dataMap: { [key: string]: TGroupedDataItem } = {};

    (auditLogsSummary as TAuditLog[]).forEach((item) => {
      const logDate = new Date(item.log_date);
      let groupDate: Date;
      let label: string;

      if (grouping === 'day') {
        groupDate = startOfDay(logDate);
        label = format(groupDate, 'dd/MM/yyyy');
      } else if (grouping === 'week') {
        groupDate = startOfWeek(logDate, { weekStartsOn: 1 }); // Monday as start
        label = format(groupDate, 'dd/MM/yyyy');
      } else if (grouping === 'month') {
        groupDate = startOfMonth(logDate);
        label = format(groupDate, 'MMM yyyy');
      } else {
        // 'custom' grouping: group by customInterval months.
        const diffMonths = differenceInMonths(logDate, startDate);
        const groupIndex = Math.floor(diffMonths / customInterval);
        groupDate = startOfMonth(addMonths(startDate, groupIndex * customInterval));
        label = format(groupDate, 'MMM yyyy');
      }

      const key = label;
      if (!dataMap[key]) {
        dataMap[key] = { date: label, dateValue: groupDate, events: {} };
      }
      if (!dataMap[key].events[item.type]) {
        dataMap[key].events[item.type] = { count: 0, details: [] };
      }
      dataMap[key].events[item.type].count += parseInt(item.total_events, 10);
      dataMap[key].events[item.type].details.push(item);
    });

    return Object.values(dataMap).sort((a, b) => a.dateValue.getTime() - b.dateValue.getTime());
  }, [auditLogsSummary, endDate, startDate]);

  // get unique audit event types
  const types = useMemo(() => {
    if (!auditLogsSummary) return [];
    const typeSet = new Set<string>();
    (auditLogsSummary as TAuditLog[]).forEach((item) => {
      typeSet.add(item.type);
    });
    return Array.from(typeSet).sort((a, b) => (typeOrder[a] ?? 99) - (typeOrder[b] ?? 99));
  }, [auditLogsSummary]);

  const xAxisInterval = 0;

  return (
    <ChartContainer>
      {isFetching ? (
        <CenterContainer style={{ height: '100%' }}>
          <Spin />
        </CenterContainer>
      ) : groupedData && groupedData.length === 0 ? (
        <NoData withIcon />
      ) : (
        <>
          <LegendContainer>
            <LegendTitle>Audit Types</LegendTitle>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '12px' }}>
              {legends.map((legend, idx) => (
                <LegendItem key={idx}>
                  <LegendCircle color={legend.color} />
                  <span>{legend.label}</span>
                </LegendItem>
              ))}
            </div>
          </LegendContainer>

          <ResponsiveContainer width="100%" height={300}>
            <BarChart margin={{ top: 20, right: 30, left: -25 }} data={groupedData} key={Math.random()}>
              <CartesianGrid strokeDasharray="20 20" stroke="var(--grey-100)" vertical={false} />
              <XAxis
                dataKey="date"
                axisLine={false}
                tick={{ fontSize: 12 }}
                tickLine={false}
                interval={xAxisInterval}
                padding={{ left: 20, right: 20 }}
              />
              <YAxis axisLine={false} tick={{ fontSize: 12 }} tickLine={false} allowDecimals={false} />
              {types.map((type, index) => (
                <Bar
                  key={type}
                  dataKey={`events.${type}.count`}
                  fill={colors[index % colors.length]}
                  shape={(props: any) => <CustomBar {...props} />}
                  isAnimationActive={animateOnMount}
                  animationBegin={500}
                  animationDuration={700}
                  animationEasing="ease-out"
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        </>
      )}
    </ChartContainer>
  );
}

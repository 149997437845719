import { useCountActiveAlerts } from '@app/api/alert.api';
import React from 'react';
import BaseBadgeCounter from '@app/components/common/BaseBadgeCounter/BaseBadgeCounter';

interface AlertBadgeCounterProps {
  style?: React.CSSProperties;
  showAsDot?: boolean;
  className?: string;
}

const AlertBadgeCounter: React.FC<AlertBadgeCounterProps> = ({ style, showAsDot = false, className }) => {
  const { data: alertCount, isLoading } = useCountActiveAlerts();

  return (
    <BaseBadgeCounter
      count={alertCount ?? 0}
      isLoading={isLoading}
      tooltipTitle={`${alertCount ?? 0} alerts`}
      style={style}
      className={className}
      showAsDot={showAsDot}
    />
  );
};

export default AlertBadgeCounter;

import React from 'react';
import { BaseBadge } from '../BaseBadge/BaseBadge';
import { BaseTooltip } from '../BaseTooltip/BaseTooltip';
import { StyledBaseBadgeCounter } from './BaseBadgeCounter.styled';
import styled from 'styled-components';

const StyledDotBadge = styled(BaseBadge)`
  & > .ant-badge-dot {
    background-color: var(--badge-counter-bg-color) !important;
    box-shadow: 0 0 0 1px var(--blue-900) !important;
    width: 8px !important;
    height: 8px !important;
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    z-index: 10 !important;
  }
`;

export interface BadgeCounterProps {
  count: number;
  isLoading: boolean;
  tooltipTitle: string;
  style?: React.CSSProperties;
  className?: string;
  showAsDot?: boolean;
}

const BaseBadgeCounter: React.FC<BadgeCounterProps> = ({
  count,
  isLoading,
  tooltipTitle,
  style,
  className,
  showAsDot = false,
}) => {
  // if count is 0 or loading, don't show anything
  if ((count === 0 && !isLoading) || (isLoading && count === 0)) {
    return null;
  }

  // if need to show as dot, use the styled dot badge
  if (showAsDot) {
    return <StyledDotBadge dot className={className} style={style} />;
  }

  // otherwise show the regular counter badge
  return (
    <BaseTooltip title={tooltipTitle}>
      <StyledBaseBadgeCounter count={isLoading ? 0 : count} overflowCount={9999} style={style} className={className} />
    </BaseTooltip>
  );
};

export default BaseBadgeCounter;

import { useMutation, useQueryClient } from 'react-query';

import { httpApi } from './http.api';
import { notificationController } from '@app/controllers/notificationController';
import { TInsightsForm } from '@app/components/apps/manage-insights/InsightsForm';
import pluralize from 'pluralize';

export const getInsight = async (id: number) => {
  const insight = await httpApi.get(`odata/Insight?$filter=(id eq ${id})&$top=1`);
  return insight?.data?.items?.at(0);
};

const createInsight = async (data: TInsightsForm) => {
  const response = await httpApi.post<any>('/insight/create', data);

  if (response?.status === 201) {
    notificationController.success({
      message: `Insight Created`,
    });
  }

  return response?.data;
};

export const useCreateInsight = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createInsight,
    onSuccess() {
      queryClient.invalidateQueries('insight-odata');
    },
  });
};

const updateInsight = async (payload: { id: number; data: TInsightsForm }) => {
  const { id, data } = payload;

  const response = await httpApi.patch<any>(`/insight/${id}`, data);

  if (response?.status === 200) {
    notificationController.success({
      message: `Insight Updated`,
    });
  }

  return response?.data;
};

export const useUpdateInsight = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateInsight,
    onSuccess() {
      queryClient.invalidateQueries('insight-odata');
    },
  });
};

const deleteInsight = async (insightIds: number[]) => {
  const response = await httpApi.delete<any>(`/insight`, { data: { ids: insightIds } });

  if (response?.status === 200) {
    notificationController.success({
      message: `${pluralize('Insight', insightIds.length)} Deleted`,
    });
  }

  return response?.data;
};

export const useDeleteInsight = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteInsight,
    onSuccess() {
      queryClient.invalidateQueries('insight-odata');
    },
  });
};

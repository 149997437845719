import React from 'react';
import { Select, Tooltip } from 'antd';
import { IoIosArrowRoundForward, IoMdClose } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const entityConfigs = {
  products: { model: 'product', entityName: 'product', displayName: 'Product', enumKey: 'Product' },
  licenses: { model: 'license', entityName: 'license', displayName: 'License', enumKey: 'License' },
  licensePlans: {
    model: 'licensePlan',
    entityName: 'license_plan',
    displayName: 'License Plan',
    enumKey: 'LicensePlan',
  },
} as const;

const EntitySelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 8px 12px;
  gap: 12px;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #555;
  font-weight: 500;
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const RemoveButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  color: var(--red-900);
  cursor: pointer;
  padding: 4px;
  border-radius: 10px;
  transition: all 0.3s ease;

  &:hover {
    background-color: var(--red-100);
    color: var(--red-900);
  }
`;

interface EntitySelectorProps {
  selectedEntity: keyof typeof entityConfigs;
  dependencies?: Array<{ id: number; dependentTableDisplayName: string }>;
  selectedDependency?: number;
  onEntityChange: (entity: keyof typeof entityConfigs) => void;
  onDependencyChange?: (dependencyId: number | undefined) => void;
}

export const EntitySelector: React.FC<EntitySelectorProps> = ({
  selectedEntity,
  dependencies,
  selectedDependency,
  onEntityChange,
  onDependencyChange,
}) => {
  const { t } = useTranslation();

  const handleEntityChange = (value: unknown) => {
    onEntityChange(value as keyof typeof entityConfigs);
  };

  const handleDependencyChange = (value: unknown, option: unknown) => {
    onDependencyChange?.(value as number);
  };

  const handleRemoveDependency = () => {
    onDependencyChange?.(undefined);
  };

  return (
    <EntitySelectorWrapper>
      <LabelContainer>
        <span>{t('Select Entity')}:</span>
      </LabelContainer>

      <SelectWrapper>
        <Select value={selectedEntity} onChange={handleEntityChange} style={{ width: 200 }}>
          {Object.entries(entityConfigs).map(([key, { displayName }]) => (
            <Select.Option key={key} value={key}>
              {displayName}
            </Select.Option>
          ))}
        </Select>

        {selectedEntity && dependencies && dependencies.length > 0 && (
          <>
            <IoIosArrowRoundForward size={30} style={{ color: '#a0a0a0', margin: '0 8px' }} />

            <Select
              value={selectedDependency}
              onChange={handleDependencyChange}
              placeholder="Select Dependent Table"
              style={{ width: 200 }}
            >
              {dependencies.map((dep) => (
                <Select.Option key={dep.id} value={dep.id}>
                  {dep.dependentTableDisplayName}
                </Select.Option>
              ))}
            </Select>
            {selectedDependency && (
              <RemoveButton onClick={handleRemoveDependency}>
                <IoMdClose size={20} />
              </RemoveButton>
            )}
          </>
        )}
      </SelectWrapper>
    </EntitySelectorWrapper>
  );
};

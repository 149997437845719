import { TAccountAuditTypes, TAuditTypesWithActions } from '@app/api/azure-events-monitoring.api';
import { BaseCancelButton } from '@app/components/common/BaseButton/BaseButton.styles';
import { BaseModalCloseButton, BaseModalTitle } from '@app/components/common/BaseLayout/BaseLayout.styled';
import Modal, { ModalFuncProps } from 'antd/lib/modal/Modal';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import AuditTypeSelection from './AuditTypeSelectionForm';

// Container for all audit types
const AuditTypesContainer = styled.div`
  border: 1px solid var(--grey-100);
  background-color: #fafafa;
  border-radius: 8px;
  padding: 12px 12px 0px 12px;
`;

const ModalTitle = styled.span`
  font-size: 1rem;
  font-weight: 400;
  color: var(--grey-600);
  margin-bottom: 10px;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 10px;
  margin: 10px 0px;
`;

const ApplyButton = styled.button`
  border: ${(props) => (props.disabled ? '1px solid var(--grey-100)' : '1px solid var(--blue-600)')};
  border-radius: 4px;
  background-color: ${(props) => (props.disabled ? 'var(--grey-100)' : 'var(--blue-600)')};
  padding: 12px 48px;
  color: ${(props) => (props.disabled ? 'var(--grey-300)' : 'white')};
  font-size: 16px;
  font-weight: 400;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const CategoryLabel = styled.span`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 16px;
  color: #272727;
`;

export interface AuditSelections {
  [itemType: string]: {
    [action: string]: boolean;
  };
}

interface IModalFooterProps {
  onCancel?: () => void;
  onApply?: () => void;
  onApplyDisabled?: boolean;
}

const ModalFooter = ({ onCancel, onApply, onApplyDisabled }: IModalFooterProps) => {
  return (
    <FooterContainer>
      <BaseCancelButton onClick={() => onCancel?.()}>Cancel</BaseCancelButton>
      <ApplyButton disabled={onApplyDisabled} onClick={() => onApply?.()}>
        Save
      </ApplyButton>
    </FooterContainer>
  );
};

interface AuditTypesModalProps extends ModalFuncProps {
  isLoading?: boolean;
  auditTypes: TAuditTypesWithActions[];
  accountAuditTypes: TAccountAuditTypes;
}

export default function AuditTypesModal({
  isLoading,
  auditTypes,
  accountAuditTypes,
  ...modalProps
}: AuditTypesModalProps) {
  const [auditSelections, setAuditSelections] = useState<AuditSelections>(() =>
    getAccountAuditSelections(accountAuditTypes),
  );

  useEffect(() => {
    if (modalProps.open) {
      setAuditSelections(getAccountAuditSelections(accountAuditTypes));
    }
  }, [modalProps.open, accountAuditTypes]);

  const handleActionToggle = useCallback((itemType: string, action: string, checked: boolean) => {
    setAuditSelections((prev) => ({
      ...prev,
      [itemType]: {
        ...prev[itemType],
        [action]: checked,
      },
    }));
  }, []);

  const handleGroupToggle = useCallback(
    (itemType: string, checked: boolean) => {
      setAuditSelections((prev) => {
        // check if the group already exists and has keys
        const currentGroup = prev[itemType];
        if (currentGroup && Object.keys(currentGroup).length > 0) {
          return {
            ...prev,
            [itemType]: Object.fromEntries(Object.entries(currentGroup).map(([action]) => [action, checked])),
          };
        } else {
          //  if the group isnt initialized, find it in the auditTypes array and create the group
          const auditTypeData = auditTypes.find((at) => at.itemType === itemType);
          if (!auditTypeData) return prev;
          const newGroup = auditTypeData.actions.reduce((acc, action) => {
            acc[action] = checked;
            return acc;
          }, {} as { [action: string]: boolean });
          return {
            ...prev,
            [itemType]: newGroup,
          };
        }
      });
    },
    [auditTypes],
  );

  const getGroupStatus = useCallback(
    (itemType: string): { groupChecked: boolean } => {
      const group = auditSelections[itemType] || {};
      const total = Object.keys(group).length;
      const selectedCount = Object.values(group).filter(Boolean).length;
      return {
        groupChecked: selectedCount > 0,
        // groupIndeterminate: selectedCount > 0 && selectedCount < total,
      };
    },
    [auditSelections],
  );

  const isAnySelected = useMemo(() => {
    return Object.values(auditSelections).some((group) => Object.values(group).some((selected) => selected));
  }, [auditSelections]);

  const handleSave = () => {
    modalProps.onOk?.(auditSelections);
  };

  const handleCloseModal = () => {
    modalProps.onCancel?.();
  };

  return (
    <Modal
      {...modalProps}
      style={{ minWidth: '700px' }}
      title={<BaseModalTitle>Audit Item types</BaseModalTitle>}
      destroyOnClose={true}
      closeIcon={<BaseModalCloseButton />}
      onCancel={handleCloseModal}
      footer={<ModalFooter onCancel={handleCloseModal} onApply={handleSave} onApplyDisabled={!isAnySelected} />}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <ModalTitle>Select Action Type Definitions you want to track.</ModalTitle>
        <CategoryLabel>Allowed actions:</CategoryLabel>
        <AuditTypesContainer>
          {isLoading ? (
            <p>Loading audit types...</p>
          ) : (
            auditTypes.map((auditType) => (
              <AuditTypeSelection
                key={auditType.itemType}
                auditType={auditType}
                auditSelections={auditSelections}
                onActionToggle={handleActionToggle}
                onGroupToggle={handleGroupToggle}
                getGroupStatus={getGroupStatus}
              />
            ))
          )}
        </AuditTypesContainer>
      </div>
    </Modal>
  );
}

/**
 * Returns the default audit selections for the account based on the account's audit types.
 * @param accountAuditTypes Audit types for the account
 * @returns the default audit selections for the account
 */
export function getAccountAuditSelections(accountAuditTypes: TAccountAuditTypes): AuditSelections {
  const defaults: AuditSelections = {};
  accountAuditTypes.accountAuditItemTypes.forEach((item) => {
    defaults[item.itemType] = {};
    item.actions.forEach((action) => {
      defaults[item.itemType][action] = true;
    });
  });
  return defaults;
}

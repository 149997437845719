import { CloseOutlined } from '@ant-design/icons';
import { Modal, ModalFuncProps, message, Table } from 'antd';
import { TAccountRecommendationExtended } from '@app/types/accountRecommendationExtended';
import { formatDate } from '@app/utils/utils';
import styled from 'styled-components';
import { FaClipboardList } from 'react-icons/fa';
import { RECOMMENDATION_DETAILS_MODAL_COLORS } from '@app/styles/themes/constants';
import { useEvidence } from '@app/api/recommendation.api';
import { useEffect, useState } from 'react';
import { Loading } from '@app/components/common/Loading/Loading';

interface IEvidenceModalProps extends ModalFuncProps {
  recommendation: TAccountRecommendationExtended;
  onClose?: () => void;
  open: boolean;
}

const ScrollableContainer = styled.div`
  max-height: 300px;
  overflow-y: auto;
  padding: 12px 8px 0 8px;
  border: 1px solid var(--grey-100);
  border-radius: 4px;
`;

const StyledTable = styled(Table)`
  .ant-table-tbody > tr:last-child > td {
    border-bottom: none;
  }
`;

const GroupContainer = styled.div`
  margin-bottom: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

const CopyButton = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  color: var(--blue-800);
  cursor: pointer;
  font-size: 14px;
  padding: 0;
  &:hover {
    text-decoration: underline;
  }
  svg {
    margin-right: 4px;
  }
`;

const Header = styled.span`
  display: block;
  color: var(--grey-950);
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 12px;
`;

const Description = styled.p`
  margin-bottom: 16px;
  font-size: 1rem;
  color: ${RECOMMENDATION_DETAILS_MODAL_COLORS.grayText};
  font-weight: 400;
`;

export default function EvidenceModal({ recommendation, onClose, open }: IEvidenceModalProps) {
  const { mutateAsync: evidence, isLoading, data: fetchEvidence } = useEvidence();
  const [evidenceData, setEvidenceData] = useState<any>(null);

  useEffect(() => {
    if (open && recommendation) {
      evidence({ accountRecommendationId: recommendation.id });
    }
  }, [open, recommendation, evidence]);

  useEffect(() => {
    if (!isLoading && fetchEvidence) {
      setEvidenceData(fetchEvidence.data);
    }
  }, [isLoading, fetchEvidence]);

  const handleCopy = () => {
    if (evidenceData?.items && evidenceData.items.length > 0) {
      let formattedText = '';

      evidenceData.items.forEach((item: any) => {
        formattedText += Object.values(item).join('\t') + '\n';
      });

      navigator.clipboard
        .writeText(formattedText)
        .then(() => {
          message.success('Copied to clipboard!');
        })
        .catch(() => {
          message.error('Failed to copy!');
        });
    }
  };

  const renderItems = () => {
    if (evidenceData?.items && evidenceData.items.length > 0) {
      const columns = Object.keys(evidenceData.items[0]);
      const tableColumns = columns.map((col) => ({
        title: col.charAt(0).toUpperCase() + col.slice(1),
        dataIndex: col,
        key: col,
        render: (text: any) => String(text),
      }));

      const dataSource = evidenceData.items.map((item: any, index: number) => ({
        key: index,
        ...item,
      }));

      return (
        <StyledTable
          dataSource={dataSource}
          columns={tableColumns}
          size="small"
          pagination={evidenceData.items.length > 10 ? { pageSize: 10 } : false}
          scroll={{ y: 240 }}
        />
      );
    }
    return null;
  };

  return (
    <Modal
      title={<span style={{ fontSize: '1.25rem', fontWeight: '500' }}>Compliance Validation Report</span>}
      open={open}
      onCancel={onClose}
      destroyOnClose={true}
      closeIcon={<CloseOutlined style={{ color: 'gray' }} />}
      footer={null}
      width="600px"
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Header>{recommendation.findings}</Header>
          <Description>
            On <strong style={{ fontWeight: '600' }}>{formatDate(recommendation.notCompliantSince)}</strong>, this
            recommendation was flagged as <strong>Non-Compliant</strong> due to the following identified{' '}
            {evidenceData?.displayName}
          </Description>
          {evidenceData?.items && evidenceData.items.length > 0 ? (
            <GroupContainer>
              <ScrollableContainer>{renderItems()}</ScrollableContainer>
            </GroupContainer>
          ) : (
            <ul>
              <li>No non-compliant items found.</li>
            </ul>
          )}
          <ButtonContainer>
            <CopyButton onClick={handleCopy}>
              <FaClipboardList />
              Copy all Items
            </CopyButton>
          </ButtonContainer>
        </>
      )}
    </Modal>
  );
}

import { BaseErrorMessage, HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import Modal, { ModalFuncProps } from 'antd/lib/modal/Modal';
import Spin from 'antd/lib/spin';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Select } from 'antd';

const Container = styled.form`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const FormTitle = styled.div`
  font-weight: 800;
  font-size: 20px;
  line-height: 23px;
  color: #272727;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  margin: 40px 0px;
  gap: 20px;
`;

const FieldLabel = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #272727;
  flex-basis: 25%;
`;

const FieldInput = styled.input<{ hasError?: boolean }>`
  flex-basis: 75%;
  background-color: transparent;
  border: ${(props) => (props.hasError ? '1px solid red' : '1px solid #acacac')};
  border-radius: 10px;
  padding: 12px;
  outline: none;
`;

const InputContainer = styled.div`
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
`;

const CancelButton = styled.button`
  border: 1px solid #272727;
  border-radius: 10px;
  background-color: transparent;
  padding: 12px 70px;
  color: #272727;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const CreateButton = styled.button`
  border: 1px solid #3a50d1;
  border-radius: 10px;
  background-color: #3a50d1;
  padding: 12px 70px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

export type TLicensePlanLicensesForm = {
  licenseIds: number[];
  licensePlanIds: number[];
};

interface ITLicensePlanLicensesFormProps extends ModalFuncProps {
  isCreating?: boolean;
  data?: TLicensePlanLicensesForm;
  selectedEntityType: string;
  selectedData: { id: number; displayName: string };
  availableLicenses: { id: number; displayName: string }[];
  availableLicensePlans: { id: number; displayName: string }[];
}

export default function LicenseRelationshipForm({
  isCreating,
  data,
  selectedEntityType,
  selectedData,
  availableLicenses,
  availableLicensePlans,
  ...modalProps
}: ITLicensePlanLicensesFormProps) {
  const {
    handleSubmit,
    reset,
    register,
    setValue,
    formState: { errors },
  } = useForm<Partial<TLicensePlanLicensesForm>>({
    mode: 'onBlur',
  });

  useEffect(() => {
    if (modalProps.open) {
      reset({
        licenseIds: selectedEntityType === 'license' ? [selectedData.id] : [],
        licensePlanIds: selectedEntityType === 'licensePlan' ? [selectedData.id] : [],
      });
    }
  }, [modalProps.open, selectedEntityType, selectedData]);

  const submitHandler: SubmitHandler<Partial<TLicensePlanLicensesForm>> = (formData) => {
    const finalData: TLicensePlanLicensesForm =
      selectedEntityType === 'license'
        ? { licenseIds: [selectedData.id], licensePlanIds: formData.licensePlanIds || [] }
        : { licensePlanIds: [selectedData.id], licenseIds: formData.licenseIds || [] };
    modalProps.onOk?.(finalData);
  };
  const handleCancel = () => {
    reset();
    modalProps.onCancel?.();
  };

  const isLoading = isCreating ? true : false;
  return (
    <Modal
      open={modalProps.open}
      title={selectedEntityType === 'license' ? 'Add License Plan(s) to License' : 'Add License(s) to License Plan'}
      footer={null}
      destroyOnClose
      {...modalProps}
    >
      <FormContainer onSubmit={handleSubmit(submitHandler)}>
        {selectedEntityType === 'license' ? (
          <>
            <FieldLabel>License (selected):</FieldLabel>
            <Select
              disabled
              defaultValue={selectedData.displayName}
              options={[{ label: selectedData.displayName, value: selectedData.id }]}
            />
            <FieldLabel>Select License Plan(s):</FieldLabel>
            <Select
              mode="multiple"
              placeholder="Select License Plans"
              onChange={(value) => setValue('licensePlanIds', value)}
              options={availableLicensePlans.map((lp) => ({
                label: lp.displayName,
                value: lp.id,
              }))}
            />
          </>
        ) : (
          <>
            <FieldLabel>License Plan (selected):</FieldLabel>
            <Select
              disabled
              defaultValue={selectedData.displayName}
              options={[{ label: selectedData.displayName, value: selectedData.id }]}
            />
            <FieldLabel>Select License(s):</FieldLabel>
            <Select
              mode="multiple"
              placeholder="Select Licenses"
              onChange={(value) => setValue('licenseIds', value)}
              options={availableLicenses.map((l) => ({
                label: l.displayName,
                value: l.id,
              }))}
            />
          </>
        )}
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
          <CancelButton type="button" onClick={handleCancel}>
            Cancel
          </CancelButton>
          <CreateButton type="submit">Establish</CreateButton>
        </div>
      </FormContainer>
    </Modal>
  );
}

import { useTranslation } from 'react-i18next';
import { MainContainer, SettingDescription, SettingTitle } from '../styles/azure-events-monitoring.styles';
import { Switch } from 'antd';
import styled from 'styled-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useUpdateAccountNotificationPreference } from '@app/api/azure-events-monitoring.api';
import debounce from 'lodash/debounce';
import { useAppSelector } from '@app/hooks/reduxHooks';
import AzureAuditLogEmailNotificationModal from './AzureAuditLogEmailNotificationModal';
import { BaseCTAButton } from '@app/components/common/BaseButton/BaseButton.styles';
import {
  useGetAccountAuditNotificationRecipients,
  useUpdateAccountAuditNotificationRecipients,
} from '@app/api/account.api';
import { FaCheck } from 'react-icons/fa6';
import ConfirmationModal from '../../manage-groups/ConfirmationModal';

const SwitchContainer = styled.div`
  margin-top: 10px;
`;

const StyledSwitch = styled(Switch)`
  &.ant-switch-checked {
    background-color: var(--blue-500) !important;
  }
`;

const ButtonContainer = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  font-weight: 500;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const Button = styled(BaseCTAButton)`
  padding: 12px 16px;
  border: none;
  font-size: 0.875rem;
`;

const AccountAuditNotificationRecipients = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 5rem;
`;

const AccountNotificationRecipientsItem = styled.div`
  font-family: 'Inter', sans-serif;
  color: var(--gray-950);
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  font-size: 0.875rem;
`;

const EmailRecipientTitle = styled.div`
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

const EmailRecipients = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(5, auto);
  gap: 0.15rem;
`;

const EmailRecipientItem = styled.div`
  font-family: 'Inter', sans-serif;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: var(--grey-700);
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
`;

export default function EmailNotificationSettings() {
  const { t } = useTranslation();
  const { mutateAsync: updateNotificationPreference } = useUpdateAccountNotificationPreference();
  const { mutateAsync: updateAccountAuditNotificationRecipients, isLoading } =
    useUpdateAccountAuditNotificationRecipients();
  const { data: accountAuditNotificationRecipients } = useGetAccountAuditNotificationRecipients();
  const accountTrackingPreference = useAppSelector((state) => state.user.user?.account.isAzureLogsTrackingEnabled);

  const [checked, setChecked] = useState(accountTrackingPreference);
  const [emails, setEmails] = useState<string[]>(accountAuditNotificationRecipients || []);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isAzureAuditNotificationRecipientsModalOpen, setIsAzureAuditNotificationRecipientsModalOpen] = useState(false);

  useEffect(() => {
    if (accountTrackingPreference !== undefined) {
      setChecked(accountTrackingPreference);
    }
  }, [accountTrackingPreference]);

  const debouncedUpdatePreference = useMemo(
    () =>
      debounce((value: boolean) => {
        updateNotificationPreference(value);
      }, 500),
    [updateNotificationPreference],
  );

  const handleSwitchChange = useCallback(
    (value: boolean) => {
      setChecked(value);
      debouncedUpdatePreference(value);
    },
    [debouncedUpdatePreference],
  );

  const handleSave = (emails: string[]) => {
    setEmails(emails);
    setIsConfirmationModalOpen(true);
  };

  const handleUpdateAccountNotificationRecipients = async () => {
    if (!emails) return;
    await updateAccountAuditNotificationRecipients(emails);
    setIsConfirmationModalOpen(false);
    setIsAzureAuditNotificationRecipientsModalOpen(false);
  };

  if (accountTrackingPreference === undefined) return null;

  return (
    <MainContainer>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <SettingTitle>Email Notifications</SettingTitle>
        <SwitchContainer>
          <StyledSwitch checked={checked} onChange={handleSwitchChange} />
        </SwitchContainer>
      </div>
      <SettingDescription>{t('eventsMonitoringPage.emailNotificationDescription')}</SettingDescription>

      {checked && accountAuditNotificationRecipients && accountAuditNotificationRecipients.length > 0 && (
        <AccountAuditNotificationRecipients>
          <AccountNotificationRecipientsItem>
            <EmailRecipientTitle>Recipients</EmailRecipientTitle>
            <EmailRecipients>
              {accountAuditNotificationRecipients.map((email) => (
                <EmailRecipientItem key={email}>
                  <FaCheck />
                  <span>{email}</span>
                </EmailRecipientItem>
              ))}
            </EmailRecipients>
          </AccountNotificationRecipientsItem>
        </AccountAuditNotificationRecipients>
      )}

      {checked && (
        <ButtonContainer>
          <Button
            onClick={() => setIsAzureAuditNotificationRecipientsModalOpen(true)}
            style={{ padding: '14px', width: 'fit-content', height: '2.5rem' }}
          >
            Edit Recipients
          </Button>
        </ButtonContainer>
      )}

      {accountAuditNotificationRecipients && (
        <AzureAuditLogEmailNotificationModal
          emails={accountAuditNotificationRecipients}
          open={isAzureAuditNotificationRecipientsModalOpen}
          onCancel={() => setIsAzureAuditNotificationRecipientsModalOpen(false)}
          onOk={handleSave}
        />
      )}

      <ConfirmationModal
        open={isConfirmationModalOpen}
        message={`Are you sure? This will update the email notification recipients.`}
        onOk={handleUpdateAccountNotificationRecipients}
        onCancel={() => setIsConfirmationModalOpen(false)}
        isLoading={isLoading}
        confirmButtonMessage={'Confirm'}
      />
    </MainContainer>
  );
}

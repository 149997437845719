import EmailRecipients from '@app/components/common/EmailRecipients/EmailRecipients';
import styled from 'styled-components';
import { useEmailGroupInput } from '../../manage-users/EmailGroupInput/useEmailGroupInput';
import Modal, { ModalFuncProps } from 'antd/lib/modal/Modal';
import { BaseModalCloseButton, BaseModalTitle } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { BaseCancelButton } from '@app/components/common/BaseButton/BaseButton.styles';
import { useMemo } from 'react';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;

const FieldLabel = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 18px;
  color: var(--grey-950);
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 10px;
  margin: 10px 0px;
`;

const ApplyButton = styled.button`
  border: ${(props) => (props.disabled ? '1px solid var(--grey-100)' : '1px solid var(--blue-600)')};
  border-radius: 4px;
  background-color: ${(props) => (props.disabled ? 'var(--grey-100)' : 'var(--blue-600)')};
  padding: 12px 48px;
  color: ${(props) => (props.disabled ? 'var(--grey-300)' : 'white')};
  font-size: 16px;
  font-weight: 400;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

interface IModalFooterProps {
  onCancel?: () => void;
  onApply?: () => void;
  onApplyDisabled?: boolean;
}

const ModalFooter = ({ onCancel, onApply, onApplyDisabled }: IModalFooterProps) => {
  return (
    <FooterContainer>
      <BaseCancelButton onClick={() => onCancel?.()}>Cancel</BaseCancelButton>
      <ApplyButton disabled={onApplyDisabled} onClick={() => onApply?.()}>
        Save
      </ApplyButton>
    </FooterContainer>
  );
};

interface IAzureAuditLogEmailNotificationModalProps extends ModalFuncProps {
  emails: string[];
  isLoading?: boolean;
}

export default function AzureAuditLogEmailNotificationModal({
  emails,
  ...modalProps
}: IAzureAuditLogEmailNotificationModalProps) {
  const emailGroupInputProps = useEmailGroupInput({
    defaultEmailList: emails,
  });

  // compute whether there are changes to the email list, added or removed
  const hasChanges = useMemo(() => {
    const sortedDefault = [...emails].sort().join(',');
    const sortedCurrent = [...emailGroupInputProps.emails].sort().join(',');
    return sortedDefault !== sortedCurrent;
  }, [emails, emailGroupInputProps.emails]);

  const handleSave = () => {
    modalProps.onOk?.(emailGroupInputProps.emails);
  };

  const handleCloseModal = () => {
    modalProps.onCancel?.();
    emailGroupInputProps.resetEmails();
  };

  return (
    <Modal
      {...modalProps}
      style={{
        minWidth: '600px',
      }}
      title={<BaseModalTitle>Azure Logs Notification Recipients</BaseModalTitle>}
      destroyOnClose={true}
      closeIcon={<BaseModalCloseButton />}
      onCancel={handleCloseModal}
      footer={
        <ModalFooter
          onCancel={handleCloseModal}
          onApply={handleSave}
          onApplyDisabled={!hasChanges || emailGroupInputProps.emails.length === 0}
        />
      }
    >
      <Container>
        <FieldContainer>
          <FieldLabel>User Email Addresses:</FieldLabel>
          <EmailRecipients {...emailGroupInputProps} />
        </FieldContainer>
      </Container>
    </Modal>
  );
}

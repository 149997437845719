import { BaseErrorMessage, HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import Modal, { ModalFuncProps } from 'antd/lib/modal/Modal';
import Spin from 'antd/lib/spin';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { TDataManagement } from '@app/types/dataManagement';

const Container = styled.form`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const FormTitle = styled.div`
  font-weight: 800;
  font-size: 20px;
  line-height: 23px;
  color: #272727;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0px;
  gap: 20px;
`;

const FieldLabel = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #272727;
  flex-basis: 25%;
`;

const FieldInput = styled.input<{ hasError?: boolean }>`
  flex-basis: 75%;
  background-color: transparent;
  border: ${(props) => (props.hasError ? '1px solid red' : '1px solid #acacac')};
  border-radius: 10px;
  padding: 12px;
  outline: none;
`;

const InputContainer = styled.div`
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
`;

const CancelButton = styled.button`
  border: 1px solid #272727;
  border-radius: 10px;
  background-color: transparent;
  padding: 12px 70px;
  color: #272727;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const CreateButton = styled.button`
  border: 1px solid #3a50d1;
  border-radius: 10px;
  background-color: #3a50d1;
  padding: 12px 70px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

export type TDataManagementForm = {
  id: number;
  displayName: string;
  name: string;
  type: number;
};

interface ITDataManagementFormProps extends ModalFuncProps {
  isEdit?: boolean;
  isCreating?: boolean;
  data?: TDataManagement;
  isUpdating?: boolean;
  dataManagementType?: string;
}

export default function DataManagementForm({
  isEdit,
  isCreating,
  isUpdating,
  data,
  dataManagementType,
  ...modalProps
}: ITDataManagementFormProps) {
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm<Partial<TDataManagement>>({
    mode: 'onBlur',
  });

  useEffect(() => {
    if (modalProps.open && !!data) {
      reset({
        displayName: data.displayName,
      });
    } else if (modalProps.open && !data) {
      reset({
        displayName: '',
      });
    }
  }, [modalProps.open, data]);

  const onSubmit: SubmitHandler<Partial<TDataManagement>> = (data) => {
    modalProps.onOk?.(data, false);
  };
  const handleCancel = () => {
    reset();
    modalProps.onCancel?.();
  };

  const isLoading = isCreating || isUpdating ? true : false;

  return (
    <Modal
      style={{
        minWidth: '1000px',
      }}
      {...modalProps}
      destroyOnClose={true}
      closable={false}
      footer={null}
    >
      <Container onSubmit={handleSubmit(onSubmit)}>
        <FormTitle>{`${isEdit ? 'Edit' : 'Create'} ${dataManagementType}`}</FormTitle>
        <FormContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Display Name:</FieldLabel>
            <InputContainer>
              <FieldInput hasError={!!errors.displayName} {...register('displayName', { required: true })} />
              {!!errors.displayName && <BaseErrorMessage>This field is required</BaseErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
        </FormContainer>
        <HorizontalAlignedContainer
          style={{
            gap: '10px',
            justifyContent: 'center',
          }}
        >
          {isLoading ? (
            <Spin />
          ) : (
            <>
              <CancelButton type="button" onClick={handleCancel}>
                Cancel
              </CancelButton>
              <CreateButton disabled={isLoading}>{isEdit ? 'Update' : 'Create'}</CreateButton>
            </>
          )}
        </HorizontalAlignedContainer>
      </Container>
    </Modal>
  );
}

import useToggle from '@app/hooks/useToggle';
import { TTermsAndConditionsForm } from './TermsAndConditionsForm';
import { TTermsAndConditions } from '@app/types/termsAndConditions';

interface IUseTermsAndConditionsFormProps {
  onCancel?: () => void;
  onOk?: (data: TTermsAndConditionsForm, type: string) => void;
  terms?: TTermsAndConditions;
}
export default function UseTermsAndConditionsForm(props?: IUseTermsAndConditionsFormProps) {
  const [isModalOpen, toggle, setIsModalOpen] = useToggle();

  const handleOnOk = (data: TTermsAndConditionsForm, type: string) => {
    props?.onOk?.(data, type);
  };

  const handleOnCancel = () => {
    props?.onCancel?.();
    setIsModalOpen(false);
  };
  return {
    open: isModalOpen,
    terms: props?.terms,
    toggle,
    onOk: handleOnOk,
    onCancel: handleOnCancel,
  };
}

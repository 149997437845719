import { useMutation, useQuery, useQueryClient } from 'react-query';
import { httpApi } from './http.api';
import { AuditSelections } from '@app/components/apps/azure-events-monitoring/audit-types-setting/AuditTypesModal';
import { notificationController } from '@app/controllers/notificationController';

export type TAuditTypesWithActions = {
  itemType: string;
  itemDisplayName: string;
  actions: string[];
  actionDisplayNames: string[];
};

export type TAccountAuditTypes = {
  accountAuditItemTypes: TAuditTypesWithActions[];
};

const getAuditTypesWithActions = async (): Promise<TAuditTypesWithActions[]> => {
  const response = await httpApi.get<TAuditTypesWithActions[]>('/azure-audit-logs/audit-types');
  return response.data;
};

export const useGetAuditTypesWithActions = () =>
  useQuery({
    queryKey: 'audit-types-with-actions',
    queryFn: () => getAuditTypesWithActions(),
  });

const updateTrackingPreferences = async (data: AuditSelections) => {
  const response = await httpApi.patch<void>('/azure-audit-logs/update-account-preferences', data);
  if (response?.status === 200) {
    notificationController.success({
      message: 'Your tracking preferences have been saved!',
    });
  }
  return response.data;
};

export const useUpdateTrackingPreferences = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateTrackingPreferences,
    onSuccess() {
      queryClient.invalidateQueries('account-audit-types');
    },
  });
};

const updateAccountNotificationPreference = async (notificationPreference: boolean) => {
  const response = await httpApi.patch<void>('/azure-audit-logs/update-logs-tracking-settings', {
    notificationPreference,
  });
  if (response?.status === 200) {
    notificationController.success({
      message: 'Your notification preferences have been saved!',
    });
  }
  return response.data;
};

export const useUpdateAccountNotificationPreference = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateAccountNotificationPreference,
    onSuccess() {
      queryClient.invalidateQueries('whoami');
    },
  });
};

const fetchAzureAuditSummary = async (startDate: Date, endDate: Date) => {
  const response = await httpApi.get(
    `/azure-audit-logs/audit-logs-events-summary?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
  );
  return response.data;
};

export const useFetchAzureAuditSummary = (fromDate: Date, to: Date) => {
  return useQuery({
    queryKey: ['fetch-azure-audit-summary', fromDate, to],
    queryFn: () => fetchAzureAuditSummary(fromDate, to),
  });
};

import { useCallback, useRef, useEffect } from 'react';

const useDynamicDotPositioning = (menuRef: React.RefObject<HTMLDivElement>) => {
  // store previous positions of the dot to avoid unnecessary recalculations
  const positionCache = useRef<Record<string, number>>({});

  // keep track of the last selected menu item so we can update it after a page refresh
  const lastSelectedKey = useRef<string | null>(null);

  const updateDotPosition = useCallback(
    (selectedKey: string) => {
      // if there's no menu or no selected key, do nothing
      if (!menuRef.current || !selectedKey) return;

      // remember the last selected menu item so we can restore it if needed
      lastSelectedKey.current = selectedKey;

      /**
       * First, we reset all menus by removing the "data-has-selected-item" attribute.
       * This ensures that we don't accidentally leave indicators on old selections.
       */
      const resetSubmenus = () => {
        document
          .querySelectorAll('.ant-menu-submenu-popup .ant-menu, .ant-menu-submenu.ant-menu-submenu-open .ant-menu')
          .forEach((menu) => (menu as HTMLElement).setAttribute('data-has-selected-item', 'false'));
      };

      /**
       * This function calculates where the dot should be positioned and updates the submenu.
       * @param submenu - the submenu container element
       * @param selectedItem - the selected menu item element
       */
      const setDotPosition = (submenu: Element, selectedItem: Element) => {
        // get the position of the submenu and selected menu item
        const submenuRect = submenu.getBoundingClientRect();
        const itemRect = selectedItem.getBoundingClientRect();

        // find the vertical center of the selected item
        const itemCenter = itemRect.height / 2;
        const dotTop = itemRect.top - submenuRect.top + itemCenter;

        positionCache.current[selectedKey] = dotTop;

        // mark this submenu as having a selected item and set the dot's position
        (submenu as HTMLElement).setAttribute('data-has-selected-item', 'true');
        (submenu as HTMLElement).style.setProperty('--dot-position', `${dotTop}px`);
      };

      resetSubmenus();

      /**
       * Finds the submenu and selected menu item, then updates the dot position.
       * @param submenu - The submenu container element
       * @param selectedItem - The selected menu item element
       */
      const handleMenu = (submenu: Element, selectedItem: Element) => {
        if (!submenu || !selectedItem) return;
        setDotPosition(submenu, selectedItem);
      };

      menuRef.current.querySelectorAll('.ant-menu-submenu.ant-menu-submenu-open').forEach((container) => {
        const submenu = container.querySelector('.ant-menu');
        const selectedItem = submenu?.querySelector(`.ant-menu-item[data-menu-id="${selectedKey}"]`);
        if (submenu && selectedItem) handleMenu(submenu, selectedItem);
      });

      document.querySelectorAll('.ant-menu-submenu-popup .ant-menu').forEach((submenu) => {
        const selectedItem = submenu.querySelector(`.ant-menu-item[data-menu-id="${selectedKey}"]`);
        if (selectedItem) handleMenu(submenu, selectedItem);
      });
    },
    [menuRef],
  );

  /**
   * This effect handles recalculating the dot position when the page reloads.
   * Since we lose positioning on refresh, we force a fresh calculation after the DOM is ready.
   */
  useEffect(() => {
    if (lastSelectedKey.current) {
      positionCache.current = {};

      const timer = setTimeout(() => {
        if (lastSelectedKey.current) {
          updateDotPosition(lastSelectedKey.current);
        }
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [updateDotPosition]);

  return updateDotPosition;
};

export default useDynamicDotPositioning;

import { TAccountNotificationsForm } from '@app/components/apps/manage-notifications/NotificationsForm';
import { httpApi } from './http.api';
import { notificationController } from '@app/controllers/notificationController';
import { useMutation, useQueryClient } from 'react-query';
import pluralize from 'pluralize';

export const createAccountNotifications = async (data: TAccountNotificationsForm) => {
  const response = await httpApi.post<any>('/notification/create', data);

  if (response?.status === 201) {
    notificationController.success({
      message: `Account Notification Succesfully Created`,
    });
  }

  return response?.data;
};

export const useCreateAccountNotifications = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createAccountNotifications,
    onSuccess() {
      queryClient.invalidateQueries('accountnotificationsextended-odata');
    },
  });
};

const editAccountNotifications = async (payload: { id: number; data: TAccountNotificationsForm }) => {
  const { id, data } = payload;
  const response = await httpApi.patch<any>(`/notification/${id}`, data);
  if (response?.status === 200) {
    notificationController.success({
      message: `Account Notification Succesfully Updated`,
    });
  }

  return response?.data;
};

export const useEditAccountNotifications = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editAccountNotifications,
    onSuccess() {
      queryClient.invalidateQueries('notification-odata');
      queryClient.invalidateQueries('accountnotificationsextended-odata');
    },
  });
};

const markNotificationAsRead = async (id: number) => {
  const response = await httpApi.patch<any>(`/notification/${id}/mark-read`);
  return response?.data;
};

export const useMarkNotificationAsRead = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: markNotificationAsRead,
    onSuccess() {
      queryClient.invalidateQueries('accountnotificationsextended-odata');
    },
  });
};

const hideNotification = async (id: number) => {
  const response = await httpApi.patch<any>(`/notification/${id}/hide`);
  return response?.data;
};

export const useHideNotification = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: hideNotification,
    onSuccess() {
      queryClient.invalidateQueries('accountnotificationsextended-odata');
    },
  });
};

const unhideNotification = async (id: number) => {
  const response = await httpApi.patch<any>(`/notification/${id}/unhide`);
  return response?.data;
};

export const useUnhideNotification = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: unhideNotification,
    onSuccess() {
      queryClient.invalidateQueries('accountnotificationsextended-odata');
    },
  });
};

const deleteNotification = async (ids: number[]) => {
  const response = await httpApi.delete<any>(`/notification`, { data: { ids } });
  if (response?.status === 200) {
    notificationController.success({
      message: `Account ${pluralize('Notification', ids.length)} Deleted`,
    });
  }

  return response?.data;
};

export const useDeleteNotification = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteNotification,
    onSuccess() {
      queryClient.invalidateQueries('accountnotificationsextended-odata');
    },
  });
};

const publishNotification = async (id: number) => {
  const response = await httpApi.patch<any>(`/notification/${id}/publish`);
  if (response?.status === 200) {
    notificationController.success({
      message: `Account Notification Published`,
    });
  }

  return response?.data;
};

export const usePublishNotification = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: publishNotification,
    onSuccess() {
      queryClient.invalidateQueries('notification-odata');
      queryClient.invalidateQueries('accountnotificationsextended-odata');
    },
  });
};

import styled from 'styled-components';
import { BaseBadge } from '@app/components/common/BaseBadge/BaseBadge';

export const StyledBaseBadgeCounter = styled(BaseBadge)`
  .ant-badge-count {
    background-color: var(--badge-counter-bg-color) !important;
    border-radius: 4px !important;
    padding: 4px 8px !important;
    color: black !important;
    font-weight: medium !important;
    font-size: 12px !important;
    text-align: center !important;
    width: 37px !important;
    height: 23px !important;
    line-height: 15px !important;
    display: inline-block !important;
    border: none !important;
    box-shadow: none !important;
  }
`;

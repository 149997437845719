/* eslint-disable no-var */
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  useCreateRecommendation,
  useDeleteRecommendation,
  useDisableRecommendation,
  usePublishRecommendation,
  useSaveAndPublishRecommendation,
  useUpdateRecommendation,
  useValidateAllRecommendations,
  useValidateRecommendation,
} from '@app/api/recommendation.api';
import RecommendationForm, {
  TRecommendationForm,
} from '@app/components/apps/manage-recommendations/RecommendationForm';
import useRecommendation from '@app/components/apps/manage-recommendations/useRecommendation';
import ActionConfirmationModal from '@app/components/apps/manage-users/ActionConfirmationModal';
import { BaseCTAButton } from '@app/components/common/BaseButton/BaseButton.styles';
import ComponentHeader from '@app/components/common/ComponentHeader/ComponentHeader';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { parseDbEnumArray } from '@app/components/common/common';
import CloudSyncIcon from '@app/components/icons/cloud-sync/CloudSync';
import SeverityBadge from '@app/components/tables/AntdTableWrapper/components/SeverityBadge';
import { TActionItems } from '@app/components/tables/AntdTableWrapper/hooks/useTableFilters';
import DataTableV2 from '@app/components/tables/data-table/DataTable';
import { TableActions } from '@app/components/tables/data-table/components/TableActions';
import TableFiltersV2 from '@app/components/tables/data-table/components/TableFilters';
import { useDataTable } from '@app/components/tables/data-table/hooks/useDataTable';
import { useTableFiltersV2 } from '@app/components/tables/data-table/hooks/useTableFilters';
import { TDataColumns } from '@app/components/tables/data-table/types';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';
import { getEnumLabel, getEnumValue } from '@app/services/enum.service';
import { TRecommendation } from '@app/types/recommendation';
import { AxiosResponse } from 'axios';
import { useState } from 'react';
import ExportExcelButton from '@app/components/tables/data-table/components/export-excel/ExportExcelButton';

interface IManageRecommendationsPageProps {
  title?: string;
}

export default function ManageRecommendationsPage(props: IManageRecommendationsPageProps) {
  const { isBigScreen } = useResponsive();
  const productOptions =
    useAppSelector((state) => state.app.products)?.map((opt) => ({
      key: opt.name,
      value: opt.displayName,
    })) || [];
  const licenseOptions =
    useAppSelector((state) => state.app.licenses)?.map((opt) => ({
      key: opt.name,
      value: opt.displayName,
    })) || [];

  const licensePlanOptions =
    useAppSelector((state) => state.app.licensePlans)?.map((opt) => ({
      key: opt.name,
      value: opt.displayName,
    })) || [];

  const manageRecommendationsTableCols: TDataColumns<TRecommendation> = [
    {
      title: 'ID',
      dataIndex: 'id',
      allowFiltering: true,
      type: 'number',
      showSortDirections: true,
      // width: '5vw',
      width: isBigScreen ? '5vw' : '5.5vw',
    },
    {
      title: 'Creation Time',
      dataIndex: 'creationTime',
      allowFiltering: true,
      type: 'date',
      showSortDirections: true,
      // width: '5vw',
      width: isBigScreen ? '5vw' : '4vw',
    },
    {
      title: 'Product',
      dataIndex: 'productName',
      align: 'center',
      allowFiltering: true,
      type: 'textOptions',
      options: productOptions,
      multipleSelect: true,
      showSortDirections: true,
      width: isBigScreen ? '8vw' : '3vw',
    },
    {
      title: 'Findings',
      dataIndex: 'findings',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      // width: '15vw',
      width: isBigScreen ? '15vw' : '13vw',
    },
    {
      title: 'Recommendation Severity',
      dataIndex: 'severity',
      allowFiltering: true,
      type: 'enum',
      align: 'center',
      enumValuesKey: 'RecommendationSeverity',
      showSortDirections: true,
      // width: '8vw',
      width: isBigScreen ? '8vw' : '14vw',
      render: (_, record) => {
        return (
          <SeverityBadge type={(record.severity as unknown as string).toLowerCase() as any}>
            {record.severity}
          </SeverityBadge>
        );
      },
    },
    {
      title: 'Ease of Fix',
      dataIndex: 'easeOfFix',
      allowFiltering: true,
      type: 'enum',
      align: 'center',
      enumValuesKey: 'RecommendationEaseOfFix',
      showSortDirections: true,
      // width: '5vw',
      width: isBigScreen ? '5vw' : '4vw',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      allowFiltering: true,
      type: 'enum',
      align: 'center',
      enumValuesKey: 'RecommendationStatus',
      showSortDirections: true,
      // width: '5vw',
      width: isBigScreen ? '5vw' : '3vw',
    },
    {
      title: () => {
        return (
          <Popover content={<div>Validation Method</div>}>
            <div>
              <CloudSyncIcon />
            </div>
          </Popover>
        );
      },
      altLabel: 'Validation Method',
      dataIndex: 'validationMethod',
      allowFiltering: true,
      showSorterTooltip: false,
      type: 'enum',
      align: 'center',
      enumValuesKey: 'RecommendationValidationMethod',
      showSortDirections: true,
      // width: '5vw',
      width: isBigScreen ? '5vw' : '1vw',
    },
    {
      title: 'Estimated Work',
      dataIndex: 'estimatedWork',
      allowFiltering: true,
      type: 'enum',
      enumValuesKey: 'RecommendationEstimatedWork',
      showSortDirections: true,
      width: 150,
      hideColumn: true,
    },
    {
      title: 'Last Updated Time',
      dataIndex: 'lastUpdatedTime',
      allowFiltering: true,
      type: 'date',
      showSortDirections: true,
      width: 150,
      hideColumn: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: 150,
      hideColumn: true,
    },
    {
      title: 'Remediation',
      dataIndex: 'remediation',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: 150,
      hideColumn: true,
    },
    {
      title: 'Corrective Action',
      dataIndex: 'correctiveAction',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: 150,
      hideColumn: true,
    },
    {
      title: 'Is Valid',
      dataIndex: 'isValid',
      allowFiltering: true,
      type: 'boolean',
      showSortDirections: true,
      width: 150,
      hideColumn: true,
    },
    {
      title: 'Error Description',
      dataIndex: 'errorDescription',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: 150,
      hideColumn: true,
    },
    {
      title: 'Error Account Id',
      dataIndex: 'errorAccountId',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: 150,
      hideColumn: true,
    },
    {
      title: 'Query',
      dataIndex: 'query',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: 200,
      hideColumn: true,
    },
    {
      title: 'Operating System',
      dataIndex: 'operatingSystem',
      allowFiltering: true,
      type: 'enum',
      enumValuesKey: 'OperatingSystem',
      showSortDirections: true,
      width: 150,
      hideColumn: true,
    },
    {
      title: 'Licenses',
      dataIndex: 'licenseNames',
      allowFiltering: true,
      type: 'textOptions',
      options: licenseOptions,
      multipleSelect: true,
      showSortDirections: true,
      width: '20vw',
      hideColumn: true,
    },
    {
      title: 'License Plans',
      dataIndex: 'licensePlans',
      allowFiltering: true,
      type: 'textOptions',
      options: licensePlanOptions,
      multipleSelect: true,
      showSortDirections: true,
      width: '25vw',
      hideColumn: true,
    },
    {
      title: 'Scopes',
      dataIndex: 'scopes',
      allowFiltering: true,
      type: 'enumArray',
      enumValuesKey: 'RecommendationScope',
      showSortDirections: true,
      width: 150,
      hideColumn: true,
      render: (_, rec) => {
        return (
          <>
            {parseDbEnumArray(rec.scopes)
              .map((scope) => getEnumLabel('RecommendationScope', scope))
              .join(', ')}
          </>
        );
      },
    },
    {
      title: 'Reference',
      dataIndex: 'reference',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: 150,
      hideColumn: true,
    },
    {
      title: 'User Impact',
      dataIndex: 'userImpact',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: 150,
      hideColumn: true,
    },
    {
      title: 'Tenant Wide',
      dataIndex: 'tenantWide',
      allowFiltering: true,
      type: 'boolean',
      showSortDirections: true,
      width: 150,
      hideColumn: true,
    },
  ];

  const [error, setError] = useState<string | null>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedRecommendation, setSelectedRecommendation] = useState<TRecommendation[] | undefined>(undefined);
  const [isPublishConfirmationModalOpen, setIsPublishConfirmationModalOpen] = useState(false);
  const [isUpdateConfirmationModalOpen, setIsUpdateConfirmationModalOpen] = useState(false);
  const [isSaveAndPublishConfirmationModalOpen, setIsSaveAndPublishConfirmationModalOpen] = useState(false);
  const [isDisableConfirmationModalOpen, setIsDisableConfirmationModalOpen] = useState(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
  const [formData, setFormData] = useState<TRecommendationForm | undefined>();

  const { t } = useTranslation();
  const { mutateAsync: createRecommendation, isLoading: isCreating } = useCreateRecommendation();
  const { mutateAsync: updateRecommendation, isLoading: isUpdating } = useUpdateRecommendation();
  const { mutateAsync: publishRecommendation, isLoading: isPublishing } = usePublishRecommendation();
  const { mutateAsync: disableRecommendation, isLoading: isDisabling } = useDisableRecommendation();
  const { mutateAsync: saveAndPublishRecommendation, isLoading: isSavingAndPublishing } =
    useSaveAndPublishRecommendation();
  const { mutateAsync: deleteRecommendation, isLoading: isDeleting } = useDeleteRecommendation();

  const { mutateAsync: validateRecommendation } = useValidateRecommendation();
  const { mutateAsync: validateAllRecommendations } = useValidateAllRecommendations();

  const { toggle, ...recommendationFormProps } = useRecommendation({
    onOk: (data) => (isEdit ? confirmUpdateRecommendation(data) : confirmCreateRecommendation(data)),
    onCancel: () => {
      resetState();
    },
    recommendation: selectedRecommendation ? selectedRecommendation[0] : undefined,
  });

  const actionItems: TActionItems<TRecommendation>[] = [
    {
      label: 'Publish',
      key: 'publish',
      multiSelect: true,
      onClick: async (rec) => {
        const ids = rec.map((recommendation) => recommendation.id);
        await publishRecommendation(ids);
        tableFilterProps.refetch();
        dataTableProps.clearSelection();
      },
      show: (rec) => rec.every((r) => (r.status as any) === 'Disabled'),
    },
    {
      label: 'Disable',
      key: 'disable',
      multiSelect: true,
      onClick: async (rec) => {
        const ids = rec.map((recommendation) => recommendation.id);
        await disableRecommendation(ids);
        tableFilterProps.refetch();
        dataTableProps.clearSelection();
      },
      show: (rec) => rec.every((r) => (r.status as any) === 'Enabled'),
    },
    {
      label: 'Validate',
      key: 'validate',
      multiSelect: true,
      onClick: async (rec) => {
        const ids = rec.map((recommendation) => recommendation.id);
        await validateRecommendation(ids);
        tableFilterProps.refetch();
        dataTableProps.clearSelection();
      },
    },
    {
      label: 'Delete',
      key: 'delete',
      multiSelect: true,
      onClick: (rec) => {
        setIsDeleteConfirmationModalOpen(true);
      },
      show: (rec) => rec.every((r) => (r.status as any) === 'Disabled'),
    },
  ];

  const tableFilterProps = useTableFiltersV2<TRecommendation>({
    columns: manageRecommendationsTableCols,
    model: 'recommendationExtended',
    searchableColumns: ['productName', 'findings'],
    orderBy: {
      column: 'id',
      order: 'desc',
    },
    constantFilter: { status: { ne: getEnumValue('RecommendationStatus', 'Deleted') } },
    onApplyFilterCallback: () => {
      dataTableProps.resetPagination();
    },
  });

  var dataTableProps = useDataTable<TRecommendation>({
    data: tableFilterProps.data?.items || [],
    columns: manageRecommendationsTableCols,
    isLoading: tableFilterProps.isFetching,
    totalRows: tableFilterProps.data?.count,
    paginationEventCallback: ({ top, skip }) => {
      tableFilterProps.buildOdataQueryFromParams({ paginationProps: { top, skip } });
    },
    columnChangesEventCallback: (columns, displayedColumnIndexes) => {
      tableFilterProps.buildOdataQueryFromParams({ selectFields: displayedColumnIndexes });
    },
    onSortCallback: (sortObj) => {
      if (!sortObj || (!!sortObj && !sortObj.column)) return;
      tableFilterProps.buildOdataQueryFromParams({
        orderyByProps: { column: sortObj.column || '', order: sortObj.order || 'asc' },
      });
    },
    onRowClick: (data) => {
      setIsEdit(true);
      setSelectedRecommendation([data]);
      toggle();
    },
    onRowSelect: (selectedRows) => {
      if (selectedRows.length > 0) {
        setSelectedRecommendation(selectedRows);
      }
    },
  });

  const createOrUpdateRecommendation = async (data: TRecommendationForm) => {
    let response: AxiosResponse<any, any> | null = null;
    if (isEdit && !!selectedRecommendation) {
      response = await updateRecommendation({
        id: selectedRecommendation[0].id,
        data: data,
      });
    } else {
      const disabledStatus = getEnumValue('RecommendationStatus', 'Disabled');
      if (!!disabledStatus) response = await createRecommendation({ ...data, status: disabledStatus });
    }

    return response;
  };

  const confirmCreateRecommendation = (data: TRecommendationForm) => {
    setFormData(data);
    setIsPublishConfirmationModalOpen(true);
  };

  const confirmUpdateRecommendation = (data: TRecommendationForm) => {
    setFormData(data);
    setIsUpdateConfirmationModalOpen(true);
  };

  const confirmSaveAndPublish = (data: TRecommendationForm) => {
    setFormData(data);
    setIsSaveAndPublishConfirmationModalOpen(true);
  };

  const confirmDisableRecommendation = (data: TRecommendationForm) => {
    setFormData(data);
    setIsDisableConfirmationModalOpen(true);
  };

  const handleConfirmSaveAndPublish = async () => {
    if (!formData) return;

    const response = await saveAndPublishRecommendation({ data: formData, isEdit, id: selectedRecommendation?.[0].id });
    if (response?.status !== 200 && response?.status !== 201) {
      const errorMessage = response?.data?.message;
      setError(errorMessage);
      return;
    }

    resetState();
    toggle();
    tableFilterProps.refetch();
    dataTableProps.clearSelection();
  };

  const handleConfirmCreate = async () => {
    if (!formData) return;

    let response;
    const disabledStatus = getEnumValue('RecommendationStatus', 'Disabled');
    if (!!disabledStatus) {
      response = await createRecommendation({ ...formData, status: disabledStatus });
    }
    if (response?.status !== 200 && response?.status !== 201) {
      const errorMessage = response?.data?.message;
      setError(errorMessage);
      return;
    }

    resetState();
    toggle();
    tableFilterProps.refetch();
    dataTableProps.clearSelection();
  };

  const handleConfirmUpdate = async (dataForm: TRecommendationForm) => {
    // Check for circular references in the data object
    try {
      JSON.stringify(dataForm);
    } catch (error) {
      console.error('Circular reference detected in data object:', error);
      setError('Circular reference detected in data object');
      return;
    }

    let response: AxiosResponse<any, any> | null = null;
    if (isEdit && !!selectedRecommendation) {
      response = await updateRecommendation({
        id: selectedRecommendation[0].id,
        data: dataForm,
      });
    }
    if (response?.status !== 200 && response?.status !== 201) {
      const errorMessage = response?.data?.message;
      setError(errorMessage);
      return;
    }

    resetState();
    toggle();
    tableFilterProps.refetch();
    dataTableProps.clearSelection();
  };

  const handleConfirmDisable = async () => {
    if (!formData) return;

    const response = await createOrUpdateRecommendation(formData);

    if (response?.status !== 200) {
      const errorMessage = response?.data?.message;
      setError(errorMessage);
      return;
    }
    if (!selectedRecommendation) return;
    const ids = selectedRecommendation.map((recommendation) => recommendation.id);
    const disable = await disableRecommendation(ids);

    resetState();
    toggle();
    tableFilterProps.refetch();
    dataTableProps.clearSelection();
  };

  const handleConfirmDelete = async () => {
    if (!selectedRecommendation) return;

    try {
      const ids = selectedRecommendation.map((recommendation) => recommendation.id);
      const deletedRecommendation = await deleteRecommendation(ids);

      onCancelDelete();
      setSelectedRecommendation(undefined);

      tableFilterProps.refetch();
      dataTableProps.clearSelection();
    } catch (error) {
      console.error('Error deleting recommendation:', error);
      setError('Failed to delete recommendation');
    }
  };

  const onCancelDelete = () => {
    setIsDeleteConfirmationModalOpen(false);
    setError(null);
    setIsEdit(false);
  };

  const resetState = () => {
    setIsPublishConfirmationModalOpen(false);
    setIsUpdateConfirmationModalOpen(false);
    setIsSaveAndPublishConfirmationModalOpen(false);
    setIsDisableConfirmationModalOpen(false);
    setIsDeleteConfirmationModalOpen(false);
    setError(null);
    setIsEdit(false);
    setSelectedRecommendation(undefined);
    setFormData(undefined);
  };

  return (
    <>
      <PageTitle>Manage Recommendations</PageTitle>
      <ComponentHeader title={t('common.manageRecommendations')} />
      <div style={{ marginBottom: '32px' }}>
        <TableFiltersV2 {...tableFilterProps}>
          <TableFiltersV2.Actions>
            <BaseCTAButton
              onClick={() => {
                validateAllRecommendations();
              }}
            >
              Validate All
            </BaseCTAButton>
            <BaseCTAButton onClick={toggle}>New recommendation</BaseCTAButton>
          </TableFiltersV2.Actions>
        </TableFiltersV2>
      </div>
      <DataTableV2 {...dataTableProps}>
        <DataTableV2.Actions>
          <TableActions actionItems={actionItems} />
          <ExportExcelButton
            exportType={{ type: 'All', model: 'Recommendation' }}
            columns={manageRecommendationsTableCols}
          />
        </DataTableV2.Actions>
      </DataTableV2>
      <RecommendationForm
        {...recommendationFormProps}
        isEdit={isEdit}
        isLoading={isCreating || isUpdating}
        error={error}
        onOk={isEdit ? confirmUpdateRecommendation : confirmCreateRecommendation}
        onSaveAndPublish={confirmSaveAndPublish}
        onDisableRecommendation={confirmDisableRecommendation}
      />
      <ActionConfirmationModal
        open={isPublishConfirmationModalOpen}
        onOk={handleConfirmCreate}
        onCancel={resetState}
        message="Are You sure? This action will attempt to create the recommendations to all active customer accounts."
        isLoading={isCreating}
      />
      <ActionConfirmationModal
        open={isUpdateConfirmationModalOpen}
        onOk={() => handleConfirmUpdate(formData!)}
        onCancel={resetState}
        message="Are You sure? This action will attempt to update the recommendations to all active customer accounts."
        isLoading={isUpdating}
      />
      <ActionConfirmationModal
        open={isSaveAndPublishConfirmationModalOpen}
        onOk={handleConfirmSaveAndPublish}
        onCancel={resetState}
        message={`Are You sure? This action will attempt to ${
          isEdit ? 'update' : 'create'
        } and publish the recommendations to all active customer accounts.`}
        isLoading={isSavingAndPublishing}
      />
      <ActionConfirmationModal
        open={isDisableConfirmationModalOpen}
        onOk={handleConfirmDisable}
        onCancel={resetState}
        message="Are you sure you want to disable this recommendation?"
        isLoading={isDisabling}
      />
      <ActionConfirmationModal
        open={isDeleteConfirmationModalOpen}
        onOk={handleConfirmDelete}
        onCancel={onCancelDelete}
        message="Are you sure you want to delete this recommendation?"
        isLoading={isDeleting}
      />
    </>
  );
}

/* eslint-disable no-var */
import React, { useState } from 'react';

import { useBlockVendor, useEnableVendor, useInviteVendor } from '@app/api/user.api';
import StatusBadge from '@app/components/apps/alerts/StatusBadge';
import ActionConfirmationModal from '@app/components/apps/manage-users/ActionConfirmationModal';
import InviteVendorsModal from '@app/components/apps/manage-vendors/InviteVendorsModal';
import { BaseCTAButton } from '@app/components/common/BaseButton/BaseButton.styles';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { TActionItems } from '@app/components/tables/AntdTableWrapper/hooks/useTableFilters';
import { TDataColumns } from '@app/components/tables/AntdTableWrapper/types';
import { TableActions } from '@app/components/tables/data-table/components/TableActions';
import TableFiltersV2 from '@app/components/tables/data-table/components/TableFilters';
import DataTableV2 from '@app/components/tables/data-table/DataTable';
import { useDataTable } from '@app/components/tables/data-table/hooks/useDataTable';
import { useTableFiltersV2 } from '@app/components/tables/data-table/hooks/useTableFilters';
import { TVendorsExtended } from '@app/types/vendors';
import EditVendorModal from '@app/components/apps/manage-vendors/EditVendorModal';
import { getEnumValue } from '@app/services/enum.service';
import { useDeleteVendor } from '@app/api/vendor.api';
import pluralize from 'pluralize';

const tableCols: TDataColumns<TVendorsExtended> = [
  {
    title: 'Name',
    dataIndex: 'vendorName',
    allowFiltering: true,
    type: 'text',
    showSortDirections: true,
    width: '20vw',
  },
  {
    title: 'Owner Email',
    dataIndex: 'email',
    allowFiltering: true,
    type: 'text',
    showSortDirections: true,
    width: '20vw',
  },
  {
    title: 'Subscription',
    dataIndex: 'subscriptionName',
    allowFiltering: true,
    type: 'text',
    showSortDirections: true,
    width: '15vw',
  },
  // {
  //   title: 'Date Joined',
  //   dataIndex: 'creationDate',
  //   allowFiltering: true,
  //   type: 'date',
  //   showSortDirections: true,
  // },
  // {
  //   title: 'Last Login Date',
  //   dataIndex: 'lastLoginDate',
  //   allowFiltering: true,
  //   type: 'date',
  //   showSortDirections: true,
  //   width: 150,
  // },
];

const ManageVendorsPage: React.FC = () => {
  const [isInviteVendorsModalOpen, setIsInviteVendorsModalOpen] = useState(false);
  const [isEditVendorModalOpen, setIsEditVendorModalOpen] = useState(false);

  const { mutateAsync: inviteVendors } = useInviteVendor();
  const { mutateAsync: blockVendor, isLoading: isBlocking } = useBlockVendor();
  const { mutateAsync: enableVendor, isLoading: isEnabling } = useEnableVendor();
  const { mutateAsync: deleteVendor, isLoading: isDeleting } = useDeleteVendor();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState<TVendorsExtended[] | null>(null);
  const [action, setAction] = useState<'enable' | 'block' | 'delete' | 're-invite' | null>(null);
  const [message, setMessage] = useState('');

  const status = (status: 'Active' | 'Pending' | 'Blocked') => {
    if (status === 'Active') {
      return 'enabled';
    }

    if (status === 'Pending') {
      return 'pending';
    }

    return 'disabled';
  };

  const actionItems: TActionItems<TVendorsExtended>[] = [
    {
      label: 'Re-invite',
      key: 'reInviteUser',
      multiSelect: true,
      onClick: async (rec) => {
        const vendorInvites = rec.map((item) => ({
          email: item.email,
          vendorName: item.vendorName,
          subscriptionTypeId: item.subscriptionTypeId,
        }));
        await inviteVendors(vendorInvites);
        dataTableProps.clearSelection();
        tableFilterProps.refetch();
      },
      show: (item) => item.every((rec) => (rec.status as any) === 'Pending'),
    },
    {
      label: 'Block',
      key: 'blockUser',
      multiSelect: false,
      onClick: async (item) => {
        setSelectedVendor([item]);
        setAction('block');
        setMessage(`Are you sure you want to block ${item.vendorName}`);
        setIsModalOpen(true);
      },
      show: (item) => (item.status as any) === 'Active',
    },
    {
      label: 'Enable',
      key: 'enableUser',
      multiSelect: false,
      onClick: async (item) => {
        setSelectedVendor([item]);
        setAction('enable');
        setMessage(`Are you sure you want to enable ${item.vendorName}`);
        setIsModalOpen(true);
      },
      show: (item) => (item.status as any) === 'Blocked',
    },
    {
      label: 'Edit',
      key: 'editUser',
      multiSelect: false,
      onClick: async (item) => {
        setSelectedVendor([item]);
        setIsEditVendorModalOpen(true);
      },
      show: (item) => (item.status as any) === 'Active',
    },
    {
      label: 'Delete',
      key: 'deleteVendor',
      multiSelect: true,
      onClick: async (rec) => {
        setSelectedVendor(rec);
        setAction('delete');
        setMessage(
          `Are you sure you want to delete ${rec.length > 1 ? rec.length : 'this'} ${pluralize('vendor', rec.length)}?`,
        );
        setIsModalOpen(true);
      },
      show: (vendors) => vendors.every((vendor) => (vendor.status as any) === 'Blocked'),
    },
  ];

  const manageVendorsCols: TDataColumns<TVendorsExtended> = [
    ...tableCols,
    {
      title: 'Status',
      dataIndex: 'status',
      allowFiltering: true,
      type: 'enum',
      enumValuesKey: 'UserStatus',
      showSortDirections: true,
      width: '15vw',
      render: (val, record) => <StatusBadge type={status(record.status as any)}>{record.status}</StatusBadge>,
    },
  ];

  const tableFilterProps = useTableFiltersV2<TVendorsExtended>({
    columns: manageVendorsCols,
    model: 'vendorsExtended',
    searchableColumns: ['vendorName', 'email', 'subscriptionName'],
    orderBy: {
      column: 'vendorName',
      order: 'asc',
    },
    onApplyFilterCallback: () => {
      dataTableProps.resetPagination();
    },
    constantFilter: { status: { ne: getEnumValue('UserStatus', 'Deleted') } },
  });

  var dataTableProps = useDataTable<TVendorsExtended>({
    data: tableFilterProps.data?.items || [],
    columns: manageVendorsCols,
    isLoading: tableFilterProps.isFetching,
    totalRows: tableFilterProps.data?.count,
    paginationEventCallback: ({ top, skip }) => {
      tableFilterProps.buildOdataQueryFromParams({ paginationProps: { top, skip } });
    },
    columnChangesEventCallback: (columns, displayedColumnIndexes) => {
      tableFilterProps.buildOdataQueryFromParams({ selectFields: displayedColumnIndexes });
    },
    onSortCallback: (sortObj) => {
      if (!sortObj || (!!sortObj && !sortObj.column)) return;
      tableFilterProps.buildOdataQueryFromParams({
        orderyByProps: { column: sortObj.column || '', order: sortObj.order || 'asc' },
      });
    },
    onRowClick: (record) => {
      setSelectedVendor([record]);
      setIsEditVendorModalOpen(true);
    },
  });

  const handleModalClose = () => {
    tableFilterProps.refetch();
    dataTableProps.clearSelection();
    setSelectedVendor(null);
    setAction(null);
    setMessage('');
    setIsModalOpen(false);
  };

  const handleConfirm = async () => {
    if (action === 'block') {
      if (selectedVendor) {
        await blockVendor(selectedVendor?.[0].vendorId);
        handleModalClose();
      }
      return;
    } else if (action === 'delete') {
      if (selectedVendor) {
        const vendorIds = selectedVendor.map((item) => item.vendorId);
        await deleteVendor(vendorIds);
        handleModalClose();
      }
      return;
    } else if (action === 'enable') {
      if (selectedVendor) {
        await enableVendor(selectedVendor?.[0].vendorId);
        handleModalClose();
      }
      return;
    }
  };

  const handleOnUpdateSuccess = () => {
    tableFilterProps.refetch();
    handleModalClose();
  };

  return (
    <>
      <PageTitle>Manage Resellers</PageTitle>
      <div style={{ marginBottom: '32px' }}>
        <TableFiltersV2 {...tableFilterProps}>
          <TableFiltersV2.Actions>
            <BaseCTAButton onClick={() => setIsInviteVendorsModalOpen(true)}>Invite Resellers</BaseCTAButton>
          </TableFiltersV2.Actions>
        </TableFiltersV2>
      </div>
      <DataTableV2 {...dataTableProps}>
        <DataTableV2.Actions>
          <TableActions actionItems={actionItems} />
        </DataTableV2.Actions>
      </DataTableV2>
      <InviteVendorsModal
        open={isInviteVendorsModalOpen}
        onCancel={() => setIsInviteVendorsModalOpen(false)}
        onInviteSuccess={tableFilterProps.refetch}
      />
      {!!selectedVendor && (
        <EditVendorModal
          vendor={selectedVendor[0]}
          open={isEditVendorModalOpen}
          onCancel={() => setIsEditVendorModalOpen(false)}
          onUpdateSuccess={handleOnUpdateSuccess}
        />
      )}

      <ActionConfirmationModal
        open={isModalOpen}
        onOk={handleConfirm}
        onCancel={handleModalClose}
        message={message}
        isLoading={isBlocking || isEnabling || isDeleting}
      />
    </>
  );
};

export default ManageVendorsPage;

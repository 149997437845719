import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import styled from 'styled-components';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import ComponentHeader from '@app/components/common/ComponentHeader/ComponentHeader';
import DataTableV2 from '@app/components/tables/data-table/DataTable';
import { TableActions } from '@app/components/tables/data-table/components/TableActions';
import {
  useCreateDataTableProps,
  useCreateTableFilterProps,
} from '@app/components/apps/data-management/useDataManagementTableCols';
import DataManagementForm, { TDataManagementForm } from '@app/components/apps/data-management/DataManagementForms';
import UseDataManagementForm from '@app/components/apps/data-management/useDataManagementForm';
import {
  useCreateDataManagementEntity,
  useUpdateDataManagementEntity,
  useEstablishLicenseRelationship,
  useDeleteLicenseRelationship,
  useDeleteEntity,
  fetchLicenseRelationships,
  useGetEditableDependencies,
} from '@app/api/dataManagement.api';
import { getEnumValue } from '@app/services/enum.service';
import { BaseCTAButton } from '@app/components/common/BaseButton/BaseButton.styles';
import UseLicenseRelationshipForm from '@app/components/apps/data-management/useLicenseRelationshipForm';
import LicenseRelationshipForm from '@app/components/apps/data-management/LicenseRelationshipForm';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { TLicensePlanLicenses } from '@app/types/licensePlanLicenses';
import { TActionItems } from '@app/components/tables/AntdTableWrapper/hooks/useTableFilters';
import ConfirmationModal from '@app/components/apps/manage-groups/ConfirmationModal';
import { entityConfigs, EntitySelector } from '@app/components/apps/data-management/EntitySelector';
import DeleteVerificationModal from '@app/components/apps/data-management/DeletionVerificationModal';
import TableFiltersV2 from '@app/components/tables/data-table/components/TableFilters';

// Mapping to generate relationship payloads generically
const relationshipMapping = {
  licenses: {
    formKey: 'licensePlanIds',
    mainKey: 'licenseId',
    relatedKey: 'licensePlanId',
  },
  licensePlans: {
    formKey: 'licenseIds',
    mainKey: 'licensePlanId',
    relatedKey: 'licenseId',
  },
};

const PageHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
`;

const TopControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FlexTablesContainer = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  flex-direction: row;

  @media (max-width: 1280px) {
    flex-direction: column;
  }
`;

const TableWrapper = styled.div`
  flex: 1;
  min-width: 300px;

  @media (max-width: 1280px) {
    width: 100%;
  }
`;

const EntityTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const EntityTitleText = styled.span`
  display: inline-block;
  max-width: 100%;
  word-break: break-word;
  white-space: normal;
  line-height: 1.5;
  font-size: 1.2rem;
  @media (min-width: 768px) {
    max-width: 50%;
  }
`;

const DEFAULT_DELETE_MESSAGE = 'Are you sure you want to delete this entity?';

export default function DataManagementPage() {
  const { t } = useTranslation();
  const [selectedEntity, setSelectedEntity] = useState<keyof typeof entityConfigs>('products');
  const [isEdit, setIsEdit] = useState(false);
  const { model, displayName, enumKey, entityName } = entityConfigs[selectedEntity];
  const { mutateAsync: createEntity, isLoading: isCreating } = useCreateDataManagementEntity(model);
  const { mutateAsync: updateEntity, isLoading: isUpdating } = useUpdateDataManagementEntity(model);
  const { mutateAsync: establishLicenseRelationship } = useEstablishLicenseRelationship();
  const { mutateAsync: deleteLicenseRelationship, isLoading: deleteLicenseRelationshipIsLoading } =
    useDeleteLicenseRelationship();
  const { mutateAsync: deleteEntity, isLoading } = useDeleteEntity();
  const [selectedData, setSelectedData] = useState<TDataManagementForm | undefined>(undefined);
  const availableLicenses = useAppSelector((state) => state.app.licenses) ?? [];
  const availableLicensePlans = useAppSelector((state) => state.app.licensePlans) ?? [];
  const [deleteModalMessage, setDeleteModalMessage] = useState(DEFAULT_DELETE_MESSAGE);
  const [disableForceDeletion, setDisableForceDeletion] = useState(false);
  const [selectedDependency, setSelectedDependency] = useState<number | undefined>(undefined);
  const [removeRelationshipModalOpen, setRemoveRelationshipModalOpen] = useState(false);
  const [relationshipsToRemove, setRelationshipsToRemove] = useState<Array<{ id: number }>>([]);
  const { mutate: fetchDependencies, data: dependencies } = useGetEditableDependencies(model);
  const [deleteVerificationModalOpen, setDeleteVerificationModalOpen] = useState(false);

  useEffect(() => {
    fetchDependencies(entityName);
    setSelectedDependency(undefined);
  }, [model, fetchDependencies]);

  // --- Generic helper to build a single relationship payload
  const buildRelationshipPayload = (
    selectedEntity: keyof typeof entityConfigs,
    selectedData: TDataManagementForm,
    row: { id: number },
  ): TLicensePlanLicenses => {
    const mapping = relationshipMapping[selectedEntity as keyof typeof relationshipMapping];
    return {
      [mapping.mainKey]: selectedData.id,
      [mapping.relatedKey]: row.id,
    } as TLicensePlanLicenses;
  };

  // --- Generic helper to build relationships from a list of rows
  const buildRelationships = (
    selectedEntity: keyof typeof entityConfigs,
    selectedData: TDataManagementForm,
    selectedRows: Array<{ id: number }>,
  ): TLicensePlanLicenses[] => {
    return selectedRows.map((row) => buildRelationshipPayload(selectedEntity, selectedData, row));
  };

  const handleDelete = () => {
    if (!selectedData) return;
    // Clear any warning message and open the verification modal:
    setDeleteModalMessage('');
    setDeleteVerificationModalOpen(true);
  };

  // Final deletion function called when user confirms in the modal:
  const handleFinalDelete = async (force: boolean) => {
    if (!selectedData) return;
    const response = await deleteEntity({
      id: selectedData.id,
      type: entityConfigs[selectedEntity].entityName,
      forceDelete: force,
    });
    if (!response.success) {
      if (response.warningOnly) {
        setDeleteModalMessage(response.message);
        setDeleteVerificationModalOpen(true);
      } else {
        setDisableForceDeletion(true);
        setDeleteModalMessage(response.message);
        setDeleteVerificationModalOpen(true);
      }
    } else {
      setSelectedData(undefined);
      tableFilterProps.refetch();
      dataTableProps.clearSelection();
      setDeleteVerificationModalOpen(false);
    }
  };

  const mainTableActionItems: TActionItems<TDataManagementForm>[] = [
    {
      label: 'Edit',
      key: 'edit',
      multiSelect: false,
      onClick: (data) => {
        setSelectedData(data as TDataManagementForm);
        setIsEdit(true);
        toggle();
      },
    },
    {
      label: 'Delete',
      key: 'delete',
      multiSelect: false,
      onClick: handleDelete,
    },
    {
      label: 'Create Relationship',
      key: 'createRelationship',
      multiSelect: false,
      onClick: () => toggleRelationshipModal(),
      show: () => selectedEntity !== 'products',
    },
  ];

  const relatedTableActionItems: TActionItems<TDataManagementForm>[] = [
    {
      label: 'Remove Relationship',
      key: 'removeRelationship',
      multiSelect: true,
      onClick: async (selectedRows) => {
        if (!selectedData) {
          return;
        }
        setRelationshipsToRemove(selectedRows);
        setRemoveRelationshipModalOpen(true);
      },
    },
  ];

  const handleRemoveRelationshipConfirm = async () => {
    if (!selectedData) return;
    try {
      const relationships = buildRelationships(selectedEntity, selectedData, relationshipsToRemove);
      await deleteLicenseRelationship(relationships);
      relatedTableFilterProps.refetch();
      setRemoveRelationshipModalOpen(false);
      relatedDataTableProps.clearSelection();
    } catch (error) {
      console.error('Error removing relationship:', error);
    }
  };

  const tableFilterProps = useCreateTableFilterProps(model);
  const dataTableProps = useCreateDataTableProps(tableFilterProps, 'radio', (selectedRows) => {
    if (selectedRows.length === 1) {
      const firstSelected = selectedRows[0] as TDataManagementForm;
      setSelectedData(firstSelected);
    }
    relatedDataTableProps.clearSelection();
  });
  const relatedTableFilterProps = useCreateTableFilterProps('licensesRelationship', async (queryString) => {
    if (!selectedData || !selectedDepObject) {
      return { items: [], count: 0 };
    }
    return await fetchLicenseRelationships({
      id: selectedData.id as number,
      type: model,
      query: queryString,
    });
  });

  const relatedDataTableProps = useCreateDataTableProps(relatedTableFilterProps, 'checkbox');

  const resetEditState = () => {
    setIsEdit(false);
    setSelectedData(undefined);
  };

  useEffect(() => {
    setSelectedData(undefined);
    dataTableProps.resetPagination();
    tableFilterProps.buildOdataQueryFromParams({ paginationProps: { top: 10, skip: 0 } });
    dataTableProps.clearSelection();
    relatedTableFilterProps.refetch();
  }, [selectedEntity]);

  const filteredAvailableLicensePlans = availableLicensePlans.filter(
    (lp) => !relatedTableFilterProps.data?.items.some((rel: any) => rel.id === lp.id),
  );
  const filteredAvailableLicenses = availableLicenses.filter(
    (l) => !relatedTableFilterProps.data?.items.some((rel: any) => rel.id === l.id),
  );

  const selectedDepObject = dependencies?.find((dep) => dep.id === selectedDependency);

  useEffect(() => {
    if (selectedData && selectedEntity !== 'products' && selectedDepObject) {
      relatedTableFilterProps.refetch();
    }
  }, [selectedData, selectedEntity, selectedDepObject]);

  const { toggle, ...dataManagementFormProps } = UseDataManagementForm({
    onOk: async (data) => {
      const type = getEnumValue('DataManagementType', enumKey) ?? 0;
      const payload = { ...data, type: type as number };
      if (!isEdit) {
        await createEntity(payload);
      } else if (selectedData) {
        await updateEntity({ id: selectedData.id, data: payload });
        resetEditState();
      }
      tableFilterProps.refetch();
      dataTableProps.clearSelection();
      toggle();
    },
    onCancel: () => {
      setIsEdit(false);
    },
    data: selectedData,
  });

  const {
    open: relationshipModalVisible,
    toggle: toggleRelationshipModal,
    onOk: handleLicenseRelationshipOk,
    onCancel: handleLicenseRelationshipCancel,
    ...licenseRelationshipFormProps
  } = UseLicenseRelationshipForm({
    onOk: async (data) => {
      try {
        const mapping = relationshipMapping[selectedEntity as keyof typeof relationshipMapping];
        let relationships: TLicensePlanLicenses[] = [];
        if (selectedData && mapping) {
          relationships = (data[mapping.formKey as keyof typeof data] || []).map((id: number) => ({
            [mapping.mainKey]: selectedData.id,
            [mapping.relatedKey]: id,
          })) as TLicensePlanLicenses[];
        }
        if (relationships.length > 0) {
          await establishLicenseRelationship(relationships);
        }
      } catch (error) {
        console.error('Error establishing relationship:', error);
      }
      toggleRelationshipModal();
      relatedTableFilterProps.refetch();
    },
  });

  return (
    <>
      <PageHeaderWrapper>
        <PageTitle>{t('common.dataManagement')}</PageTitle>
        <TopControls>
          <ComponentHeader title={t('common.dataManagement')} />
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <EntitySelector
              selectedEntity={selectedEntity}
              dependencies={dependencies}
              selectedDependency={selectedDependency}
              onEntityChange={setSelectedEntity}
              onDependencyChange={setSelectedDependency}
            />
          </div>
        </TopControls>
      </PageHeaderWrapper>

      <FlexTablesContainer>
        <TableWrapper>
          <TableFiltersV2 {...tableFilterProps} />
          <Card
            title={
              <EntityTitle>
                <EntityTitleText>{displayName}</EntityTitleText>
                <BaseCTAButton
                  onClick={() => {
                    setSelectedData(undefined);
                    toggle();
                  }}
                >
                  Create {displayName}
                </BaseCTAButton>
              </EntityTitle>
            }
          >
            <DataTableV2 {...dataTableProps}>
              <DataTableV2.Actions>
                <TableActions actionItems={mainTableActionItems} />
              </DataTableV2.Actions>
            </DataTableV2>
          </Card>
        </TableWrapper>

        {dependencies && dependencies.length > 0 && selectedDepObject && (
          <TableWrapper>
            <TableFiltersV2 {...relatedTableFilterProps} />
            <Card
              title={
                <EntityTitle>
                  <EntityTitleText style={{ maxWidth: '50%' }}>
                    {selectedDepObject.dependentTableDisplayName} {selectedData && `for ${selectedData.displayName}`}
                  </EntityTitleText>
                  <BaseCTAButton onClick={toggleRelationshipModal} disabled={!selectedData}>
                    Create Relationship
                  </BaseCTAButton>
                </EntityTitle>
              }
            >
              <DataTableV2 {...relatedDataTableProps}>
                <DataTableV2.Actions>
                  <TableActions actionItems={relatedTableActionItems} />
                </DataTableV2.Actions>
              </DataTableV2>
            </Card>
          </TableWrapper>
        )}
      </FlexTablesContainer>
      {/* Remove Relationship Modal */}
      <ConfirmationModal
        open={removeRelationshipModalOpen}
        message={`Are you sure you want to remove ${relationshipsToRemove.length} relationship(s)?`}
        isLoading={deleteLicenseRelationshipIsLoading}
        confirmButtonMessage="Remove"
        onOk={handleRemoveRelationshipConfirm}
        onCancel={() => setRemoveRelationshipModalOpen(false)}
      />
      <DataManagementForm
        isEdit={isEdit}
        isCreating={isCreating}
        isUpdating={isUpdating}
        dataManagementType={displayName}
        {...dataManagementFormProps}
      />
      {selectedData && (
        <>
          <LicenseRelationshipForm
            availableLicenses={filteredAvailableLicenses}
            availableLicensePlans={filteredAvailableLicensePlans}
            open={relationshipModalVisible}
            selectedEntityType={model}
            selectedData={selectedData}
            onOk={handleLicenseRelationshipOk}
            onCancel={handleLicenseRelationshipCancel}
            {...licenseRelationshipFormProps}
          />

          <DeleteVerificationModal
            open={deleteVerificationModalOpen}
            entityName={selectedData.displayName}
            isLoading={isLoading}
            warningMessage={deleteModalMessage}
            onCancel={() => setDeleteVerificationModalOpen(false)}
            onOk={() => handleFinalDelete(false)}
            onForceOk={() => handleFinalDelete(true)}
            disableForceDeletion={disableForceDeletion}
          />
        </>
      )}
    </>
  );
}

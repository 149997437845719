import AzureAuditEventsBarChart from '@app/components/apps/azure-events-monitoring/azure-bar-chart/AzureAuditEventsBarChart';
import AzureAuditDetailsModal from '@app/components/apps/azure-events-monitoring/AzureAuditDetailsModal';
import { BasePageWrapper } from '@app/components/common/BasePageWrapper/BasePageWrapper';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { TActionItems } from '@app/components/tables/AntdTableWrapper/hooks/useTableFilters';
import { TableActions } from '@app/components/tables/data-table/components/TableActions';
import TableFiltersV2 from '@app/components/tables/data-table/components/TableFilters';
import { DEFAULT_DATE_FORMAT } from '@app/components/tables/data-table/constants';
import DataTableV2 from '@app/components/tables/data-table/DataTable';
import { useDataTable } from '@app/components/tables/data-table/hooks/useDataTable';
import { useTableFiltersV2 } from '@app/components/tables/data-table/hooks/useTableFilters';
import { TDataColumns } from '@app/components/tables/data-table/types';
import { TAzureAuditLogs } from '@app/types/azureAuditLogs';
import { DatePicker } from 'antd';
import { format } from 'date-fns';
import moment from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const azureLogsHistoryTableCols: TDataColumns<TAzureAuditLogs> = [
  {
    title: 'Activity Date',
    dataIndex: 'auditActivityDateTime',
    type: 'date',
    render(_, record) {
      if (!record?.auditActivityDateTime) {
        return <></>;
      }
      return <>{format(record.auditActivityDateTime as any, 'dd/MM/yyyy HH:mm')}</>;
    },
    showSortDirections: true,
    width: '8vw',
  },
  {
    title: 'Audit Type',
    dataIndex: 'itemType',
    allowFiltering: true,
    type: 'enum',
    enumValuesKey: 'AzureAuditItemTypes',
    sortEnumValues: true,
    showSortDirections: true,
    width: '10vw',
  },
  {
    title: 'Action Type',
    dataIndex: 'actionType',
    allowFiltering: true,
    type: 'enum',
    enumValuesKey: 'AzureAuditActionTypes',
    sortEnumValues: true,
    showSortDirections: true,
    width: '12vw',
    sorter: (a, b) => a.itemType.localeCompare(b.itemType),
  },
  {
    title: 'Initiated By',
    dataIndex: 'initiatedByPrincipalName',
    type: 'text',
    showSortDirections: true,
    width: '12vw',
  },
];

export default function AzureAuditLogsHistoryPage() {
  const { t } = useTranslation();
  const [selectedAuditLog, setSelectedAuditLog] = useState<TAzureAuditLogs | null>(null);
  const [isAzureAuditDetailsModalOpen, setIsAzureAuditDetailsModalOpen] = useState<boolean>(false);
  const [timelineRange, setTimelineRange] = useState<RangeValue<moment.Moment> | undefined>([
    moment(new Date()).subtract(90, 'days'),
    moment(new Date()),
  ]);

  const handleOpenAzureAuditDetailsModal = (item: TAzureAuditLogs) => {
    setSelectedAuditLog(item);
    setIsAzureAuditDetailsModalOpen(true);
  };

  const actionItems: TActionItems<TAzureAuditLogs>[] = [
    {
      label: 'View Details',
      key: 'view-details',
      multiSelect: false,
      onClick: (item) => {
        handleOpenAzureAuditDetailsModal(item);
      },
    },
  ];

  const tableFilterProps = useTableFiltersV2<TAzureAuditLogs>({
    columns: azureLogsHistoryTableCols,
    model: 'azureAuditLogsExtended',
    searchableColumns: ['actionType'],
    constantFilter: {
      auditActivityDateTime: { ge: timelineRange?.[0]?.toISOString(), le: timelineRange?.[1]?.toISOString() },
    },
    orderBy: {
      column: 'auditActivityDateTime',
      order: 'desc',
    },
    defaultPaginationParams: {
      top: 50,
      skip: 0,
    },
    onApplyFilterCallback: () => {
      dataTableProps.resetPagination();
    },
  });

  const dataTableProps = useDataTable<TAzureAuditLogs>({
    data: tableFilterProps.data?.items || [],
    columns: azureLogsHistoryTableCols,
    isLoading: tableFilterProps.isFetching,
    totalRows: tableFilterProps.data?.count,
    defaultLimit: 50,
    paginationEventCallback: ({ top, skip }) => {
      tableFilterProps.buildOdataQueryFromParams({ paginationProps: { top, skip } });
    },
    columnChangesEventCallback: (columns, displayedColumnIndexes) => {
      tableFilterProps.buildOdataQueryFromParams({ selectFields: displayedColumnIndexes });
    },
    onSortCallback: (sortObj) => {
      if (!sortObj || (!!sortObj && !sortObj.column)) return;
      tableFilterProps.buildOdataQueryFromParams({
        orderyByProps: { column: sortObj.column || '', order: sortObj.order || 'asc' },
      });
    },
    onRowClick: (data) => handleOpenAzureAuditDetailsModal(data),
  });

  const onDatePickerChange = (value: RangeValue<moment.Moment>, dateString: [string, string]) => {
    if (!value) {
      setTimelineRange(undefined);
      return;
    }

    setTimelineRange(value);
    tableFilterProps.buildOdataQueryFromParams({
      currentConstantFilter: {
        auditActivityDateTime: { ge: value?.[0]?.toISOString(), le: value?.[1]?.toISOString() },
      },
    });
  };

  if (!timelineRange) return;

  const startDate = timelineRange[0]!.toDate();
  const endDate = timelineRange[1]!.toDate();

  return (
    <BasePageWrapper
      title={t('common.eventsHistoryScreen')}
      description="Monitor the historical and crucial events in your Azure Tenant."
    >
      <PageTitle>Azure Logs History</PageTitle>

      <div style={{ marginBottom: '32px' }}>
        <TableFiltersV2 {...tableFilterProps}>
          <TableFiltersV2.Actions>
            <DatePicker.RangePicker
              value={timelineRange}
              format={DEFAULT_DATE_FORMAT}
              onChange={onDatePickerChange}
              allowClear={false}
            />
          </TableFiltersV2.Actions>
        </TableFiltersV2>
      </div>

      <DataTableV2 {...dataTableProps}>
        <DataTableV2.Actions>
          <TableActions actionItems={actionItems} />
        </DataTableV2.Actions>
        <DataTableV2.Charts>
          <AzureAuditEventsBarChart startDate={startDate} endDate={endDate} />
        </DataTableV2.Charts>
      </DataTableV2>

      {isAzureAuditDetailsModalOpen && selectedAuditLog && (
        <AzureAuditDetailsModal
          azureAuditLog={selectedAuditLog}
          open={true}
          onCancel={() => setIsAzureAuditDetailsModalOpen(false)}
        />
      )}
    </BasePageWrapper>
  );
}

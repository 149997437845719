import { useMutation, useQueryClient } from 'react-query';
import { httpApi } from '@app/api/http.api';
import { notificationController } from '@app/controllers/notificationController';
import { TUserInviteRequest, TUserInviteWithGroupRequest, TUpdateUserRoleAndGroupPayload } from '@app/types/users';
import pluralize from 'pluralize';

const deleteUser = async (userId: number) => {
  const response = await httpApi.delete(`/users/${userId}`);
  if (response?.status === 200) {
    notificationController.success({
      message: 'User deleted',
    });
  }

  return response?.data;
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteUser,
    onSuccess: () => {
      queryClient.invalidateQueries('user-odata');
    },
  });
};

const activateUser = async (userId: number) => {
  const response = await httpApi.patch('/users/update', { id: userId, status: 1 });
  if (response?.status === 200) {
    notificationController.success({
      message: 'User approved',
    });
  }
};

export const useActivateUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: activateUser,
    onSuccess: () => {
      queryClient.invalidateQueries('user-odata');
    },
  });
};

const blockUser = async (userId: number) => {
  const response = await httpApi.patch('/users/update', { id: userId, status: 3 });

  if (response?.status === 200) {
    notificationController.success({
      message: 'User blocked',
    });
  }

  return response?.data;
};

export const useBlockUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: blockUser,
    onSuccess() {
      queryClient.invalidateQueries('user-odata');
    },
  });
};

const inviteUsers = async (request: TUserInviteRequest) => {
  const response = await httpApi.post('/users/invite', request);
  if (response?.status === 201) {
    return true;
  }

  return false;
};

export const useInviteUsers = () =>
  useMutation({
    mutationFn: inviteUsers,
  });

type TUpdateUserRoleAndGroupVariables = {
  id: number;
  payload: TUpdateUserRoleAndGroupPayload;
};

const updateUserRoleAndGroup = async ({ id, payload }: TUpdateUserRoleAndGroupVariables) => {
  const response = await httpApi.patch(`/users/${id}/updateRoleAndGroup`, payload);
  return response?.data;
};

export const useUpdateUserRoleAndGroup = () => {
  return useMutation({
    mutationFn: updateUserRoleAndGroup,
    onSuccess: () => {
      notificationController.success({
        message: 'User role and group updated.',
      });
    },
  });
};

const inviteUsersWithGroup = async (request: TUserInviteWithGroupRequest) => {
  const response = await httpApi.post('/users/invite', request);
  if (response?.status === 201) {
    notificationController.success({
      message: 'Users invited',
    });
    return response?.data;
  }
  return undefined;
};

export const useInviteUsersWithGroup = () => {
  return useMutation({
    mutationFn: inviteUsersWithGroup,
  });
};

const reInviteUser = async (email: string) => {
  try {
    const response = await httpApi.post('/users/reinvite', { email });
    if (response?.status === 201) {
      notificationController.success({
        message: `User ${email} re-invited`,
      });
      return true;
    }
  } catch (error) {
    notificationController.error({
      message: `Something went wrong. Please try again.`,
    });
    console.log(error);
    return false;
  }
};

export const useReInviteUser = () =>
  useMutation({
    mutationFn: reInviteUser,
  });

export const inviteAccount = async (email: string) => {
  try {
    const response = await httpApi.post('account/invite', { email });
    if (response?.status === 201) {
      return true;
    }

    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const useInviteAccount = () =>
  useMutation({
    mutationFn: inviteAccount,
  });

export const inviteVendor = async (invites: { email: string; vendorName: string; subscriptionTypeId: number }[]) => {
  const response = await httpApi.post('vendors/invite', { invites });
  if (response?.status === 201) {
    notificationController.success({
      message: `Invited ${invites.length > 1 ? invites.length : ''} ${pluralize('Reseller', invites.length)}`,
    });
  }
  return response?.data;
};

export const useInviteVendor = () =>
  useMutation({
    mutationFn: inviteVendor,
  });

export const blockVendor = async (id: number) => {
  try {
    const response = await httpApi.patch(`vendors/block/${id}`);
    if (response?.status === 200) {
      notificationController.success({
        message: 'Reseller blocked.',
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const useBlockVendor = () =>
  useMutation({
    mutationFn: blockVendor,
  });

export const enableVendor = async (id: number) => {
  try {
    const response = await httpApi.patch(`vendors/enable/${id}`);
    if (response?.status === 200) {
      notificationController.success({
        message: 'Reseller enabled.',
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const useEnableVendor = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: enableVendor,
    onSuccess: () => {
      queryClient.invalidateQueries('vendors-odata');
    },
  });
};

export const updateVendor = async ({
  vendorId,
  vendorName,
  subscriptionTypeId,
}: {
  vendorId: number;
  vendorName: string;
  subscriptionTypeId: number;
}) => {
  try {
    const response = await httpApi.patch(`vendors/update/${vendorId}`, { vendorName, subscriptionTypeId });
    if (response?.status === 200) {
      notificationController.success({
        message: 'Reseller updated.',
      });
    }
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const useUpdateVendor = () =>
  useMutation({
    mutationFn: updateVendor,
  });

import { useMutation, useQuery, useQueryClient } from 'react-query';

import { httpApi } from '@app/api/http.api';
import { TList } from '@app/types/generalTypes';
import { TAlert } from '@app/types/alert';
import { notificationController } from '@app/controllers/notificationController';
import { TAlertExtended } from '@app/types/alertExtended';
import { TActiveAlert } from '@app/types/activeAlert';
import { TAlertsHistoryExtended } from '@app/types/alertsHistoryExtended';
import { getEnumValue } from '@app/services/enum.service';

export async function getActiveAlerts(queryString: string): Promise<TList<TActiveAlert>> {
  const response = await httpApi.get<TList<TActiveAlert>>(`/alert/active${queryString}`);
  return response.data;
}

export const getActiveAlert = async (id: string | undefined): Promise<TActiveAlert | undefined> => {
  const response = await httpApi.get<TList<TActiveAlert>>(`/alert/active?$filter=id eq ${id}`);
  return response?.data?.items?.at(0);
};

const getAlertData = async (id: string | undefined): Promise<TAlert | undefined> => {
  const response = await httpApi.get<TList<TAlert>>(`/odata/Alert?$filter=id eq ${id}`);
  return response.data.items.at(0);
};

export const useGetAlertData = (id: string | undefined) =>
  useQuery({
    queryFn: () => getAlertData(id),
    queryKey: ['alert', id],
    enabled: !!id,
  });

const createAlert = async (form: Omit<TAlert, 'id' | 'creationTime' | 'lastUpdatedTime'>) => {
  const response = await httpApi.post<any>('/alert/create', form);

  if (response?.status === 201) {
    notificationController.success({
      message: 'Alert created',
    });
  }

  return response?.data;
};

export const useCreateAlert = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createAlert,
    onSuccess() {
      queryClient.invalidateQueries('alert-odata');
      queryClient.invalidateQueries('account-status-info');

      queryClient.invalidateQueries('countActiveAlerts');
    },
  });
};

const updateAlert = async (payload: {
  id: string;
  form: Omit<TAlert, 'id' | 'creationTime' | 'lastUpdatedTime'>;
  onSuccessMessage?: string;
}) => {
  const response = await httpApi.patch<any>(`/alert/update/${payload.id}`, payload.form);

  if (response?.status === 200) {
    notificationController.success({
      message: payload.onSuccessMessage ?? 'Alert updated',
    });
  }

  return response?.data;
};

export const useUpdateAlert = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateAlert,
    onSuccess() {
      queryClient.invalidateQueries('alert-odata');
      queryClient.invalidateQueries('account-status-info');

      queryClient.invalidateQueries('countActiveAlerts');
    },
  });
};

const deleteAlert = async (alertId: string) => {
  const response = await httpApi.delete<any>(`/alert/update/${alertId}`);

  if (response?.status === 200) {
    notificationController.success({
      message: 'Alert deleted',
    });
  }

  return response?.data;
};

export const useDeleteAlert = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteAlert,
    onSuccess() {
      queryClient.invalidateQueries('alert-odata');
      queryClient.invalidateQueries('account-status-info');

      queryClient.invalidateQueries('countActiveAlerts');
    },
  });
};

const getLatestAlerts = async () => {
  const response = await httpApi.get<TAlert>('/alert/latest');
  return response.data;
};

export const useGetLatestAlerts = async () =>
  useQuery({
    queryFn: () => getLatestAlerts(),
  });

export const getAlertHistoryItem = async (id: string): Promise<TAlertsHistoryExtended> => {
  const response = await httpApi.get(`/odata/AlertsHistoryExtended?$filter=(id eq '${id}')`);
  return response?.data?.items?.at(0);
};

const countActiveAlerts = async (): Promise<number> => {
  const response = await httpApi.get('/alert/active');
  return response.data.count;
};

export const useCountActiveAlerts = () => {
  return useQuery('countActiveAlerts', countActiveAlerts);
};

import React from 'react';

interface BadgeComponentProps {
  showAsDot?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

export const renderBadge = (
  badgeComponent: React.ReactNode | undefined,
  isCollapsed: boolean,
  isParentSubmenuOpen: boolean,
): React.ReactNode | null => {
  if (!badgeComponent) {
    return null;
  }

  if (isCollapsed) {
    if (React.isValidElement<BadgeComponentProps>(badgeComponent)) {
      return React.cloneElement(badgeComponent, {
        showAsDot: true,
        style: { position: 'absolute', top: '-2px', right: '-2px' },
        className: 'collapsed-alert-badge',
      });
    }
    return badgeComponent;
  } else {
    return isParentSubmenuOpen ? null : badgeComponent;
  }
};

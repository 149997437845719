import { BaseCTAButton } from '@app/components/common/BaseButton/BaseButton.styles';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useGetAuditTypesWithActions, useUpdateTrackingPreferences } from '@app/api/azure-events-monitoring.api';
import { useGetAccountAuditTypes } from '@app/api/account.api';
import { useState } from 'react';
import { MainContainer, SettingDescription, SettingTitle } from '../styles/azure-events-monitoring.styles';
import AuditTypesModal, { AuditSelections } from './AuditTypesModal';
import ConfirmationModal from '../../manage-groups/ConfirmationModal';
import { FaCheck } from 'react-icons/fa6';

const ButtonContainer = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  font-weight: 500;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const Button = styled(BaseCTAButton)`
  padding: 12px 16px;
  border: none;
  font-size: 0.875rem;
`;

const AuditTypesDisplayContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 5rem;
`;

const AuditTypeItem = styled.div`
  font-family: 'Inter', sans-serif;
  color: var(--gray-950);
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  font-size: 0.875rem;
`;

const AuditTypeTitle = styled.div`
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

const AuditActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

const AuditActionItem = styled.div`
  font-family: 'Inter', sans-serif;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: var(--grey-700);
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
`;

export default function AuditTypeSettings() {
  const { t } = useTranslation();
  const [isAuditTypesModalOpen, setIsAuditTypesModalOpen] = useState(false);
  const { data: typesWithActions } = useGetAuditTypesWithActions();
  const { data: accountAuditTypes } = useGetAccountAuditTypes();
  const { mutateAsync: updateTrackingPreferences, isLoading } = useUpdateTrackingPreferences();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedAuditTypes, setSelectedAuditTypes] = useState<AuditSelections>();

  const handleSave = (auditSelections: AuditSelections) => {
    setSelectedAuditTypes(auditSelections);
    setIsConfirmationModalOpen(true);
  };

  const handleUpdateTrackingPreferences = async () => {
    if (!selectedAuditTypes) return;
    await updateTrackingPreferences(selectedAuditTypes);
    setIsConfirmationModalOpen(false);
    setIsAuditTypesModalOpen(false);
  };

  return (
    <MainContainer>
      <SettingTitle>Audit Item Types</SettingTitle>
      <SettingDescription>{t('eventsMonitoringPage.auditTypeSettingDescription')}</SettingDescription>

      {accountAuditTypes && accountAuditTypes.accountAuditItemTypes && (
        <AuditTypesDisplayContainer>
          {accountAuditTypes.accountAuditItemTypes.map((type) => (
            <AuditTypeItem key={type.itemType}>
              <AuditTypeTitle>{type.itemDisplayName}</AuditTypeTitle>
              <AuditActions>
                {type.actionDisplayNames.map((action) => (
                  <AuditActionItem key={action}>
                    <FaCheck />
                    <span>{action}</span>
                  </AuditActionItem>
                ))}
              </AuditActions>
            </AuditTypeItem>
          ))}
        </AuditTypesDisplayContainer>
      )}

      <ButtonContainer>
        <Button
          onClick={() => setIsAuditTypesModalOpen(true)}
          style={{ padding: '0', width: '5rem', height: '2.5rem' }}
        >
          {accountAuditTypes && accountAuditTypes.accountAuditItemTypes ? 'Edit' : 'Select'}
        </Button>
      </ButtonContainer>

      {typesWithActions && accountAuditTypes && (
        <AuditTypesModal
          auditTypes={typesWithActions}
          accountAuditTypes={accountAuditTypes}
          open={isAuditTypesModalOpen}
          onCancel={() => setIsAuditTypesModalOpen(false)}
          onOk={handleSave}
        />
      )}

      <ConfirmationModal
        open={isConfirmationModalOpen}
        message={`Are you sure? This will update your tracking preferences`}
        onOk={handleUpdateTrackingPreferences}
        onCancel={() => setIsConfirmationModalOpen(false)}
        isLoading={isLoading}
        confirmButtonMessage={'Confirm'}
      />
    </MainContainer>
  );
}

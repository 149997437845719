import { Modal, ModalFuncProps } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { TDataColumns } from '@app/components/tables/AntdTableWrapper/types';
import styled from 'styled-components';
import { DataTable } from '@app/components/tables/AntdTableWrapper';
import { TRegulationControl, TRegulationControlExtended } from '@app/types/regulationControl';
import { useState } from 'react';
import useRegulationControlsForm from '@app/components/apps/manage-regulation-controls/useRegulationControlsForm';
import RegulationControlsForm from '@app/components/apps/manage-regulation-controls/RegulationControlsForm';
import Button from '@app/components/common/Button/Button';
import {
  useCreateRegulationControl,
  useDeleteRegulationControl,
  useUpdateRegulationControl,
} from '@app/api/regulation-control.api';
import { useDataTable } from '@app/components/tables/AntdTableWrapper/hooks/useDataTable';
import { TActionItems } from '@app/components/tables/AntdTableWrapper/hooks/useTableFilters';
import ActionConfirmationModal from '@app/components/apps/manage-users/ActionConfirmationModal';

const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  flex-basis: 40%;
  font-weight: 700;
`;

const Value = styled.div`
  flex-basis: 60%;
  font-weight: 500;
  font-size: 14px;
`;

interface IAlertRegulationData {
  RegulationName: string;
  RegulationId: number;
}

interface IAlertControlsModalProps extends ModalFuncProps {
  regulationData?: IAlertRegulationData | undefined;
  open: boolean;
  onClose?: () => void;
}

const manageControlCols: TDataColumns<TRegulationControl> = [
  {
    title: 'Control ID',
    dataIndex: 'controlId',
    allowFiltering: true,
    type: 'text',
    showSortDirections: true,
    width: '10vw',
  },
  {
    title: 'Text',
    dataIndex: 'text',
    allowFiltering: true,
    type: 'text',
    showSortDirections: true,
  },
];
export default function RegulationsModal({ regulationData, open, ...modalProps }: IAlertControlsModalProps) {
  const [isEdit, setIsEdit] = useState(false);
  const [selectedRegulationControl, setSelectedRegulationControl] = useState<TRegulationControl[] | undefined>(
    undefined,
  );
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const { mutateAsync: createRegulationControl, isLoading: isCreateRegulationControlLoading } =
    useCreateRegulationControl();
  const { mutateAsync: updateRegulationControl, isLoading: isRegulationControlUpdating } = useUpdateRegulationControl();
  const { mutateAsync: deleteRegulationControl, isLoading: isRegulationControlDeleting } = useDeleteRegulationControl();

  const { toggle, ...regulationControlFormProps } = useRegulationControlsForm({
    onOk: async (data) => {
      if (isEdit && !!selectedRegulationControl) {
        await updateRegulationControl({
          id: selectedRegulationControl[0].id,
          data: data,
        });
      } else {
        await createRegulationControl({ ...data, regulationId: regulationData?.RegulationId });
      }

      setIsEdit(false);
      setSelectedRegulationControl(undefined);
      toggle();
      dataTableProps.refetch();
    },
    onCancel: () => {
      setIsEdit(false);
      setSelectedRegulationControl(undefined);
    },
    regulationControl: selectedRegulationControl ? selectedRegulationControl[0] : undefined,
  });

  const actionItems: TActionItems<TRegulationControl>[] = [
    {
      label: 'Edit Regulation Control',
      key: 'edit',
      multiSelect: false,
      onClick: (rec) => {
        setIsEdit(true);
        setSelectedRegulationControl([rec]);
        toggle();
      },
    },
    {
      label: 'Delete Regulation Control',
      key: 'delete',
      multiSelect: true,
      onClick: (rec) => {
        setSelectedRegulationControl(rec);
        setIsConfirmationModalOpen(true);
      },
    },
  ];

  const dataTableProps = useDataTable<TRegulationControl>({
    model: 'RegulationControl',
    columns: manageControlCols,
    constantFilter: `(regulationId eq ${regulationData?.RegulationId}) and (status eq 1)`,
    defaultOrder: {
      column: 'controlId',
      order: 'asc',
    },
    expand: ['recommendations'],
    actionItems,
    trigger: open,
    noUrl: true,
    onRowClick: (data, index) => {
      setIsEdit(true);
      setSelectedRegulationControl([data]);
      toggle();
    },
  });

  const handleModalClose = () => {
    setSelectedRegulationControl(undefined);
    setIsConfirmationModalOpen(false);
  };

  const handleConfirm = async () => {
    if (!!selectedRegulationControl) {
      const ids = selectedRegulationControl.map((control) => control.id);
      await deleteRegulationControl(ids);
      handleModalClose();
      dataTableProps.refetch();
      dataTableProps.clearSelection();
    }
  };

  return (
    <Modal
      title={'Regulation Controls'}
      open={open}
      {...modalProps}
      destroyOnClose={true}
      closeIcon={<CloseOutlined style={{ color: '#D81C2E' }} />}
      footer={null}
      width="70vw"
    >
      <ColumnsContainer style={{ marginBottom: '16px' }}>
        <Row>
          <Label>Regulation Name: </Label>
          <Value>{regulationData?.RegulationName}</Value>
          <Button title="New Control" onPress={toggle} />
        </Row>
      </ColumnsContainer>
      <DataTable {...dataTableProps} />
      <RegulationControlsForm
        {...regulationControlFormProps}
        isEdit={isEdit}
        isLoading={isCreateRegulationControlLoading || isRegulationControlUpdating}
      />
      <ActionConfirmationModal
        open={isConfirmationModalOpen}
        onOk={handleConfirm}
        onCancel={handleModalClose}
        message="Are you sure you want to delete this control?"
        isLoading={isRegulationControlDeleting}
      />
    </Modal>
  );
}

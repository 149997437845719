import { CloseOutlined, DownOutlined } from '@ant-design/icons';
import { DatePicker, Modal, ModalFuncProps, Select } from 'antd';
import { TCustomData } from '../AdditionalDetails';
import styled from 'styled-components';
import { FONT_WEIGHT } from '@app/styles/themes/constants';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import GenerateReportFooter from './GenerateReportFooter';
import EmailRecipients from '@app/components/common/EmailRecipients/EmailRecipients';
import { useEmailGroupInput } from '../../manage-users/EmailGroupInput/useEmailGroupInput';
import { createReport, sendEmailReport } from '@app/api/report.api';
import saveAs from 'file-saver';
import { BaseModalCloseButton, BaseModalTitle } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { getImpersonationToken } from '@app/services/localStorage.service';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.span`
  font-size: 1.15rem;
  font-weight: ${FONT_WEIGHT.medium};
  color: var(--grey-950);
`;

const Description = styled.span`
  font-size: 1rem;
  font-weight: ${FONT_WEIGHT.medium};
  color: var(--grey-600);
`;

const CustomFieldsForm = styled.form`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const CustomFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FieldLabel = styled.span`
  font-size: 0.9rem;
  font-weight: ${FONT_WEIGHT.medium};
  color: var(--grey-950);
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TextInput = styled.input`
  background-color: white;
  border: 1px solid #e9eaeb;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 500;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
`;

const RangeDateInput = styled(DatePicker.RangePicker)``;

const DateInput = styled(DatePicker)`
  .ant-picker-input > input {
    font-size: 0.8rem !important;
  }

  .ant-picker-input > input::placeholder {
    color: var(--grey-400) !important;
  }
`;

const CustomSelect = styled(Select)`
  .ant-select-selector {
    height: 2.5rem !important;
    border-radius: 4px !important;
  }

  .ant-select-selection-item {
    display: flex;
    justify-content: start;
    align-items: center;
    height: 2.5rem !important;
    font-size: 0.8rem;
  }
`;

interface IGenerateReportModalProps extends ModalFuncProps {
  report: TCustomData[];
  open: boolean;
  onCancel: () => void;
}

type TOptions = {
  label: string;
  value: string;
};

interface GeneratedReportData {
  name: string;
  blob: Blob;
}

const ctaOptions: TOptions[] = [
  { label: 'Download Report', value: 'download' },
  { label: 'Email Report', value: 'email' },
];

const formatOptions: TOptions[] = [
  { label: 'PDF', value: 'pdf' },
  { label: 'Word Document', value: 'docx' },
];

export default function GenerateReportModal({ report, open, onCancel, ...modalProps }: IGenerateReportModalProps) {
  const [selectedAction, setSelectedAction] = useState<string>(ctaOptions[0].value);
  const [selectedFormat, setSelectedFormat] = useState<string>(formatOptions[0].value);
  const [emails, setEmails] = useState<string[]>([]);
  const [isSelectOpen, setSelectOpen] = useState(false);
  const [reportFormatDropdownOpen, setReportFormatDropdownOpen] = useState(false);

  const {
    control,
    formState: { errors },
    watch,
    getValues,
  } = useForm({
    mode: 'onChange',
  });

  const formValues = watch();
  const isImpersonated = !!getImpersonationToken();

  const emailGroupInputProps = useEmailGroupInput({
    defaultEmailList: [],
    onAddEmail: (newEmailList) => {
      setEmails(newEmailList);
    },
  });

  const isActionDisabled = useMemo(() => {
    const requiredDateFields: string[] = [];

    report.forEach((r) => {
      r.customData.forEach((field) => {
        if (field.type === 'Date' && field.isRequired) {
          requiredDateFields.push(`${r.name}.customData.${field.name}`);
        }
      });
    });

    const allDatesFilled = requiredDateFields.every((path) => {
      const parts = path.split('.');
      const val = formValues?.[parts[0]]?.customData?.[parts[2]];
      return val && val !== '';
    });

    if (selectedAction === 'download') {
      return !allDatesFilled;
    } else {
      return !allDatesFilled || emails.length === 0;
    }
  }, [report, selectedAction, formValues, emails.length]);

  const handleDropdownVisibleChange = (open: boolean) => {
    setSelectOpen(open);
  };

  const handleReportFormatDropdownVisibleChange = (open: boolean) => {
    setReportFormatDropdownOpen(open);
  };

  const handleSelectChange = (value: unknown) => {
    setSelectedAction(value as string);
  };

  const handleReportFormatChange = (value: unknown) => {
    setSelectedFormat(value as string);
  };

  const disabledDate = (currentDate: moment.Moment, minDateString?: string, maxDateString?: string) => {
    let minDate: Date | undefined;
    let maxDate: Date | undefined;

    if (minDateString) {
      if (minDateString === 'currentDate') {
        minDate = new Date();
      } else {
        minDate = new Date(minDateString);
      }
    }

    if (maxDateString) {
      if (maxDateString === 'currentDate') {
        maxDate = new Date();
      } else {
        maxDate = new Date(maxDateString);
      }
    }

    if (minDate && maxDate) {
      return currentDate && (currentDate < moment(minDate) || currentDate > moment(maxDate));
    }

    if (minDate && !maxDate) {
      return currentDate && currentDate < moment(minDate);
    }

    if (!minDate && maxDate) {
      return currentDate && currentDate > moment(maxDate);
    }

    return false;
  };

  const getPayload = (data: any) => {
    const payload = Object.keys(data).map((key) => {
      const customDataKeys = Object.keys(data[key].customData);
      const customDataObj = customDataKeys.map((customDataKey) => {
        const type = report
          .find((cData) => cData.name === key)
          ?.customData?.find((cData) => cData.name === customDataKey)?.type;
        const value =
          type === 'Text'
            ? data[key]['customData'][customDataKey]
            : moment(data[key]['customData'][customDataKey]).format('DD/MM/YYYY');
        return {
          [customDataKey]: value,
        };
      });
      return {
        name: key,
        customData: customDataObj,
      };
    });

    return payload;
  };

  const onActionClick = async () => {
    if (selectedAction === 'download') {
      const data = getValues();
      const payload = getPayload(data);

      // get list of report names and their corresponding data
      const toBeGeneratedReports: Promise<GeneratedReportData>[] = payload
        .map((p) => p.customData.map((cData) => ({ name: p.name, customData: cData })))
        .flatMap((p) => p)
        .map(
          (p) =>
            new Promise((resolve, reject) => {
              createReport(p.name, p.customData, selectedFormat).then((blob) => {
                if (!blob) {
                  reject();
                } else {
                  resolve({ name: p.name, blob: blob || new Blob() });
                }
              });
            }),
        );

      // wait for all reports to be downloaded from the server
      const reportsToGenerate = await Promise.all(toBeGeneratedReports);
      // download each report to the user's PC
      reportsToGenerate.forEach((reportToGenerate) => {
        saveAs(reportToGenerate.blob, reportToGenerate.name);
      });
    } else {
      const payload = getPayload(getValues());

      const toBeEmailedReports = payload
        .map((p: any) => p.customData.map((cData: any) => ({ name: p.name, customData: cData })))
        .flatMap((p: any) => p)
        .map((p: any) => sendEmailReport(p.name, p.customData, emailGroupInputProps.emails, selectedFormat));

      await Promise.all(toBeEmailedReports);
    }
  };

  return (
    <Modal
      title={<BaseModalTitle style={{ fontSize: '1.25rem', fontWeight: '500' }}>Generate Report</BaseModalTitle>}
      open={open}
      onCancel={onCancel}
      destroyOnClose={true}
      closeIcon={<BaseModalCloseButton />}
      footer={
        <GenerateReportFooter
          actionType={selectedAction}
          loadingText={selectedAction === 'download' ? 'Downloading...' : 'Sending...'}
          onCancel={onCancel}
          onActionClick={onActionClick}
          isDisabled={isActionDisabled}
        />
      }
      width="40vw"
      {...modalProps}
    >
      <Container>
        {report.map((data, index) => {
          return (
            <div key={index}>
              <ContentContainer>
                <Title>{data.name}</Title>
                <Description>Please enter the following details which are required to generate the report.</Description>
              </ContentContainer>
              <CustomFieldsForm>
                <SectionContainer>
                  <FieldLabel>Select an action</FieldLabel>
                  <CustomSelect
                    defaultValue={ctaOptions[0].value}
                    onChange={handleSelectChange}
                    style={{ width: '100%' }}
                    options={ctaOptions}
                    open={isSelectOpen}
                    onDropdownVisibleChange={handleDropdownVisibleChange}
                    suffixIcon={
                      <DownOutlined rotate={isSelectOpen ? 180 : 0} onClick={() => setSelectOpen(!isSelectOpen)} />
                    }
                  />
                </SectionContainer>
                {isImpersonated && (
                  <SectionContainer>
                    <FieldLabel>Report format</FieldLabel>
                    <CustomSelect
                      defaultValue={formatOptions[0].value}
                      onChange={handleReportFormatChange}
                      style={{ width: '100%' }}
                      options={formatOptions}
                      open={reportFormatDropdownOpen}
                      onDropdownVisibleChange={handleReportFormatDropdownVisibleChange}
                      suffixIcon={
                        <DownOutlined
                          rotate={reportFormatDropdownOpen ? 180 : 0}
                          onClick={() => setReportFormatDropdownOpen(!reportFormatDropdownOpen)}
                        />
                      }
                    />
                  </SectionContainer>
                )}
                {data.customData.map((field, fieldIndex) => (
                  <CustomFieldContainer key={fieldIndex}>
                    {/* If the field is "Date" */}
                    {field.type === 'Date' && (
                      <SectionContainer>
                        <FieldLabel>{field.label}</FieldLabel>
                        <Controller
                          control={control}
                          name={`${data.name}.customData.${field.name}`}
                          rules={{ required: field.isRequired }}
                          render={({ field: rhfField }) => (
                            <DateInput
                              placeholder="Select Date"
                              format="DD/MM/yyyy"
                              {...rhfField}
                              disabledDate={(currentDate) => disabledDate(currentDate, field.minDate, field.maxDate)}
                              style={{
                                height: '2.5rem',
                                borderRadius: '4px',
                              }}
                            />
                          )}
                        />
                        {!!errors[field.name] && <ErrorMessage>Field is required.</ErrorMessage>}
                      </SectionContainer>
                    )}

                    {/* If the field is "Text" */}
                    {field.type === 'Text' && (
                      <SectionContainer>
                        <FieldLabel>{field.label}</FieldLabel>
                        <TextInput placeholder="Enter text" />
                        {!!errors[field.name] && <ErrorMessage>Field is required.</ErrorMessage>}
                      </SectionContainer>
                    )}

                    {/* If the field is "DateRange" */}
                    {field.type === 'DateRange' && (
                      <SectionContainer>
                        <FieldLabel>{field.label}</FieldLabel>
                        <RangeDateInput format="DD/MM/yyyy" />
                        {!!errors[field.name] && <ErrorMessage>Field is required.</ErrorMessage>}
                      </SectionContainer>
                    )}

                    {/* If action = 'email', show the EmailRecipients */}
                    {selectedAction === 'email' && (
                      <SectionContainer>
                        <FieldLabel>User Email Addresses</FieldLabel>
                        <EmailRecipients {...emailGroupInputProps} />
                      </SectionContainer>
                    )}
                  </CustomFieldContainer>
                ))}
              </CustomFieldsForm>
            </div>
          );
        })}
      </Container>
    </Modal>
  );
}

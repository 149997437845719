import useToggle from '@app/hooks/useToggle';
import { TDataManagementForm } from './DataManagementForms';

interface IUseDataManagementFormProps {
  onCancel?: () => void;
  onOk?: (data: TDataManagementForm, type: string) => void;
  data?: TDataManagementForm;
}
export default function UseDataManagementForm(props?: IUseDataManagementFormProps) {
  const [isModalOpen, toggle, setIsModalOpen] = useToggle();

  const handleOnOk = (data: TDataManagementForm, type: string) => {
    props?.onOk?.(data, type);
  };

  const handleOnCancel = () => {
    props?.onCancel?.();
    setIsModalOpen(false);
  };
  return {
    open: isModalOpen,
    data: props?.data,
    toggle,
    onOk: handleOnOk,
    onCancel: handleOnCancel,
  };
}

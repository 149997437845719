import { useMutation, useQueryClient } from 'react-query';
import { httpApi } from './http.api';
import { notificationController } from '@app/controllers/notificationController';
import pluralize from 'pluralize';

const deleteVendor = async (vendorIds: number[]) => {
  const response = await httpApi.patch('/vendors/delete', { vendorIds });
  if (response.status === 200) {
    notificationController.success({
      message: `${vendorIds.length > 1 ? vendorIds.length : ''} ${pluralize(
        'Reseller',
        vendorIds.length,
      )} successfully deleted.`,
    });
    return;
  }
  return response.data;
};

export const useDeleteVendor = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteVendor,
    onSuccess: () => {
      queryClient.invalidateQueries('vendors-odata');
    },
  });
};

import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';
import { TAzureAuditLogs } from '@app/types/azureAuditLogs';
import { capitalizeWords, formatDate } from '@app/utils/utils';
import Modal, { ModalFuncProps } from 'antd/lib/modal/Modal';
import styled from 'styled-components';

const DetailParagraph = styled.p`
  margin: 0 0 1.5rem 0;
  font-size: 1rem;
  line-height: 1.4;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Italic = styled.span`
  font-style: italic;
`;

const Underline = styled.span`
  text-decoration: underline;
`;

interface IAzureAuditDetailsModalProps extends ModalFuncProps {
  azureAuditLog: TAzureAuditLogs;
  open: boolean;
  onClose?: () => void;
}
function renderAuditDetails(auditLog: TAzureAuditLogs, itemType: string, actionType: string): JSX.Element {
  const formattedDate = formatDate(auditLog.auditActivityDateTime);
  const details = auditLog.details;
  const initiatedBy = auditLog.initiatedByPrincipalName;
  const formattedEventType = capitalizeWords(actionType);

  // Fallback content if no target resources are available.
  const defaultFallback = (
    <div className="p-2 space-y-2 border rounded-lg">
      <DetailParagraph>
        <Bold>Time:</Bold> {formattedDate}
      </DetailParagraph>
      <DetailParagraph>
        <Bold>Event Type:</Bold> {formattedEventType}
      </DetailParagraph>
      <DetailParagraph>
        <Bold>Performed by:</Bold> {initiatedBy}
      </DetailParagraph>
      <DetailParagraph>
        <Bold>Event Details:</Bold> No further details available.
      </DetailParagraph>
    </div>
  );

  if (details.targetResources && details.targetResources.length > 0) {
    const category = details.category;
    const target = details.targetResources[0];
    const firstModified = target.modifiedProperties[0];
    const modified = target.modifiedProperties[1];
    const operationType = details.operationType.toLowerCase();

    let eventDetails;

    switch (category) {
      case 'Group':
      case 'GroupManagement':
        if (operationType === 'delete') {
          eventDetails = (
            <>
              Group <Underline>{target.displayName}</Underline> was deleted.
            </>
          );
        } else if (operationType === 'add') {
          eventDetails = (
            <>
              Group <Underline>{target.displayName}</Underline> was created.
            </>
          );
        } else if (operationType === 'assign' && actionType.toLowerCase() === 'group member added') {
          eventDetails = (
            <>
              <Underline>{target.userPrincipalName || target.displayName}</Underline> was added to Group{' '}
              <Bold>{modified.newValue || firstModified.newValue}</Bold>.
            </>
          );
        } else if (operationType === 'assign' && actionType.toLowerCase() === 'group owner set') {
          eventDetails = (
            <>
              <Underline>{target.userPrincipalName || target.displayName}</Underline> was added as owner to Group{' '}
              <Bold>{modified.newValue || firstModified.newValue}</Bold>.
            </>
          );
        } else if (operationType === 'unassign') {
          eventDetails = (
            <>
              <Underline>{target.userPrincipalName || target.displayName}</Underline> was removed from Group{' '}
              <Bold>{modified.oldValue || firstModified.oldValue}</Bold>.
            </>
          );
        } else if (operationType === 'update') {
          eventDetails = (
            <>
              Group <Underline>{target.displayName}</Underline> details were updated.
            </>
          );
        } else {
          return defaultFallback;
        }
        break;

      case 'Role':
      case 'ServicePrincipal':
      case 'RoleManagement':
        if (operationType === 'assign') {
          eventDetails = (
            <>
              Role <Bold>{modified.newValue || firstModified.newValue}</Bold> was assigned to{' '}
              <Underline>{target.userPrincipalName || target.displayName}</Underline>.
            </>
          );
        } else if (operationType === 'unassign') {
          eventDetails = (
            <>
              Role <Bold>{modified.oldValue || firstModified.oldValue}</Bold> was removed from{' '}
              <Underline>{target.userPrincipalName || target.displayName}</Underline>.
            </>
          );
        } else {
          return defaultFallback;
        }
        break;

      case 'Policy':
        eventDetails = (
          <>
            Policy <Underline>{target.displayName}</Underline> was modified.
          </>
        );
        break;

      default:
        return defaultFallback;
    }

    return (
      <div className="p-2 space-y-2 border rounded-lg">
        <DetailParagraph>
          <Bold>Time:</Bold> {formattedDate}
        </DetailParagraph>
        <DetailParagraph>
          <Bold>Event Type:</Bold> {formattedEventType}
        </DetailParagraph>
        <DetailParagraph>
          <Bold>Performed by:</Bold> {initiatedBy}
        </DetailParagraph>
        <DetailParagraph>
          <Bold>Event Details:</Bold> {eventDetails}
        </DetailParagraph>
      </div>
    );
  }

  return defaultFallback;
}

export default function AzureAuditDetailsModal({ azureAuditLog, ...modalProps }: IAzureAuditDetailsModalProps) {
  const itemType = azureAuditLog.itemType;
  const actionType = azureAuditLog.actionType;

  return (
    <Modal
      title={<span style={{ fontSize: '1.25rem', fontWeight: '500' }}>Event Details</span>}
      {...modalProps}
      destroyOnClose={true}
      closeIcon={<CloseOutlined style={{ color: 'gray' }} />}
      footer={null}
      width="40vw"
    >
      {renderAuditDetails(azureAuditLog, itemType, actionType)}
    </Modal>
  );
}

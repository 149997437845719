import { useMutation, useQueryClient } from 'react-query';

import { httpApi } from './http.api';
import { notificationController } from '@app/controllers/notificationController';
import { TRegulationControlsForm } from '@app/components/apps/manage-regulation-controls/RegulationControlsForm';
import pluralize from 'pluralize';

export const createRegulationControl = async (data: TRegulationControlsForm) => {
  const response = await httpApi.post<any>('/regulation-control/create', data);

  if (response?.status === 201) {
    notificationController.success({
      message: `Regulation Control Created`,
    });
  }

  return response?.data;
};

export const useCreateRegulationControl = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createRegulationControl,
    onSuccess() {
      queryClient.invalidateQueries('RegulationControl-odata');
    },
  });
};

const updateRegulationControl = async (payload: { id: number; data: TRegulationControlsForm }) => {
  const { id, data } = payload;

  const response = await httpApi.patch<any>(`/regulation-control/${id}`, data);

  if (response?.status === 200) {
    notificationController.success({
      message: `Regulation Control Updated`,
    });
  }

  return response?.data;
};

export const useUpdateRegulationControl = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateRegulationControl,
    onSuccess() {
      queryClient.invalidateQueries('RegulationControl-odata');
    },
  });
};

const deleteRegulationControl = async (ids: number[]) => {
  const response = await httpApi.delete<any>(`/regulation-control`, { data: { ids } });
  if (response?.status === 200) {
    notificationController.success({
      message: `Regulation ${pluralize('Control', ids.length)} Deleted`,
    });
  }

  return response?.data;
};

export const useDeleteRegulationControl = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteRegulationControl,
    onSuccess() {
      queryClient.invalidateQueries('RegulationControl-odata');
    },
  });
};

import Checkbox from 'antd/lib/checkbox/Checkbox';
import styled from 'styled-components';
import { AuditSelections } from './AuditTypesModal';
import { TAuditTypesWithActions } from '@app/api/azure-events-monitoring.api';

const AuditTypeSection = styled.div`
  font-family: 'Inter', sans-serif;
  border-bottom: 1px solid var(--grey-200);
  padding: 8px 0;
`;

const AuditTypeHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

const AuditTypeTitle = styled.span`
  font-size: 0.875rem;
  margin-left: 8px;
  font-weight: 500;
  color: var(--grey-950);
`;

const AuditActionItem = styled.div`
  display: flex;
  align-items: center;
  margin-left: 24px;
  margin-bottom: 4px;
`;

const ActionItem = styled.span`
  font-size: 0.75rem;
  margin-left: 8px;
  font-weight: 400;
  color: var(--grey-950);
`;

const CustomCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--blue-500);
    border-color: var(--blue-500);
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #ffffff;
  }
`;

interface AuditTypeSelectionProps {
  auditType: TAuditTypesWithActions;
  auditSelections: AuditSelections;
  onActionToggle: (itemType: string, action: string, checked: boolean) => void;
  onGroupToggle: (itemType: string, checked: boolean) => void;
  getGroupStatus: (itemType: string) => { groupChecked: boolean };
}

export default function AuditTypeSelection({
  auditType,
  auditSelections,
  onActionToggle,
  onGroupToggle,
  getGroupStatus,
}: AuditTypeSelectionProps) {
  const { groupChecked } = getGroupStatus(auditType.itemType);
  const group = auditSelections[auditType.itemType] || {};

  return (
    <AuditTypeSection style={{ marginBottom: '16px', borderBottom: '1px solid var(--grey-200)', paddingBottom: '8px' }}>
      <AuditTypeHeader style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
        <CustomCheckbox checked={groupChecked} onChange={(e) => onGroupToggle(auditType.itemType, e.target.checked)} />
        <AuditTypeTitle style={{ marginLeft: '8px', fontWeight: 'bold' }}>{auditType.itemDisplayName}</AuditTypeTitle>
      </AuditTypeHeader>
      {auditType.actions.map((action, index) => (
        <AuditActionItem
          key={action}
          style={{ display: 'flex', alignItems: 'center', marginLeft: '24px', marginBottom: '4px' }}
        >
          <Checkbox
            checked={group[action] || false}
            onChange={(e) => onActionToggle(auditType.itemType, action, e.target.checked)}
          />
          <ActionItem style={{ marginLeft: '8px' }}>{auditType.actionDisplayNames[index]}</ActionItem>
        </AuditActionItem>
      ))}
    </AuditTypeSection>
  );
}

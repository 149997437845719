/* eslint-disable no-var */
import React, { useEffect, useMemo, useState } from 'react';

import { useGetGroups } from '@app/api/groups.api';
import { useActivateUser, useBlockUser, useDeleteUser, useReInviteUser } from '@app/api/user.api';
import StatusBadge from '@app/components/apps/alerts/StatusBadge';
import ActionConfirmationModal from '@app/components/apps/manage-users/ActionConfirmationModal';
import EditUserModal from '@app/components/apps/manage-users/EditUserModal';
import { BasePageWrapper } from '@app/components/common/BasePageWrapper/BasePageWrapper';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { TActionItems } from '@app/components/tables/AntdTableWrapper/hooks/useTableFilters';
import { TDataColumns } from '@app/components/tables/AntdTableWrapper/types';
import { TGroupExtended } from '@app/types/groupExtended';
import { TUsersExtended } from '@app/types/users';
import { useTableFiltersV2 } from '@app/components/tables/data-table/hooks/useTableFilters';
import { useDataTable } from '@app/components/tables/data-table/hooks/useDataTable';
import TableFiltersV2 from '@app/components/tables/data-table/components/TableFilters';
import DataTableV2 from '@app/components/tables/data-table/DataTable';
import { TableActions } from '@app/components/tables/data-table/components/TableActions';
import InviteUsersComponent from '@app/components/apps/manage-users/InviteUsersComponent';
import { getEnumValue } from '@app/services/enum.service';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useTranslation } from 'react-i18next';

const tableCols: TDataColumns<TUsersExtended> = [
  {
    title: 'Email',
    dataIndex: 'email',
    allowFiltering: true,
    type: 'text',
    showSortDirections: true,
    width: '20vw',
  },
  {
    title: 'Role',
    dataIndex: 'role',
    allowFiltering: true,
    type: 'enum',
    enumValuesKey: 'UserRole',
    showSortDirections: true,
    width: '10vw',
  },
  {
    title: 'Group',
    dataIndex: 'groupName',
    allowFiltering: true,
    type: 'text',
    showSortDirections: true,
    width: '10vw',
  },
  {
    title: 'Date Joined',
    dataIndex: 'creationDate',
    allowFiltering: true,
    type: 'date',
    showSortDirections: true,
    width: '8vw',
  },
  {
    title: 'Last Login Date',
    dataIndex: 'lastLoginDate',
    allowFiltering: true,
    type: 'date',
    showSortDirections: true,
    width: '8vw',
  },
];

const ManageUsersPage: React.FC = () => {
  const currentUser = useAppSelector((state) => state.user.user);
  const currentUserRole = currentUser?.role;
  const { mutateAsync: approveUser, isLoading: isApproving } = useActivateUser();
  const { mutateAsync: blockUser, isLoading: isBlocking } = useBlockUser();
  const { mutateAsync: deleteUser, isLoading: isDeleting } = useDeleteUser();
  const { mutateAsync: reinviteUser, isLoading: isReinviteUserLoading } = useReInviteUser();
  const { data: groups } = useGetGroups();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<TUsersExtended | null>(null);
  const [action, setAction] = useState<'activate' | 'block' | 'delete' | 're-invite' | null>(null);
  const [message, setMessage] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const sortedActiveGroups = groups
    ? groups?.items.sort((a: TGroupExtended, b: TGroupExtended) => a.name.localeCompare(b.name))
    : [];

  const status = (status: 'Active' | 'Pending' | 'Blocked') => {
    if (status === 'Active') {
      return 'enabled';
    }

    if (status === 'Pending') {
      return 'pending';
    }

    return 'disabled';
  };

  useEffect(() => {
    if (!!currentUser && currentUserRole === getEnumValue('UserRole', 'Vendor')) {
      tableFilterProps.buildOdataQueryFromParams({
        selectFields: ['email', 'creationDate', 'lastLoginDate', 'status'],
        currentConstantFilter: {
          vendorId: currentUser?.vendor.id,
          status: { ne: getEnumValue('UserStatus', 'Deleted') },
        },
      });
    }
  }, [currentUser, currentUserRole]);

  const actionItems: TActionItems<TUsersExtended>[] = [
    {
      label: 'Edit',
      key: 'edit',
      multiSelect: false,
      onClick: (item) => {
        setIsEditModalOpen(true);
        setSelectedUser(item);
      },
      show: () => currentUserRole !== getEnumValue('UserRole', 'Vendor'),
    },
    {
      label: 'Activate',
      key: 'activate',
      multiSelect: false,
      onClick: (item) => {
        setSelectedUser(item);
        setAction('activate');
        setMessage('Are you sure you want to activate this user?');
        setIsModalOpen(true);
      },
      show: (item) => 'Pending' == (item.status as 'Pending') || 'Blocked' == (item.status as 'Blocked'),
    },
    {
      label: 'Block',
      key: 'block',
      multiSelect: false,
      onClick: (item) => {
        setSelectedUser(item);
        setAction('block');
        setMessage('Are you sure you want to block this user?');
        setIsModalOpen(true);
      },
      show: (item) => (item.status as any) !== 'Deleted' && (item.status as any) !== 'Blocked',
    },
    {
      label: 'Delete',
      key: 'delete',
      multiSelect: false,
      onClick: (item) => {
        setSelectedUser(item);
        setAction('delete');
        setMessage('Are you sure you want to delete this user?');
        setIsModalOpen(true);
      },
      show: (item) => (item.status as any) === 'Blocked',
    },
    {
      label: 'Re-invite user',
      key: 'reInviteUser',
      multiSelect: false,
      onClick: (item) => {
        reinviteUser(item.email.trim());
      },
      show: (item) => (item.status as any) === 'Pending',
    },
  ];

  const manageUsersCols: TDataColumns<TUsersExtended> = [
    ...tableCols,
    {
      title: 'Status',
      dataIndex: 'status',
      allowFiltering: true,
      type: 'enum',
      enumValuesKey: 'UserStatus',
      showSortDirections: true,
      width: '6vw',
      render: (val, record) => <StatusBadge type={status(record.status as any)}>{record.status}</StatusBadge>,
    },
  ];

  const filteredColumns = useMemo(() => {
    return currentUserRole === getEnumValue('UserRole', 'Vendor')
      ? manageUsersCols.filter((col) => col.title !== 'Group' && col.title !== 'Role')
      : manageUsersCols;
  }, [currentUserRole]);

  const tableFilterProps = useTableFiltersV2<TUsersExtended>({
    columns: filteredColumns,
    model: currentUserRole === getEnumValue('UserRole', 'Vendor') ? 'vendorUsersExtended' : 'usersExtended',
    searchableColumns: ['email'],
    orderBy: {
      column: 'email',
      order: 'asc',
    },
    constantFilter:
      currentUserRole === getEnumValue('UserRole', 'Vendor')
        ? { vendorId: currentUser?.vendor.id, status: { ne: getEnumValue('UserStatus', 'Deleted') } }
        : { status: { ne: getEnumValue('UserStatus', 'Deleted') } },
    onApplyFilterCallback: () => {
      dataTableProps.resetPagination();
    },
  });

  var dataTableProps = useDataTable<TUsersExtended>({
    data: tableFilterProps.data?.items || [],
    columns: filteredColumns,
    isLoading: tableFilterProps.isFetching,
    totalRows: tableFilterProps.data?.count,
    paginationEventCallback: ({ top, skip }) => {
      tableFilterProps.buildOdataQueryFromParams({ paginationProps: { top, skip } });
    },
    columnChangesEventCallback: (columns, displayedColumnIndexes) => {
      tableFilterProps.buildOdataQueryFromParams({ selectFields: displayedColumnIndexes });
    },
    onSortCallback: (sortObj) => {
      if (!sortObj || (!!sortObj && !sortObj.column)) return;
      tableFilterProps.buildOdataQueryFromParams({
        orderyByProps: { column: sortObj.column || '', order: sortObj.order || 'asc' },
      });
    },
  });

  const handleModalClose = () => {
    setSelectedUser(null);
    setAction(null);
    setMessage('');
    setIsModalOpen(false);
  };

  const handleConfirm = async () => {
    if (action === 'activate') {
      if (selectedUser) {
        await approveUser(selectedUser?.id);
        tableFilterProps.refetch();
        dataTableProps.clearSelection();
        handleModalClose();
      }
      return;
    }

    if (action === 'block') {
      if (selectedUser) {
        await blockUser(selectedUser?.id);
        dataTableProps.clearSelection();
        tableFilterProps.refetch();
        handleModalClose();
      }
      return;
    }

    if (action === 'delete') {
      if (selectedUser) {
        await deleteUser(selectedUser?.id);
        dataTableProps.clearSelection();
        tableFilterProps.refetch();
        handleModalClose();
      }
      return;
    }

    if (action === 're-invite') {
      return;
    }
  };

  const handleEditUserOk = () => {
    dataTableProps.clearSelection();
    tableFilterProps.refetch();
    setIsEditModalOpen(false);
    setSelectedUser(null);
  };

  const handleEditUserCancel = () => {
    setIsEditModalOpen(false);
  };

  if (!!currentUser && !currentUserRole) return null;

  return (
    <BasePageWrapper
      title="Manage Users"
      description="Add, edit, and manage individual users with tailored roles and permissions for your environment."
    >
      <PageTitle>Manage Users</PageTitle>
      <div style={{ marginBottom: '32px' }}>
        <TableFiltersV2 {...tableFilterProps}>
          <TableFiltersV2.Actions>
            <InviteUsersComponent onInviteSuccess={tableFilterProps.refetch} groups={sortedActiveGroups} />
          </TableFiltersV2.Actions>
        </TableFiltersV2>
      </div>
      <DataTableV2 {...dataTableProps} columns={filteredColumns}>
        <DataTableV2.Actions>
          <TableActions actionItems={actionItems} />
        </DataTableV2.Actions>
      </DataTableV2>
      <ActionConfirmationModal
        open={isModalOpen}
        onOk={handleConfirm}
        onCancel={handleModalClose}
        message={message}
        isLoading={isApproving || isBlocking || isDeleting}
      />
      <EditUserModal
        user={selectedUser}
        open={isEditModalOpen}
        onOk={handleEditUserOk}
        onCancel={handleEditUserCancel}
        groups={sortedActiveGroups}
      />
    </BasePageWrapper>
  );
};

export default ManageUsersPage;

import { Modal, ModalFuncProps, Select, Spin, Tooltip } from 'antd';
import { useForm, SubmitHandler, Controller, useWatch } from 'react-hook-form';
import styled from 'styled-components';

import { HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { TManageAccounts } from '@app/types/manageAccounts';
import { useEffect } from 'react';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { getEnumValue } from '@app/services/enum.service';
import { getSubscriptionTypeOptions, splitCamelCase } from '@app/utils/utils';
import { EMAIL_REGEX } from '@app/styles/themes/constants';
import { GrCircleInformation } from 'react-icons/gr';

const Container = styled.form`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const FormTitle = styled.div`
  font-weight: 800;
  font-size: 20px;
  line-height: 23px;
  color: #272727;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0px;
  gap: 20px;
`;

const FieldLabel = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #272727;
  flex-basis: 25%;
`;

const FieldInput = styled.input<{ hasError?: boolean }>`
  flex-basis: 75%;
  background-color: transparent;
  border: ${(props) => (props.hasError ? '1px solid red' : '1px solid #acacac')};
  border-radius: 10px;
  padding: 12px;
  outline: none;
`;

const InputContainer = styled.div`
  flex-basis: 75%;
  display: flex;
  flex-direction: column;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 1rem;
  font-weight: bold;
`;

const CancelButton = styled.button`
  border: 1px solid #272727;
  border-radius: 10px;
  background-color: transparent;
  padding: 12px 70px;
  color: #272727;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const CreateButton = styled.button`
  border: 1px solid #3a50d1;
  border-radius: 10px;
  background-color: #3a50d1;
  padding: 12px 70px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

export type TManageAccountsForm = {
  name: string;
  microsoftTenantId: string;
  microsoftTenantName: string;
  microsoftApplicationId: string;
  microsoftApplicationSecret: string;
  ownerEmail: string;
  status: number;
  subscriptionTypeID: number | null;
};

interface IManageAccountsProps extends ModalFuncProps {
  isEdit?: boolean;
  isLoading?: boolean;
  account?: TManageAccounts;
}

export default function ManageAccountsForm({ isEdit, isLoading, account, ...modalProps }: IManageAccountsProps) {
  const subscriptionTypes = useAppSelector((state) => state.app.subscriptionTypes);
  const subscriptionTypesOptions = getSubscriptionTypeOptions();
  const trialSubscription = subscriptionTypes?.find((st) => st.name.toLowerCase() === 'trial');
  const userRole = useAppSelector((state) => state.user.user?.role);

  const accountStatusOptions = useAppSelector((state) =>
    (state.app.appValues as any)?.AccountStatus.map((item: { value: number; label: string }) => ({
      ...item,
      label: splitCamelCase(item.label),
    })),
  ) as {
    value: number;
    label: string;
  }[];

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm<TManageAccountsForm>({
    mode: 'onBlur',
    defaultValues: {
      subscriptionTypeID: trialSubscription?.id,
    },
  });

  const watchedSubscriptionTypeID = useWatch({
    control,
    name: 'subscriptionTypeID',
  });

  // reset subscription type to trial subscription if it is not valid anymore (subscription type has been disabled)
  useEffect(() => {
    const isValid = subscriptionTypes?.some((st) => st.id === watchedSubscriptionTypeID && st.status === 1);
    if (!isValid && watchedSubscriptionTypeID !== null) {
      setValue('subscriptionTypeID', trialSubscription?.id ?? null);
    }
  }, [subscriptionTypes, watchedSubscriptionTypeID, setValue, trialSubscription?.id]);

  useEffect(() => {
    if (modalProps.open && !!account) {
      reset({
        name: account?.name,
        microsoftTenantId: account?.microsoftTenantId,
        microsoftTenantName: account?.microsoftTenantName,
        microsoftApplicationId: account?.microsoftApplicationId,
        microsoftApplicationSecret: account?.microsoftApplicationSecret,
        ownerEmail: account?.ownerEmail,
        status: getEnumValue('AccountStatus', account.status as any),
        subscriptionTypeID: account?.subscriptionTypeId ?? null,
      });
    } else if (modalProps.open && !account) {
      reset({
        name: '',
        microsoftTenantId: '',
        microsoftTenantName: '',
        microsoftApplicationId: '',
        microsoftApplicationSecret: '',
        ownerEmail: '',
        status: getEnumValue('AccountStatus', 'Enabled'),
        subscriptionTypeID: trialSubscription?.id ?? null,
      });
    }
  }, [modalProps.open, account]);

  const onSubmit: SubmitHandler<TManageAccountsForm> = (data) => {
    modalProps.onOk?.(data);
  };

  const handleCancel = () => {
    reset();
    modalProps.onCancel?.();
  };

  // to put 'None' option at the top (for subscription type)
  const customFilterSort = (optionA: any, optionB: any) => {
    if (optionA.label === 'None') return -1;
    if (optionB.label === 'None') return 1;
    return (optionA.label ?? '').toLowerCase().localeCompare((optionB.label ?? '').toLowerCase());
  };

  return (
    <Modal
      style={{
        minWidth: '600px',
      }}
      {...modalProps}
      destroyOnClose={true}
      closable={false}
      footer={null}
    >
      <Container onSubmit={handleSubmit(onSubmit)}>
        <FormTitle>{`${isEdit ? 'Edit' : 'Create'} Account`}</FormTitle>
        <FormContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Name:</FieldLabel>
            <InputContainer>
              <FieldInput hasError={!!errors.name} {...register('name', { required: true })} />
              {!!errors.name && <ErrorMessage>This field is required</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Subscription Type:</FieldLabel>
            <InputContainer>
              <Controller
                control={control}
                name="subscriptionTypeID"
                render={({ field }) => (
                  <Select
                    {...field}
                    value={field.value}
                    showSearch
                    style={{ flexBasis: '75%' }}
                    options={subscriptionTypesOptions}
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                    filterSort={customFilterSort}
                    onChange={(value) => {
                      field.onChange(value === null ? null : value);
                    }}
                    allowClear
                  />
                )}
              />
              {!!errors.status && <ErrorMessage>{errors.status.message}</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Microsoft Tenant ID:</FieldLabel>
            <InputContainer>
              <FieldInput
                hasError={!!errors.microsoftTenantId}
                {...register('microsoftTenantId', { required: false })}
              />
              {!!errors.microsoftTenantId && <ErrorMessage>This field is required</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Microsoft Onmicrosoft Domain:</FieldLabel>
            <InputContainer>
              <FieldInput
                hasError={!!errors.microsoftTenantName}
                {...register('microsoftTenantName', { required: false })}
              />
              {!!errors.microsoftTenantName && <ErrorMessage>This field is required</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
          {/* 
          <HorizontalAlignedContainer>
            <FieldLabel>Microsoft Application ID:</FieldLabel>
            <InputContainer>
              <FieldInput
                hasError={!!errors.microsoftApplicationId}
                {...register('microsoftApplicationId', { required: false })}
              />
              {!!errors.microsoftApplicationId && <ErrorMessage>This field is required</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Microsoft Application Secret:</FieldLabel>
            <InputContainer>
              <FieldInput
                placeholder={account?.hasAppSecret ? '***************' : ''}
                type="password"
                hasError={!!errors.microsoftApplicationSecret}
                {...register('microsoftApplicationSecret', { required: false })}
              />
              {!!errors.microsoftApplicationSecret && <ErrorMessage>This field is required</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer> */}

          <HorizontalAlignedContainer style={{ opacity: 0.5 }}>
            <FieldLabel>Owner Email:</FieldLabel>
            <InputContainer style={{ flexBasis: '70%' }}>
              <FieldInput type="email" disabled {...register('ownerEmail')} />
            </InputContainer>
            <Tooltip
              title="Account ownership is managed by the system and cannot be changed."
              placement="top"
              color="black"
              overlayStyle={{ maxWidth: '100%' }}
              overlayInnerStyle={{ fontSize: '13px' }}
            >
              <GrCircleInformation style={{ fontSize: '1.25rem', color: 'black', marginLeft: '0.5rem' }} />
            </Tooltip>
          </HorizontalAlignedContainer>

          <HorizontalAlignedContainer style={{ pointerEvents: 'none', opacity: 0.5 }}>
            <FieldLabel>Status:</FieldLabel>
            <InputContainer>
              <Controller
                control={control}
                name="status"
                render={({ field }) => (
                  <Select
                    {...field}
                    value={field.value}
                    showSearch
                    style={{ flexBasis: '75%' }}
                    options={accountStatusOptions}
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                  >
                    {accountStatusOptions.map((opt) => (
                      <Select.Option key={opt.value} value={opt.value}>
                        {opt.label}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              />
              {!!errors.status && <ErrorMessage>{errors.status.message}</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
          {/* <ErrorMessage style={{ fontSize: '0.8rem', textAlign: 'center' }}>
            Status updates occur only via automated server changes or row-level actions.
          </ErrorMessage> */}
        </FormContainer>
        <HorizontalAlignedContainer
          style={{
            gap: '10px',
            justifyContent: 'center',
          }}
        >
          {isLoading ? (
            <Spin />
          ) : (
            <>
              <CancelButton type="button" onClick={handleCancel}>
                Cancel
              </CancelButton>
              <CreateButton>{isEdit ? 'Update' : 'Create'}</CreateButton>
            </>
          )}
        </HorizontalAlignedContainer>
      </Container>
    </Modal>
  );
}

import { notificationController } from '@app/controllers/notificationController';
import { useQueryClient, useMutation } from 'react-query';
import { httpApi } from './http.api';

import { TDataManagementForm } from '@app/components/apps/data-management/DataManagementForms';
import { TLicensePlanLicensesForm } from '@app/components/apps/data-management/LicenseRelationshipForm';
import { TLicensePlanLicenses } from '@app/types/licensePlanLicenses';
import { TList } from '@app/types/generalTypes';
import { TLicense } from '@app/types/license';
import { TLicensePlan } from '@app/types/licensePlan';
import { TDataManagementEditableDependencies } from '@app/types/dataManagementEditableDependencies';
import { loadAppValues } from '@app/store/slices/appSlice';
import { useDispatch } from 'react-redux';

export const fetchRelationships = async ({ id, type }: { id: number; type: string }) => {
  const response = await httpApi.get<any>(`/data-management/`, { params: { id, type } });

  return response?.data;
};

export const useFetchRelationships = () => {
  const queryClient = useQueryClient();

  return useMutation(fetchRelationships, {
    onSuccess: () => {
      queryClient.invalidateQueries('termsandconditions-odata');
    },
  });
};

export const getEditableDependencies = async (type: string) => {
  const response = await httpApi.get<TDataManagementEditableDependencies[]>(`/data-management/${type}`);

  return response?.data;
};

export const useGetEditableDependencies = (type: string) => {
  const queryClient = useQueryClient();

  return useMutation(getEditableDependencies, {
    onSuccess: () => {
      queryClient.invalidateQueries(type);
    },
  });
};

export const createDataManagementEntity = async (data: TDataManagementForm) => {
  const response = await httpApi.post<any>(`/data-management/create`, data);
  return response?.data;
};

export const useCreateDataManagementEntity = (type: string) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(createDataManagementEntity, {
    onSuccess: (data) => {
      dispatch(loadAppValues());
      notificationController.success({
        message: 'Data Entity Successfully Created',
      });
      queryClient.invalidateQueries(type);
    },
  });
};

export const updateDataManagementEntity = async ({ id, data }: { id: number; data: TDataManagementForm }) => {
  const response = await httpApi.patch<any>(`/data-management/update/${id}`, data);

  if (response?.status === 200) {
    loadAppValues();
    notificationController.success({
      message: 'Data Entity Successfully Updated',
    });
  }

  return response?.data;
};

export const useUpdateDataManagementEntity = (type: string) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(updateDataManagementEntity, {
    onSuccess: () => {
      dispatch(loadAppValues());
      notificationController.success({
        message: 'Data Entity Successfully Created',
      });
      queryClient.invalidateQueries(type);
    },
  });
};

export const fetchLicenseRelationships = async ({ id, type, query }: { id: number; type: string; query: string }) => {
  const encodedQuery = encodeURI(query);
  const response = await httpApi.get<TList<TLicense | TLicensePlan>>(`/data-management/${id}/${type}${encodedQuery}`);
  return response?.data;
};

export const establishLicenseRelationship = async (data: TLicensePlanLicenses[]) => {
  const payload = { relationships: data };
  const response = await httpApi.post<any>(`/data-management/relationship`, payload);

  if (response?.status === 201) {
    notificationController.success({
      message: 'Relationship Established',
    });
  }

  return response?.data;
};

export const useEstablishLicenseRelationship = () => {
  const queryClient = useQueryClient();

  return useMutation(establishLicenseRelationship, {
    onSuccess: () => {
      queryClient.invalidateQueries('licensePlan');
    },
  });
};

export const deleteLicenseRelationship = async (data: TLicensePlanLicenses[]) => {
  const payload = { relationships: data };
  const response = await httpApi.delete<any>(`/data-management/delete/relationship`, { data: payload });

  if (response?.status === 200) {
    notificationController.success({
      message: 'Relationship Deleted',
    });
  }

  return response?.data;
};

export const useDeleteEntity = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(deleteEntity, {
    onSuccess: () => {
      dispatch(loadAppValues());
      queryClient.invalidateQueries('licensePlan');
    },
  });
};

export const deleteEntity = async ({ id, type, forceDelete }: { id: number; type: string; forceDelete: boolean }) => {
  const response = await httpApi.delete<any>(`/data-management/delete/entity/${id}/${type}/${forceDelete}`);

  if (response?.status === 200) {
    response.data?.success
      ? notificationController.success({ message: 'Entity Deleted' })
      : notificationController.error({ message: 'Deletion failed. Please remove related records first.' });
  }

  return response?.data;
};

export const useDeleteLicenseRelationship = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteLicenseRelationship, {
    onSuccess: () => {
      queryClient.invalidateQueries('licensePlan');
    },
  });
};

import { useResponsive } from '@app/hooks/useResponsive';
import Empty from 'antd/lib/empty';
import Table from 'antd/lib/table';
import { RowSelectionType, SorterResult } from 'antd/lib/table/interface';
import React, { ReactElement, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { TDataColumns } from '../AntdTableWrapper/types';
import { DataTableContext } from './store/context';
import { HorizontalAlignedContainer, SpaceGrower } from '@app/components/common/BaseLayout/BaseLayout.styled';
import Pagination, { IPaginationProps } from './components/Pagination';
import { DEFAULT_TOTAL_ROWS } from './constants';
import ManageTableColumnsButton from './components/manage-table-columns/ManageTableColumnsButton';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableContainer = styled.div`
  border: 1px solid var(--grey-100);
  border-radius: 4px;
`;

const ActionsContainer = styled(HorizontalAlignedContainer)`
  background-color: var(--white);
  padding: 16px 12px;
  border-bottom: 1px solid var(--ashgrey);
  border-radius: 4px;
`;

const ActionsText = styled.span`
  color: var(--grey-400);
  font-size: 0.875rem;
  font-weight: 400;
`;

const FooterContainer = styled.div`
  background-color: var(--white);
  padding: 12px;
  border-radius: 4px;
`;

export interface IDataTableProps<T extends Record<string, unknown>> {
  data: T[];
  columns: TDataColumns<T>;
  displayedColumns?: TDataColumns<T>;
  isLoading?: boolean;
  canSelectRows?: boolean;
  showHeader?: boolean;
  stickyHeader?: boolean;
  emptyRowText?: string;
  selectedRows?: T[];
  totalRows?: number;
  canModifyTable?: boolean;
  paginationProps?: IPaginationProps;
  rowSelectionType?: string;
  children?: ReactElement<ITableComponentProps> | ReactElement<ITableComponentProps>[];
  setDatTableProps?: (newProps: any) => void;
  onSort?: (sortProps: SorterResult<T>) => void;
  onRowClick?: (data: T, index: number | undefined) => void;
  onRowMouseEnter?: (data: T, index: number | undefined) => void;
  onRowMouseLeave?: (data: T, index: number | undefined) => void;
  onSelectRows?: (rows: T[]) => void;
}

function DataTableV2<T extends Record<string, unknown>>(props: IDataTableProps<T>) {
  const {
    data,
    displayedColumns,
    isLoading,
    emptyRowText,
    stickyHeader = true,
    selectedRows,
    paginationProps,
    totalRows = DEFAULT_TOTAL_ROWS,
    canSelectRows = true,
    showHeader = true,
    children,
    canModifyTable = true,
    rowSelectionType = 'checkbox',
    setDatTableProps,
    onSort,
    onRowClick,
    onRowMouseEnter,
    onRowMouseLeave,
    onSelectRows,
  } = props;

  const { isMobile, isTablet, isDesktop, isBigScreen } = useResponsive();

  const adjustedHeight = () => {
    if (isBigScreen) return '50vh';
    if (isDesktop) return '45vh';
    if (isTablet) return '40vh';
    if (isMobile) return '35vh';
    return '50vh'; //* Fallback table height TBD
  };

  const ActionComponents = useMemo(() => {
    if (Array.isArray(children)) {
      const TableActionsComponent = children?.find((child) => child.type === TableActions);
      return TableActionsComponent || undefined;
    }

    return children?.type === TableActions ? children : undefined;
  }, [children]);

  const ChartComponents = useMemo(() => {
    if (Array.isArray(children)) {
      const TableChartsComponent = children?.find((child) => child.type === TableCharts);
      return TableChartsComponent || undefined;
    }

    return children?.type === TableCharts ? children : undefined;
  }, [children]);

  return (
    <DataTableContext.Provider
      value={{
        tableProps: props,
        setTableProps: (newProps) => setDatTableProps?.(newProps),
      }}
    >
      <Container>
        {!!ChartComponents && ChartComponents}
        <TableContainer>
          <ActionsContainer>
            <ActionsText>{`Total: ${totalRows} entries`}</ActionsText>
            <SpaceGrower />
            <HorizontalAlignedContainer style={{ flexDirection: 'row-reverse', gap: '8px' }}>
              {canModifyTable && <ManageTableColumnsButton />}

              {!!ActionComponents && ActionComponents}
            </HorizontalAlignedContainer>
          </ActionsContainer>
          <Table
            showHeader={showHeader}
            loading={isLoading}
            scroll={{
              ...(!!stickyHeader && { y: adjustedHeight() }),
              x: 'max-content',
              scrollToFirstRowOnChange: true,
            }}
            dataSource={data}
            columns={displayedColumns}
            sortDirections={['ascend', 'descend', 'ascend']}
            locale={{ emptyText: <Empty description={emptyRowText} image={Empty.PRESENTED_IMAGE_SIMPLE} /> }}
            onRow={(row, index) => {
              return {
                onClick: () => onRowClick?.(row, index),
                onMouseEnter: () => onRowMouseEnter?.(row, index),
                onMouseLeave: () => onRowMouseLeave?.(row, index),
                className: onRowClick ? 'cursor-pointer' : '',
              };
            }}
            {...(canSelectRows && {
              rowSelection: {
                type: rowSelectionType as RowSelectionType,
                ...(selectedRows && { selectedRowKeys: selectedRows.map((row) => row.key as string) }),
                onChange: (selectedRowKeys: React.Key[], selectedRows: T[]) => {
                  onSelectRows?.(selectedRows);
                },
              },
            })}
            pagination={false}
            onChange={(val, filter, sorter, extra) => {
              onSort?.(sorter as SorterResult<T>);
            }}
          />
          {!!paginationProps && (
            <FooterContainer>
              <Pagination {...{ ...paginationProps, canSelectRows }} />
            </FooterContainer>
          )}
        </TableContainer>
      </Container>
    </DataTableContext.Provider>
  );
}

interface ITableComponentProps {
  children: ReactElement | ReactElement[];
}

/**
 * Child Components under these 2 Parent components can use the hook below to get the
 * values of the table:
 * const { tableProps, setTableProps } = useDataTableContext<IDataTableProps<T>>();
 *
 * Note: the typescript generic T type should be the same as the type of the data supplied
 * on the DataTable component
 */
function TableActions({ children }: ITableComponentProps) {
  return <>{children}</>;
}

function TableCharts({ children }: ITableComponentProps) {
  return <>{children}</>;
}

DataTableV2.Actions = TableActions;
DataTableV2.Charts = TableCharts;

export default DataTableV2;

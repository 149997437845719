import { useMutation, useQuery, useQueryClient } from 'react-query';
import { httpApi } from '@app/api/http.api';
import { TAccountStatusInfo } from '@app/types/accountStatusInfo';
import { TAccountSetupData } from '@app/types/accountSetupData';
import { TManageAccountsForm } from '@app/components/apps/manage-accounts/ManageAccountsForm';
import { notificationController } from '@app/controllers/notificationController';
import { getEnumValue } from '@app/services/enum.service';
import { TEnumEntity } from '@app/types/enumEntity';
import { TManageAccounts } from '@app/types/manageAccounts';
import pluralize from 'pluralize';
import { TAccountAuditTypes } from './azure-events-monitoring.api';

const finalizeAccountSignUp = async (setupData: TAccountSetupData): Promise<string> => {
  const response = await httpApi.post('/account/finishSetup', setupData);
  return response.data;
};

export const useFinalizeAccountSignUp = () =>
  useMutation({
    mutationFn: finalizeAccountSignUp,
  });

const getAccountStatusInfo = async (): Promise<TAccountStatusInfo> => {
  const response = await httpApi.get<TAccountStatusInfo>('/account/status');
  return response.data;
};

export const useGetAccountStatusInfo = (fetchStats?: boolean) =>
  useQuery({
    queryKey: 'account-status-info',
    enabled: fetchStats,
    queryFn: () => getAccountStatusInfo(),
    refetchInterval: (data, query) => {
      if (data?.status === getEnumValue('AccountStatus', 'PendingInitialScan')) {
        return 30000;
      }
      return false;
    },
  });

export const checkAppAccess = async (): Promise<string> => {
  const { data } = await httpApi.post<{ connectionStatus: string }>('/account/checkAppAccess');
  return data.connectionStatus;
};

const getAccount = async (): Promise<TManageAccounts> => {
  const response = await httpApi.get<TManageAccounts>('/account');
  return response.data;
};

export const useGetAccount = () =>
  useQuery({
    queryKey: 'get-account',
    queryFn: () => getAccount(),
  });

const createAccount = async (data: TManageAccountsForm) => {
  const response = await httpApi.post<any>('/account', data);

  if (response?.status === 201) {
    notificationController.success({
      message: `Account Created`,
    });
  }

  return response?.data;
};

export const useCreateAccount = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createAccount,
    onSuccess() {
      queryClient.invalidateQueries('accountExtended-odata');
    },
  });
};

const updateAccount = async (payload: { ids: string[]; data: Partial<TManageAccountsForm> }) => {
  const { ids } = payload;
  const response = await httpApi.patch<any>(`/account/update`, payload);

  if (response?.status === 200) {
    notificationController.success({
      message: `${ids.length > 1 ? ids.length : ''} ${pluralize('Account', ids.length)} Updated`,
    });
  }
  return response?.data;
};

export const useUpdateAccount = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateAccount,
    onSuccess() {
      queryClient.invalidateQueries('accountExtended-odata');
    },
  });
};

const deleteAccount = async (accountIds: string[]) => {
  const response = await httpApi.delete(`/account/delete`, { data: { accountIds } });

  if (response?.status === 200) {
    notificationController.success({
      message: `${accountIds.length > 1 ? accountIds.length : ''} ${pluralize('Account', accountIds.length)} Deleted`,
    });
  }
};

export const useDeleteAccount = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteAccount,
    onSuccess() {
      queryClient.invalidateQueries('accountExtended-odata');
    },
  });
};

const completeAccountSetup = async (data: { appId: string; appSecret: string }) => {
  const response = await httpApi.post<any>(`/account/completeSetup`, data);

  if (response?.status === 200) {
    notificationController.success({
      message: `Completed Account Setup`,
    });
  }

  return response?.data;
};

export const useCompleteAccountSetup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: completeAccountSetup,
  });
};

const scanAccount = async (accountIds: string[]) => {
  httpApi.post<any>(`/account/scan`, { accountIds });
  notificationController.success({
    message: `Account Scan Initialized`,
  });
};

export const useScanAccount = () => {
  return useMutation({
    mutationFn: scanAccount,
    onSuccess(_data, variables) {
      notificationController.success({
        message: `${pluralize('Account', variables.length)} scanned initiated`,
      });
    },
  });
};

export const getAccountProducts = async (): Promise<TEnumEntity[]> => {
  try {
    const response = await httpApi.get<TEnumEntity[]>('/account/products');
    if (response.status === 403) return [];
    return response.data;
  } catch (error) {
    return [];
  }
};

export const useGetAccountProducts = () =>
  useQuery({
    queryKey: 'account-products',
    queryFn: getAccountProducts,
  });

const updateSettings = async ({ productIds }: { productIds: number[] }) => {
  const response = await httpApi.patch<any>('/account/update-settings', { productIds });
  if (response.status !== 200) throw undefined;
  return response.data;
};

export const useUpdateSettings = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateSettings,
    onSuccess() {
      queryClient.invalidateQueries('account-products');
      notificationController.success({ message: `Successfully updated the connected products.` });
    },
  });
};

const getAccountAuditTypes = async (): Promise<TAccountAuditTypes> => {
  const response = await httpApi.get<TAccountAuditTypes>('/account/audit-types');
  return response.data;
};

export const useGetAccountAuditTypes = () =>
  useQuery({
    queryKey: 'account-audit-types',
    queryFn: getAccountAuditTypes,
  });

const getAccountAuditNotificationRecipients = async (): Promise<string[]> => {
  const response = await httpApi.get<string[]>('/account/audit-notification-recipients');
  return response.data;
};

export const useGetAccountAuditNotificationRecipients = () =>
  useQuery({
    queryKey: 'account-audit-notification-recipients',
    queryFn: getAccountAuditNotificationRecipients,
  });

const updateAccountAuditNotificationRecipients = async (emails: string[]) => {
  const response = await httpApi.patch<void>('/account/audit-notification-recipients', { emails });
  if (response?.status === 200) {
    notificationController.success({ message: `Successfully updated the email notification recipients.` });
  }

  return response.data;
};

export const useUpdateAccountAuditNotificationRecipients = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateAccountAuditNotificationRecipients,
    onSuccess() {
      queryClient.invalidateQueries('account-audit-notification-recipients');
    },
  });
};

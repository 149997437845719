import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal, { ModalFuncProps } from 'antd/lib/modal/Modal';
import { Input, Spin } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 1.5rem;
`;

const ConfirmationMessage = styled.span`
  font-size: 1.75rem;
  font-weight: 800;
  line-height: 1.3;
  color: var(--grey-950);
  text-align: center;
  margin: 1.5rem 0 0.5rem;
`;

const SubMessage = styled.span`
  font-size: 1rem;
  font-weight: 400;
  margin: 0.5rem 0 1.5rem;
  text-align: center;
  max-width: 90%;
  line-height: 1.6;
`;

const InputField = styled(Input)`
  margin: 0.75rem 0 1.5rem;
  width: 90%;
  max-width: 450px;
  height: 2.75rem;
  border-radius: 8px;
`;

const StyledHorizontalContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
`;

const Button = styled.button`
  border-radius: 8px;
  padding: 0.75rem 0;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 8rem;
  flex: 1;
  max-width: 12rem;

  @media (max-width: 480px) {
    flex: 0 0 100%;
    max-width: 90%;
  }
`;

const CancelButton = styled(Button)`
  border: 1px solid #272727;
  background-color: transparent;
  color: #272727;

  &:hover {
    background-color: var(--grey-50);
  }
`;

const ConfirmButton = styled(Button)<{ disabled?: boolean }>`
  border: 1px solid var(--red-600);
  background-color: var(--red-600);
  color: white;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &:hover {
    background-color: var(--red-700);
    border-color: var(--red-700);
  }
`;

const WarningContainer = styled.div`
  display: flex;
  border: 0px;
  border-radius: 50%;
  background-color: var(--red-100);
  width: 5rem;
  height: 5rem;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 12px rgba(216, 28, 46, 0.1);
`;

interface DeleteVerificationModalProps extends ModalFuncProps {
  entityName: string;
  isLoading?: boolean;
  warningMessage?: string;
  onForceOk?: () => void;
  disableForceDeletion: boolean;
}

export default function DeleteVerificationModal({
  open,
  entityName,
  isLoading,
  warningMessage,
  disableForceDeletion,
  ...modalProps
}: DeleteVerificationModalProps): JSX.Element {
  const [confirmationInput, setConfirmationInput] = useState('');
  const isConfirmed = confirmationInput === entityName;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmationInput(e.target.value);
  };

  const handleConfirmClick = () => {
    if (isConfirmed && modalProps.onOk) {
      modalProps.onOk(); // Normal deletion confirmation.
    }
  };

  const handleContinueClick = () => {
    // Call the force deletion callback if provided
    if (modalProps.onForceOk) {
      modalProps.onForceOk();
    } else if (modalProps.onOk) {
      modalProps.onOk();
    }
  };

  useEffect(() => {
    if (!open) {
      setConfirmationInput('');
    }
  }, [open]);

  return (
    <Modal
      open={open}
      destroyOnClose
      closable={false}
      footer={null}
      centered
      width="auto"
      style={{ maxWidth: '500px', margin: '0 16px' }}
      bodyStyle={{ padding: 0 }}
    >
      <Container>
        <WarningContainer>
          <WarningOutlined style={{ color: 'var(--red-600)', fontSize: '3rem' }} />
        </WarningContainer>
        <ConfirmationMessage>Are you sure?</ConfirmationMessage>
        {warningMessage ? (
          // When a warning message is provided, display it and show the force deletion option.
          <>
            <SubMessage>{warningMessage}</SubMessage>
            <StyledHorizontalContainer>
              <CancelButton onClick={modalProps.onCancel}>Cancel</CancelButton>
              <ConfirmButton onClick={handleContinueClick} disabled={disableForceDeletion}>
                Continue with deletion
              </ConfirmButton>
            </StyledHorizontalContainer>
          </>
        ) : (
          // Normal verification UI:
          <>
            <SubMessage>
              Are you sure you want to delete &quot;<strong>{entityName}</strong>&quot;? This action may have unintended
              consequences.
            </SubMessage>
            <InputField
              placeholder="Enter entity name to confirm"
              value={confirmationInput}
              onChange={handleInputChange}
              autoFocus
            />
            <StyledHorizontalContainer>
              {isLoading ? (
                <Spin size="large" />
              ) : (
                <>
                  <CancelButton onClick={modalProps.onCancel}>Cancel</CancelButton>
                  <ConfirmButton onClick={handleConfirmClick} disabled={!isConfirmed}>
                    Delete
                  </ConfirmButton>
                </>
              )}
            </StyledHorizontalContainer>
          </>
        )}
      </Container>
    </Modal>
  );
}

import { FONT_WEIGHT } from '@app/styles/themes/constants';
import { TAuditLog, TGroupedDataItem } from '@app/types/azureAuditLogs';
import { useMemo } from 'react';
import styled from 'styled-components';

const TooltipContainer = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 12vw;
  font-size: 12px;

  pointer-events: auto;
`;

const Indicator = styled.div<{ color: string }>`
  width: 3px;
  align-self: stretch;
  border-radius: 20%;
  background-color: ${({ color }) => color};
  margin-right: 2px;
  flex-shrink: 0;
`;

const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 6px;
`;

const RowBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Labels = styled.span`
  font-family: 'Inter', sans-serif;
  font-weight: ${FONT_WEIGHT.medium};
  color: var(--grey-950);
`;

const TextLabels = styled.span`
  font-family: 'Inter', sans-serif;
  color: var(--grey-500);
  font-weight: ${FONT_WEIGHT.regular};
`;

interface IAzureBarChartTooltipProps {
  barPayload: TGroupedDataItem;
  barDetails: TAuditLog[];
  barColor: string;
}

export default function AzureBarChartTooltip({ barPayload, barDetails, barColor }: IAzureBarChartTooltipProps) {
  const groupedItems = useMemo(() => {
    const groups: {
      [displayName: string]: { total: number; actions: { [actionType: string]: number } };
    } = {};
    if (barDetails && Array.isArray(barDetails)) {
      barDetails.forEach((item: TAuditLog) => {
        const name = item.item_display_name;
        if (!groups[name]) {
          groups[name] = { total: 0, actions: {} };
        }
        groups[name].total += parseInt(item.total_events, 10);
        if (item.actions) {
          item.actions.forEach((action) => {
            if (!groups[name].actions[action.action_type]) {
              groups[name].actions[action.action_type] = 0;
            }
            groups[name].actions[action.action_type] += action.action_count;
          });
        }
      });
    }
    return groups;
  }, [barDetails]);

  return (
    <TooltipContainer>
      <Column>
        <Labels>Date</Labels>
        <TextLabels>{barPayload.date}</TextLabels>
      </Column>
      <Column>
        {Object.entries(groupedItems).map(([itemDisplayName, { total, actions }]) => (
          <>
            <Row key={itemDisplayName}>
              <Column>
                <Column style={{ marginBottom: '8px' }}>
                  <RowBetween>
                    <Labels>{itemDisplayName}</Labels>
                    <Labels>{total}</Labels>
                  </RowBetween>
                </Column>

                <Row>
                  <Indicator color={barColor} />
                  {/* List each action with its count */}
                  <Column>
                    {Object.entries(actions).map(([actionType, count]) => (
                      <RowBetween key={actionType}>
                        <TextLabels>{actionType}</TextLabels>
                        <TextLabels>{count}</TextLabels>
                      </RowBetween>
                    ))}
                  </Column>
                </Row>
              </Column>
            </Row>
          </>
        ))}
      </Column>
    </TooltipContainer>
  );
}

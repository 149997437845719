import React, { useMemo, useState } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { useGetQuickWins } from '@app/api/statistics.api';
import styled from 'styled-components';
import { capitalize } from '@app/utils/utils';
import { TEnumEntity } from '@app/types/enumEntity';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store/store';
import { usePagination } from '../tables/AntdTableWrapper/hooks/usePagination';
import DashboardPagination from './DashboardPagination';
import { Spin } from 'antd';
import { DashboardCenterContainer } from '../common/BaseLayout/BaseLayout.styled';
import { Link } from 'react-router-dom';
import buildQuery from 'odata-query';
import { getEnumValue } from '@app/services/enum.service';

const MainContainer = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
`;

const QuickWinsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ProductContainer = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ProductItem = styled.div<{ isHovered: boolean }>`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  p {
    text-decoration: ${({ isHovered }) => (isHovered ? 'underline' : 'none')};
    color: ${({ isHovered }) => (isHovered ? '#000' : '#555')};
    margin: 0;
    padding: 0;
    transition: color 0.3s ease-in-out, text-decoration 0.3s ease-in-out;
  }
`;

const PieChartData = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  height: 250px;
  width: 250px;
`;

const RiskContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
`;

const RiskItem = styled.div<{ isHovered: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  span {
    text-decoration: ${({ isHovered }) => (isHovered ? 'underline' : 'none')};
    color: ${({ isHovered }) => (isHovered ? '#000' : '#555')};
    transition: color 0.3s ease-in-out, text-decoration 0.3s ease-in-out;
  }
`;

const ColorIndicator = styled.div<{ backgroundColor: string }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  transition: transform 0.3s ease-in-out;

  ${({ backgroundColor }) =>
    backgroundColor &&
    `
      &:hover {
        transform: scale(1.2);
      }
  `}
`;

const LabelContainer = styled.div`
  width: 30px;
  height: 30px;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
`;

export function QuickWins() {
  const [currentPage, setCurrentPage] = useState(1);
  const [hoveredOuterIndex, setHoveredOuterIndex] = useState<number | null>(null);
  const [hoveredInnerIndex, setHoveredInnerIndex] = useState<number | null>(null);
  const selectedProducts = useSelector<RootState>((state) => state.app.accountProducts) as TEnumEntity[] | null;

  const limit = 5;

  const BY_PRODUCT_COLORS = [
    '#290014', // maroon-ish brown
    '#9b79b9', // light purple
    '#4e2a5b', // dark purple
    '#555bad', // light blue
    '#fff3e0', // cream
  ];

  const productIds = useMemo(() => {
    if (selectedProducts) {
      return selectedProducts.map((product) => product.id);
    }
    return [];
  }, [selectedProducts]);

  const { data: quickWinsData, isLoading } = useGetQuickWins(productIds.join(','));

  const quickWins = useMemo(() => {
    return quickWinsData || { byRisk: {}, byProduct: {} };
  }, [quickWinsData]);

  const byProductEntries = Object.entries(quickWins.byProduct);
  const sortedByProductEntries = [...byProductEntries].sort(([, aValue], [, bValue]) => bValue - aValue);
  const totalRows = sortedByProductEntries.length;

  const paginationProps = usePagination({
    limit,
    skip: (currentPage - 1) * limit,
    totalRows,
    limitIntervals: [5],
    onNext() {
      if (currentPage * limit < totalRows) {
        setCurrentPage(currentPage + 1);
      }
    },
    onPrev() {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    },
    onPageClicked(callbackProps) {
      setCurrentPage(callbackProps.currentPage);
    },
  });

  const paginatedProducts = sortedByProductEntries.slice(
    paginationProps.skip,
    paginationProps.skip + paginationProps.limit,
  );

  const productColors = useMemo(() => {
    return sortedByProductEntries.reduce((acc, [product], idx) => {
      acc[product] = BY_PRODUCT_COLORS[idx % BY_PRODUCT_COLORS.length];
      return acc;
    }, {} as Record<string, string>);
  }, [sortedByProductEntries]);

  const riskLevels = [
    { key: '1', label: 'Low' },
    { key: '2', label: 'Moderate' },
    { key: '3', label: 'High' },
  ];

  // transform data for the donut charts
  const chartData = useMemo(() => {
    let innerData, outerData;

    const isDataEmpty = Object.keys(quickWins.byProduct).length === 0 && Object.keys(quickWins.byRisk).length === 0;

    if (isDataEmpty) {
      innerData = riskLevels.map((risk) => ({
        key: risk.key,
        name: risk.label,
        value: 1,
      }));
      outerData = [{ name: 'No Data', value: 1 }];
    } else {
      innerData = riskLevels.map((risk) => ({
        key: risk.key,
        name: risk.label,
        value: quickWins.byRisk[risk.key] || 0,
      }));

      outerData = paginatedProducts.map(([product, value]) => ({
        name: product,
        value,
      }));
    }

    return { innerData, outerData };
  }, [quickWins.byProduct, quickWins.byRisk, paginatedProducts]);

  const totalQuickWins = useMemo(() => {
    const sumByRisk = Object.values(quickWins.byRisk).reduce((acc, value) => acc + value, 0);
    return sumByRisk;
  }, [quickWins]);

  const colors = useMemo(() => {
    if (Object.keys(quickWins.byProduct).length === 0) {
      return {
        inner: ['#30AE5B', '#FFB156', '#D81D2D'],
        outer: ['#E0E0E0'],
      };
    }

    return {
      inner: ['#30AE5B', '#FFB156', '#D81D2D'],
      outer: Object.values(productColors),
    };
  }, [productColors, quickWins.byProduct]);

  const buildProductFilter = (product: string) => {
    return buildQuery({
      filter: {
        and: [
          { productName: { contains: product.toLowerCase() } },
          { isCompliant: false },
          { isExempted: false },
          { estimatedWork: getEnumValue('RecommendationEstimatedWork', 'Minimal') },
          { easeOfFix: getEnumValue('RecommendationEaseOfFix', 'Minimal') },
        ],
      },
    });
  };

  const buildSeverityFilter = (severity: number) => {
    return buildQuery({
      filter: {
        and: [
          { severity: severity },
          { isCompliant: false },
          { isExempted: false },
          { estimatedWork: getEnumValue('RecommendationEstimatedWork', 'Minimal') },
          { easeOfFix: getEnumValue('RecommendationEaseOfFix', 'Minimal') },
        ],
      },
    });
  };

  const renderCustomLabel = (
    {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      index,
    }: {
      cx: number;
      cy: number;
      midAngle: number;
      innerRadius: number;
      outerRadius: number;
      index: number;
    },
    data: { name: string; value: number }[],
  ) => {
    const value = data[index].value;
    if (value === 0) return null;

    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) / 2;
    const x = cx + radius * Math.cos(-midAngle * RADIAN) - 15;
    const y = cy + radius * Math.sin(-midAngle * RADIAN) - 15;

    return (
      <foreignObject
        x={x}
        y={y}
        width={30}
        height={30}
        onMouseEnter={() => setHoveredInnerIndex(index)}
        onMouseLeave={() => setHoveredInnerIndex(null)}
      >
        <LabelContainer>{value}</LabelContainer>
      </foreignObject>
    );
  };

  return (
    <MainContainer>
      {isLoading ? (
        <DashboardCenterContainer>
          <Spin />
        </DashboardCenterContainer>
      ) : (
        <>
          <QuickWinsContainer>
            <ProductContainer>
              {Object.entries(quickWins.byProduct).length === 0 ? (
                <span>No quick wins available</span>
              ) : (
                paginatedProducts.map(([product, value], index) => {
                  const productFilter = buildProductFilter(product);
                  return (
                    <Link key={product} to={`/recommendations${productFilter}`} style={{ color: 'inherit' }}>
                      <ProductItem isHovered={hoveredOuterIndex === index}>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '6px', alignItems: 'center' }}>
                          <ColorIndicator backgroundColor={productColors[product]} />
                          <p
                            onMouseEnter={() => setHoveredOuterIndex(index)}
                            onMouseLeave={() => setHoveredOuterIndex(null)}
                          >
                            {capitalize(product)}
                          </p>
                        </div>
                        <span>{value}</span>
                      </ProductItem>
                    </Link>
                  );
                })
              )}
            </ProductContainer>

            <PieChartData>
              <PieChart width={250} height={250}>
                {/* Text in the center of the donuts */}
                <text x={125} y={120} textAnchor="middle" dominantBaseline="central" fontSize={24} fontWeight="bold">
                  {totalQuickWins}
                </text>
                <text x={125} y={140} textAnchor="middle" dominantBaseline="central" fontSize={14} fill="#888888">
                  Quick Wins
                </text>

                {/* Inner Donut - byRisk */}
                <Pie
                  data={chartData.innerData}
                  dataKey="value"
                  outerRadius={78}
                  innerRadius={60}
                  label={(props) => renderCustomLabel(props, chartData.innerData)}
                  isAnimationActive={false}
                  onMouseEnter={(_, index) => setHoveredInnerIndex(index)}
                  onMouseLeave={() => setHoveredInnerIndex(null)}
                  paddingAngle={-15}
                  blendStroke={true}
                  cornerRadius={20}
                >
                  {chartData.innerData.map((entry, index) => (
                    <Cell
                      key={`inner-${index}`}
                      fill={
                        hoveredInnerIndex === null || hoveredInnerIndex === index
                          ? colors.inner[index % colors.inner.length]
                          : '#E0E0E0'
                      }
                      strokeWidth={0}
                      style={{ transition: 'fill 0.3s ease-in-out' }}
                    />
                  ))}
                </Pie>

                {/* Outer Donut - byProduct */}
                <Pie
                  data={chartData.outerData}
                  dataKey="value"
                  innerRadius={90}
                  outerRadius={120}
                  isAnimationActive={false}
                  onMouseEnter={(_, index) => setHoveredOuterIndex(index)}
                  onMouseLeave={() => setHoveredOuterIndex(null)}
                  paddingAngle={-11}
                  cornerRadius={20}
                >
                  {chartData.outerData.map((entry, index) => (
                    <Cell
                      key={`outer-${index}`}
                      fill={
                        hoveredOuterIndex === null || hoveredOuterIndex === index
                          ? colors.outer[index % colors.outer.length]
                          : '#E0E0E0'
                      }
                      strokeWidth={0}
                      style={{ transition: 'fill 0.3s ease-in-out' }}
                    />
                  ))}
                </Pie>
              </PieChart>
              <RiskContainer>
                {chartData.innerData.map((riskItem, index) => {
                  const severityFilter = buildSeverityFilter(Number(riskItem.key));
                  return (
                    <Link key={riskItem.name} to={`/recommendations${severityFilter}`} style={{ color: 'inherit' }}>
                      <RiskItem isHovered={hoveredInnerIndex === index}>
                        <ColorIndicator backgroundColor={colors.inner[index]} />
                        <span
                          onMouseEnter={() => setHoveredInnerIndex(index)}
                          onMouseLeave={() => setHoveredInnerIndex(null)}
                        >
                          {riskItem.name}
                        </span>
                      </RiskItem>
                    </Link>
                  );
                })}
              </RiskContainer>
            </PieChartData>
          </QuickWinsContainer>
        </>
      )}
      <DashboardPagination {...paginationProps} currentPage={currentPage} />
    </MainContainer>
  );
}

import { store } from '../store/store';

export const getEnumValue = (enumKey: string, key: string) => {
  const enumValues = store.getState().app.appValues;
  return (enumValues?.[enumKey] as { label: string; value: number }[])?.find((val) => val.label === key)?.value;
};

export const getEnumLabel = (enumKey: string, value: number) => {
  const enumValues = store.getState().app.appValues;
  return (enumValues?.[enumKey] as { label: string; value: number }[])?.find((val) => val.value == value)?.label;
};

/**
 * Get enum values that match a key (case insensitive and whitespace insensitive)
 * @param enumKey - The key of the enum to search in
 * @param key - The key to search for in the enum values
 * @returns An array of matching enum values
 */
export const getEnumValuesLikeKey = (enumKey: string, key: string): number[] => {
  const enumValues = store.getState().app.appValues;
  const normalizedKey = key.replace(/\s+/g, '').toLowerCase();
  const matchingValues =
    (enumValues?.[enumKey] as { label: string; value: number }[] | undefined)
      ?.filter((val) => {
        const normalizedLabel = val.label.replace(/\s+/g, '').toLowerCase();
        return normalizedLabel.includes(normalizedKey);
      })
      .map((val) => val.value) || [];
  return matchingValues;
};

import { useTableFiltersV2 } from '@app/components/tables/data-table/hooks/useTableFilters';
import { TDataColumns } from '@app/components/tables/data-table/types';
import { useDataTable } from '@app/components/tables/data-table/hooks/useDataTable';
import { TList } from '@app/types/generalTypes';

export const getDataManagementCommonCols = <T extends Record<string, unknown>>(): TDataColumns<T> => [
  {
    title: 'ID',
    dataIndex: 'id',
    type: 'number',
    width: '8vw',
    align: 'center',
    showSortDirections: true,
  },
  {
    title: 'Display Name',
    dataIndex: 'displayName',
    type: 'text',
    width: '15vw',
    showSortDirections: true,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    type: 'text',
    width: '12vw',
    showSortDirections: true,
  },
];

export const useCreateTableFilterProps = <T extends Record<string, unknown>>(
  model: string,
  customQuery?: (queryString: string, filterValuesMap?: Map<string, string>) => Promise<TList<T>>,
  constantFilter: Record<string, any> = {},
) => {
  return useTableFiltersV2<T>({
    columns: getDataManagementCommonCols<T>(),
    model,
    constantFilter,
    searchableColumns: ['displayName'],
    orderBy: {
      column: 'id',
      order: 'asc',
    },
    canFilter: false,
    customQueryFn: customQuery,
  });
};

export const useCreateDataTableProps = <T extends Record<string, unknown>>(
  tableFilterProps: ReturnType<typeof useCreateTableFilterProps<T>>,
  selectionType: string,
  onRowSelectCallback?: (selectedRows: T[]) => void,
) => {
  return useDataTable<T>({
    data: tableFilterProps.data?.items || [],
    columns: getDataManagementCommonCols<T>(),
    isLoading: tableFilterProps.isFetching,
    totalRows: tableFilterProps.data?.count,
    canModifyTable: false,
    rowSelectionType: selectionType,
    paginationEventCallback: ({ top, skip }) => {
      tableFilterProps.buildOdataQueryFromParams({ paginationProps: { top, skip } });
    },
    columnChangesEventCallback: (columns, displayedColumnIndexes) => {
      tableFilterProps.buildOdataQueryFromParams({ selectFields: displayedColumnIndexes });
    },
    onSortCallback: (sortObj) => {
      if (!sortObj || (!!sortObj && !sortObj.column)) return;
      tableFilterProps.buildOdataQueryFromParams({
        orderyByProps: { column: sortObj.column || '', order: sortObj.order || 'asc' },
      });
    },
    onRowSelect: (selectedRows) => onRowSelectCallback && onRowSelectCallback(selectedRows),
  });
};

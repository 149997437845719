import AuditTypeSettings from '@app/components/apps/azure-events-monitoring/audit-types-setting/AuditTypeSettings';
import EmailNotificationSettings from '@app/components/apps/azure-events-monitoring/azure-email-notifications/EmailNotificationSettings';
import { BasePageWrapper } from '@app/components/common/BasePageWrapper/BasePageWrapper';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useTranslation } from 'react-i18next';
import { VscAzure } from 'react-icons/vsc';
import styled from 'styled-components';

const MainContainer = styled.div`
  min-height: 50vh;
  border: 1px solid var(--grey-100);
  background-color: white;
  padding: 24px;
  border-radius: 8px;

  @media (max-width: 768px) {
    padding: 16px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
  align-items: center;
`;

const Title = styled.span`
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  color: var(--grey-950);
`;

export default function AzureLogsSettingsPage() {
  const { t } = useTranslation();

  return (
    <BasePageWrapper
      title={t('common.auditSettingsPageTitle')}
      description="Customize which audit events are tracked which you will be notified about through email."
    >
      <PageTitle>Configure Events Monitoring</PageTitle>

      <MainContainer>
        <TitleContainer>
          <VscAzure size={'1.2rem'} fill="var(--grey-950)" />
          <Title>Main Features</Title>
        </TitleContainer>

        <AuditTypeSettings />
        <EmailNotificationSettings />
      </MainContainer>
    </BasePageWrapper>
  );
}

/* eslint-disable no-var */
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCreateAccount, useDeleteAccount, useScanAccount, useUpdateAccount } from '@app/api/account.api';
import StatusBadge from '@app/components/apps/alerts/StatusBadge';
import ManageAccountsForm from '@app/components/apps/manage-accounts/ManageAccountsForm';
import useManageAccountsForm from '@app/components/apps/manage-accounts/useManageAccountsForm';
import ComponentHeader from '@app/components/common/ComponentHeader/ComponentHeader';
import { TManageAccounts } from '@app/types/manageAccounts';

import InviteAccountModal from '@app/components/apps/manage-accounts/InviteAccountModal';
import ActionConfirmationModal from '@app/components/apps/manage-users/ActionConfirmationModal';
import { BaseCTAButton } from '@app/components/common/BaseButton/BaseButton.styles';
import { BasePageWrapper } from '@app/components/common/BasePageWrapper/BasePageWrapper';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { TActionItems } from '@app/components/tables/AntdTableWrapper/hooks/useTableFilters';
import DataTableV2 from '@app/components/tables/data-table/DataTable';
import { TableActions } from '@app/components/tables/data-table/components/TableActions';
import TableFiltersV2 from '@app/components/tables/data-table/components/TableFilters';
import { useDataTable } from '@app/components/tables/data-table/hooks/useDataTable';
import { useTableFiltersV2 } from '@app/components/tables/data-table/hooks/useTableFilters';
import { TDataColumns } from '@app/components/tables/data-table/types';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { getEnumLabel, getEnumValue } from '@app/services/enum.service';
import { differenceInHours, parse } from 'date-fns';

interface IManageAccountPageProps {
  title?: string;
}

export default function ManageAccountPage(props: IManageAccountPageProps) {
  const subscriptionTypes =
    useAppSelector((state) => state.app.subscriptionTypes)?.map((opt) => ({
      key: opt.name,
      value: opt.name,
    })) || [];

  const manageAccountsTableCols: TDataColumns<TManageAccounts> = [
    {
      title: 'Account ID',
      dataIndex: 'id',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: '8vw',
      hideColumn: true,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: '11vw',
    },
    {
      title: 'Creation Time',
      dataIndex: 'creationTime',
      allowFiltering: true,
      type: 'date',
      showSortDirections: true,
      width: '11vw',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      allowFiltering: true,
      type: 'enum',
      enumValuesKey: 'AccountStatus',
      showSortDirections: true,
      width: '8vw',
      render: (val, record) => (
        <StatusBadge
          type={
            (record.status as any) === 'Enabled'
              ? 'enabled'
              : (record.status as any) === 'Disabled'
              ? 'disabled'
              : 'pending'
          }
        >
          {record.status}
        </StatusBadge>
      ),
    },
    {
      title: 'Microsoft Application Status',
      dataIndex: 'microsoftApplicationStatus',
      allowFiltering: true,
      type: 'enum',
      enumValuesKey: 'MicrosoftApplicationStatus',
      showSortDirections: true,
      width: '11vw',
      render: (val, record) => (
        <StatusBadge type={(record?.microsoftApplicationStatus as any) === 'Active' ? 'enabled' : 'disabled'}>
          {record.microsoftApplicationStatus}
        </StatusBadge>
      ),
    },
    {
      title: 'Last Scan',
      dataIndex: 'lastScanTimeEnd',
      allowFiltering: true,
      type: 'datetime',
      showSortDirections: true,
      width: '11vw',
      render: (val, record) => (
        <>
          <StatusBadge
            type={
              record?.lastScanTimeEnd == null ||
              differenceInHours(new Date(), parse(record?.lastScanTimeEnd, 'dd/MM/yyyy HH:mm', new Date())) > 8
                ? 'disabled'
                : 'enabled'
            }
          >
            {record?.lastScanTimeEnd || 'None'} {record.isScanning == 'True' && ' (Scanning)'}
          </StatusBadge>
        </>
      ),
    },
    {
      title: 'Error Details',
      dataIndex: 'microsoftConnectorError',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      hideColumn: true,
      width: '8vw',
    },
    {
      title: 'Owner Email',
      dataIndex: 'ownerEmail',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: '11vw',
    },
    {
      title: 'Subscription',
      dataIndex: 'subscriptionName',
      allowFiltering: true,
      type: 'textOptions',
      options: subscriptionTypes,
      multipleSelect: true,
      showSortDirections: true,
      hideColumn: false,
      width: '8vw',
    },
    {
      title: 'Custom Security Score',
      dataIndex: 'customSecurityScore',
      allowFiltering: true,
      type: 'number',
      showSortDirections: true,
      hideColumn: true,
      width: '8vw',
    },
    {
      title: 'Baseline Security Score',
      dataIndex: 'standardSecurityScore',
      allowFiltering: true,
      type: 'number',
      showSortDirections: true,
      hideColumn: true,
      width: '8vw',
    },
    {
      title: 'Last Updated Time',
      dataIndex: 'lastUpdatedTime',
      allowFiltering: true,
      type: 'date',
      showSortDirections: true,
      hideColumn: true,
    },
    {
      title: 'Last Scan Time',
      dataIndex: 'lastScanTime',
      allowFiltering: true,
      type: 'datetime',
      showSortDirections: true,
      hideColumn: true,
    },
    {
      title: 'Microsoft Tenant ID',
      dataIndex: 'microsoftTenantId',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      hideColumn: true,
      width: '8vw',
    },
    {
      title: 'Microsoft Tenant Name',
      dataIndex: 'microsoftTenantName',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      hideColumn: true,
      width: '8vw',
    },
    {
      title: 'Microsoft Application ID',
      dataIndex: 'microsoftApplicationId',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      hideColumn: true,
      width: '8vw',
    },
    {
      title: 'Is Scanning',
      dataIndex: 'isScanning',
      allowFiltering: true,
      type: 'boolean',
      showSortDirections: true,
      hideColumn: true,
    },
    {
      title: 'Reseller',
      dataIndex: 'vendorName',
      allowFiltering: false,
      type: 'text',
      showSortDirections: true,
      width: '11vw',
    },
    {
      title: 'Terms Version',
      dataIndex: 'termsVersion',
      allowFiltering: false,
      type: 'number',
      showSortDirections: true,
      width: '8vw',
      hideColumn: true,
    },
  ];

  const [isEdit, setIsEdit] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<TManageAccounts[] | undefined>(undefined);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [action, setAction] = useState<'enable' | 'disable' | 'overrideScan' | 'delete' | null>(null);
  const [message, setMessage] = useState('');
  const [isInviteAccountsModalOpen, setIsInviteAccountsModalOpen] = useState(false);

  const { t } = useTranslation();
  const { mutateAsync: createAccount, isLoading: isCreating } = useCreateAccount();
  const { mutateAsync: updateAccount, isLoading: isUpdating } = useUpdateAccount();
  const { mutateAsync: deleteAccount, isLoading: isDeleting } = useDeleteAccount();
  const { mutateAsync: scanAccount } = useScanAccount();

  const { toggle, ...manageAccountsFormProps } = useManageAccountsForm({
    onOk: async (data) => {
      if (isEdit && !!selectedAccount) {
        await updateAccount({
          ids: [selectedAccount[0].id],
          data: data,
        });
      } else {
        await createAccount(data);
      }

      setIsEdit(false);
      setSelectedAccount(undefined);
      toggle();
      tableFilterProps.refetch();
      dataTableProps.clearSelection();
    },
    onCancel: () => {
      setIsEdit(false);
      setSelectedAccount(undefined);
    },
    account: selectedAccount ? selectedAccount[0] : undefined,
  });

  const actionItems: TActionItems<TManageAccounts>[] = [
    {
      label: 'Enable',
      key: 'enable',
      multiSelect: true,
      onClick: (item) => {
        setSelectedAccount(item);
        setAction('enable');
        setMessage('Are you sure you want to enable this account?');
        setIsConfirmationModalOpen(true);
      },
      show: (item) => item.every((rec) => (rec.status as any) === 'Disabled'),
    },
    {
      label: 'Disable',
      key: 'disable',
      multiSelect: true,
      onClick: (rec) => {
        setSelectedAccount(rec);
        setAction('disable');
        setMessage('Are you sure you want to disable this account?');
        setIsConfirmationModalOpen(true);
      },
      show: (item) => item.every((rec) => (rec.status as any) === 'Enabled'),
    },
    {
      label: 'View as Account Admin',
      key: 'view',
      multiSelect: false,
      onClick: (rec) => {
        window.open(`/impersonate?accountId=${rec.id}`, '_blank', 'rel=noopener noreferrer');
      },
    },
    {
      label: 'Scan',
      key: 'scan',
      multiSelect: true,
      onClick: async (accounts) => {
        const scanningAccounts = accounts.filter((acc) => acc.isScanning === 'True');

        if (scanningAccounts.length > 0) {
          // override the scan if at least one account is scanning
          setSelectedAccount(accounts);
          setAction('overrideScan');
          setMessage(
            `A previous scan for account ${scanningAccounts[0].ownerEmail} is currently in progress. Are you sure you want to cancel the existing scan and start a new one?`,
          );
          setIsConfirmationModalOpen(true);
        } else {
          const ids = accounts.map((rec) => rec.id);
          await scanAccount(ids);
          accounts.forEach((rec) => (rec.isScanning = 'True'));
          tableFilterProps.refetch();
          dataTableProps.clearSelection();
        }
      },
      show: (accounts) => {
        const disabled = getEnumLabel('AccountStatus', 2);
        const deleted = getEnumLabel('AccountStatus', 5);
        return accounts.every((acc) => (acc.status as any) != disabled && (acc.status as any) != deleted);
      },
    },
    {
      label: 'Edit Account',
      key: 'edit',
      multiSelect: false,
      onClick: (rec) => {
        setIsEdit(true);
        setSelectedAccount([rec]);
        toggle();
      },
    },
    {
      label: 'Delete Account',
      key: 'delete',
      multiSelect: true,
      onClick: (rec) => {
        setSelectedAccount(rec);
        setAction('delete');
        setMessage('Are you sure you want to delete this account?');
        setIsConfirmationModalOpen(true);
      },
      show: (item) => item.every((rec) => (rec.status as any) !== 'Enabled'),
    },
  ];

  const tableFilterProps = useTableFiltersV2<TManageAccounts>({
    columns: manageAccountsTableCols,
    model: 'accountExtended',
    searchableColumns: ['name'],
    orderBy: {
      column: 'name',
      order: 'asc',
    },
    constantFilter: { status: { ne: 5 } }, //todo - the hardcoded 5 value (deleted status) is a patch to resolve deleted statuses removal from enum logic conflict. todo - address better later on

    onApplyFilterCallback: () => {
      dataTableProps.resetPagination();
    },
  });

  var dataTableProps = useDataTable<TManageAccounts>({
    data: tableFilterProps.data?.items || [],
    columns: manageAccountsTableCols,
    isLoading: tableFilterProps.isFetching,
    totalRows: tableFilterProps.data?.count,
    paginationEventCallback: ({ top, skip }) => {
      tableFilterProps.buildOdataQueryFromParams({ paginationProps: { top, skip } });
    },
    columnChangesEventCallback: (columns, displayedColumnIndexes) => {
      tableFilterProps.buildOdataQueryFromParams({ selectFields: displayedColumnIndexes });
    },
    onSortCallback: (sortObj) => {
      if (!sortObj || (!!sortObj && !sortObj.column)) return;
      tableFilterProps.buildOdataQueryFromParams({
        orderyByProps: { column: sortObj.column || '', order: sortObj.order || 'asc' },
      });
    },
    onRowClick: (data) => {
      setIsEdit(true);
      setSelectedAccount([data]);
      toggle();
    },
  });

  const handleModalClose = () => {
    setSelectedAccount(undefined);
    setAction(null);
    setMessage('');
    setIsConfirmationModalOpen(false);
  };

  const handleConfirm = async () => {
    if (!selectedAccount) return;

    const ids = selectedAccount.map((rec) => rec.id);

    if (action === 'enable') {
      await updateAccount({
        ids,
        data: {
          status: getEnumValue('AccountStatus', 'Enabled'),
        },
      });
      handleModalClose();
      tableFilterProps.refetch();
      dataTableProps.clearSelection();

      return;
    }

    if (action === 'disable') {
      await updateAccount({
        ids,
        data: {
          status: getEnumValue('AccountStatus', 'Disabled'),
        },
      });
      handleModalClose();
      tableFilterProps.refetch();
      dataTableProps.clearSelection();

      return;
    }

    if (action === 'overrideScan') {
      await scanAccount(ids);
      handleModalClose();
      tableFilterProps.refetch();
      dataTableProps.clearSelection();
      return;
    }

    if (action === 'delete') {
      await deleteAccount(ids);
      handleModalClose();
      tableFilterProps.refetch();
      dataTableProps.clearSelection();
    }
  };

  return (
    <BasePageWrapper title={t('common.manageAccounts')}>
      <PageTitle>Manage Accounts</PageTitle>
      <ComponentHeader title={t('common.manageAccounts')} />
      <div style={{ marginBottom: '32px' }}>
        <TableFiltersV2 {...tableFilterProps}>
          <TableFiltersV2.Actions>
            <BaseCTAButton onClick={() => setIsInviteAccountsModalOpen(true)}>Invite Account</BaseCTAButton>
          </TableFiltersV2.Actions>
        </TableFiltersV2>
      </div>
      <DataTableV2 {...dataTableProps}>
        <DataTableV2.Actions>
          <TableActions actionItems={actionItems} />
        </DataTableV2.Actions>
      </DataTableV2>

      <ManageAccountsForm {...manageAccountsFormProps} isEdit={isEdit} isLoading={isCreating || isUpdating} />
      <ActionConfirmationModal
        open={isConfirmationModalOpen}
        onOk={handleConfirm}
        onCancel={handleModalClose}
        message={message}
        isLoading={isUpdating}
      />
      <InviteAccountModal
        open={isInviteAccountsModalOpen}
        onCancel={() => setIsInviteAccountsModalOpen(false)}
        onInviteSuccess={tableFilterProps.refetch}
      />
    </BasePageWrapper>
  );
}
